<template>
  <svg
    class="preloader"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 1C8 0.447715 7.55004 -0.00655632 7.00199 0.0616464C3.05471 0.552863 0 3.9197 0 8C0 12.4183 3.58172 16 8 16C12.0803 16 15.4471 12.9453 15.9384 8.99801C16.0066 8.44996 15.5523 8 15 8C14.4477 8 14.0087 8.45107 13.9177 8.99581C13.4434 11.8356 10.9745 14 8 14C4.68629 14 2 11.3137 2 8C2 5.02554 4.16441 2.55661 7.00419 2.08226C7.54892 1.99127 8 1.55228 8 1Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "IconLoad",
  props: {
    color: {
      type: String,
      default: "#ffffff",
    },
  },
};
</script>

<style lang="scss" scoped>
.preloader {
  position: relative;
  z-index: 1;
  width: 16px;
  height: 16px;

  animation: 3s linear infinite rotate;

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
}
</style>
