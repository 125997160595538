<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.4217 17.7644C10.0983 18.0785 9.5739 18.0785 9.25047 17.7644C8.92704 17.4503 8.92704 16.941 9.25047 16.6269L14.5862 11.4449C14.9096 11.1308 15.434 11.1308 15.7574 11.4449C16.0809 11.7591 16.0809 12.2683 15.7574 12.5824L10.4217 17.7644Z"
      :fill="color"
    />
    <path
      d="M9.24257 7.37309C8.91914 7.05898 8.91914 6.5497 9.24257 6.23558C9.56601 5.92147 10.0904 5.92147 10.4138 6.23558L15.7495 11.4176C16.073 11.7317 16.073 12.2409 15.7495 12.5551C15.4261 12.8692 14.9017 12.8692 14.5783 12.5551L9.24257 7.37309Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "IconArrowButton",
  props: {
    color: {
      default: "#ffffff",
    },
  },
};
</script>

<style scoped></style>
