<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Time">
      <path
        id="Vector"
        d="M19.4993 21.3665C19.7438 21.6109 20.0438 21.7332 20.3993 21.7332C20.7549 21.7332 21.066 21.5998 21.3327 21.3332C21.5771 21.0887 21.6993 20.7776 21.6993 20.3998C21.6993 20.0221 21.5771 19.7109 21.3327 19.4665L17.3327 15.4665V10.6332C17.3327 10.2554 17.2051 9.94428 16.95 9.69984C16.694 9.45539 16.3771 9.33317 15.9993 9.33317C15.6216 9.33317 15.3051 9.46073 15.05 9.71584C14.794 9.97184 14.666 10.2887 14.666 10.6665V15.9665C14.666 16.1443 14.6993 16.3163 14.766 16.4825C14.8327 16.6496 14.9327 16.7998 15.066 16.9332L19.4993 21.3665ZM15.9993 29.3332C14.1549 29.3332 12.4216 28.9829 10.7993 28.2825C9.17713 27.5829 7.76602 26.6332 6.56602 25.4332C5.36602 24.2332 4.41624 22.8221 3.71668 21.1998C3.01624 19.5776 2.66602 17.8443 2.66602 15.9998C2.66602 14.1554 3.01624 12.4221 3.71668 10.7998C4.41624 9.17762 5.36602 7.7665 6.56602 6.5665C7.76602 5.3665 9.17713 4.41628 10.7993 3.71584C12.4216 3.01628 14.1549 2.6665 15.9993 2.6665C17.8438 2.6665 19.5771 3.01628 21.1993 3.71584C22.8216 4.41628 24.2327 5.3665 25.4327 6.5665C26.6327 7.7665 27.5825 9.17762 28.282 10.7998C28.9825 12.4221 29.3327 14.1554 29.3327 15.9998C29.3327 17.8443 28.9825 19.5776 28.282 21.1998C27.5825 22.8221 26.6327 24.2332 25.4327 25.4332C24.2327 26.6332 22.8216 27.5829 21.1993 28.2825C19.5771 28.9829 17.8438 29.3332 15.9993 29.3332ZM15.9993 26.6665C18.9549 26.6665 21.4718 25.6278 23.55 23.5505C25.6273 21.4723 26.666 18.9554 26.666 15.9998C26.666 13.0443 25.6273 10.5274 23.55 8.44917C21.4718 6.37184 18.9549 5.33317 15.9993 5.33317C13.0438 5.33317 10.5273 6.37184 8.45002 8.44917C6.37179 10.5274 5.33268 13.0443 5.33268 15.9998C5.33268 18.9554 6.37179 21.4723 8.45002 23.5505C10.5273 25.6278 13.0438 26.6665 15.9993 26.6665Z"
        :fill="color"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconBookingTime",
  props: {
    color: {
      type: String,
      default: "#F18E35",
    },
  },
};
</script>

<style scoped></style>
