<template>
  <vue-slider
    v-model="value"
    :min="[valueMin]"
    :max="valueMax"
    :step="interval"
    :format="format"
    @drag-end="emitChange"
    :tooltipPosition="yPos"
    :tooltips="tooltips"
  />
</template>

<script>
import VueSlider from "@vueform/slider";
export default {
  name: "VRange",
  components: { VueSlider },
  props: {
    valueMax: [Number],
    valueMin: { type: Number, default: 0 },
    valueRange: [Number],
    yPos: {
      type: String,
      default: "top",
    },
    tooltips: {
      type: Boolean,
      default: true,
    },
    interval: { type: Number, default: 1 },
    formatMarker: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      value: this.valueRange,
      format: (value) => {
        return `${Math.round(value)}  ${this.formatMarker}`;
      },
    };
  },

  watch: {
    valueRange(newValue) {
      this.value = newValue;
    },
  },

  methods: {
    emitChange() {
      this.$emit("on-change", this.value);
    },
  },
};
</script>
<style>
.slider-touch-area {
  border-radius: 50%;
  border: 2px solid #f18e35;
}
</style>

<style src="@vueform/slider/themes/default.css"></style>
