<template>
  <svg
    width="16"
    height="28"
    viewBox="0 0 16 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.845 1.14906C13.4906 0.505653 14.5372 0.505653 15.1828 1.14906C15.8284 1.79246 15.8284 2.83562 15.1828 3.47903L3.49368 15.1289C2.84811 15.7723 1.80143 15.7723 1.15586 15.1289C0.510287 14.4855 0.510287 13.4423 1.15586 12.7989L12.845 1.14906Z"
      :fill="color"
    />
    <path
      d="M15.1781 24.5206C15.8237 25.164 15.8237 26.2072 15.1781 26.8506C14.5325 27.494 13.4859 27.494 12.8403 26.8506L1.15117 15.2008C0.505597 14.5574 0.505598 13.5142 1.15117 12.8708C1.79674 12.2274 2.84342 12.2274 3.489 12.8708L15.1781 24.5206Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "IconBookingCounterArrow",
  props: {
    color: {
      type: String,
      default: "#F18E35",
    },
  },
};
</script>

<style></style>
