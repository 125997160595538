<template>
  <svg
    width="12"
    height="7"
    viewBox="0 0 12 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.235585 1.42172C-0.0785284 1.09829 -0.0785282 0.573902 0.235585 0.25047C0.549698 -0.0729626 1.05898 -0.0729628 1.37309 0.250469L6.55506 5.58617C6.86917 5.90961 6.86917 6.43399 6.55506 6.75743C6.24094 7.08086 5.73167 7.08086 5.41755 6.75743L0.235585 1.42172Z"
      :fill="color"
    />
    <path
      d="M10.6269 0.242574C10.941 -0.0808582 11.4503 -0.080858 11.7644 0.242574C12.0785 0.566007 12.0785 1.09039 11.7644 1.41383L6.58245 6.74953C6.26834 7.07296 5.75906 7.07296 5.44494 6.74953C5.13083 6.4261 5.13083 5.90171 5.44494 5.57828L10.6269 0.242574Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "IconSelectArrow",
  props: {
    color: {
      type: String,
      default: "#ADADAD",
    },
  },
};
</script>

<style scoped></style>
