<template>
  <div class="wrapper-datapicker" :class="{ _edit: edit }">
    <Datepicker
      v-model="modelValue"
      locale="ru"
      ref="datepicker"
      :highlight="highlightedDates"
      :disabled-dates="highlightedDates"
      highlight-disabled-days
      :month-change-on-scroll="false"
      hide-offset-dates
      class="datapicker"
      :class="{ _widget: isWidget }"
      timezone="Asia/Yekaterinburg"
      @update-month-year="handleMonthYear"
      :min-date="minDateCalendar"
      no-swipe
      inline
      auto-apply
    >
      <template #month-year="{ month, year, handleMonthYearChange }">
        <div class="month-year" :class="{ _sidebar: edit }">
          <div class="icon _left" :class="{ _hide: monthCount < 1 }">
            <span
              v-if="month > initMonth || nextYear"
              class="custom-icon"
              @click="
                handleMonthYearChangeHandler(handleMonthYearChange, false)
              "
            >
              <IconAccordionArrow />
            </span>
          </div>
          <div class="custom-month-year-component" :class="{ _edit: edit }">
            {{ getMonth(month) }} {{ year }}
          </div>
          <div class="icon _right" :class="{ _hide: monthCount > 4 }">
            <span
              v-if="monthCount <= 4"
              class="custom-icon"
              @click="handleMonthYearChangeHandler(handleMonthYearChange, true)"
            >
              <IconAccordionArrow />
            </span>
          </div>
        </div>
      </template>
    </Datepicker>
  </div>
</template>

<script>
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import IconAccordionArrow from "@/components/icons/IconAccordionArrow";
import { MONTHS } from "@/assets/js/utils/consts";
import addDays from "date-fns/addDays";

export default {
  name: "VDataPickerBooking",
  components: { Datepicker, IconAccordionArrow },
  data() {
    return {
      modelValue: null,
      highlightedDates: [],
      month: 0,
      year: 0,
      initMonth: 0,
      updated: false,
      monthOffset: 0,
      nextYear: false,
      cellWidth: "72px",
      currentYear: 0,
      minDateCalendar: null,
      monthCount: 0,
    };
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    edit: {
      type: Boolean,
      default: false,
    },
    minDate: {
      type: Boolean,
      default: false,
    },
    dateVal: {
      type: String,
      required: false,
    },
    isWidget: {
      type: Boolean,
      default: false,
    },
    freeMonthDays: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    modelValue(value) {
      const date = new Date(value);

      const updatedDate = new Date(
        date.getTime() + (300 + date.getTimezoneOffset()) * 60000
      );

      if (this.edit) {
        if (this.updated) {
          this.$emit("update", updatedDate);
        }
        this.updated = true;
      } else {
        this.$emit("update", updatedDate);
      }
    },
    freeMonthDays: {
      handler() {
        const firstDay = new Date(this.currentYear, this.month, 1);
        this.highlightedDates = [];
        this.findFalsyIndexes(this.freeMonthDays).forEach((el) => {
          this.highlightedDates.push(addDays(firstDay, el));
        });
      },
      deep: true,
    },
    month: {
      handler() {
        setTimeout(() => {
          const days = document.querySelectorAll(".dp__cell_inner");
          for (let i of days) {
            if (!i.innerText) {
              i.classList.add("dp__cell_hidden");
            }
          }
        });
      },
      immediate: true,
    },
  },

  methods: {
    handleMonthYearChangeHandler(func, isNext) {
      func(isNext);
      if (isNext) {
        this.monthCount += 1;
      } else {
        this.monthCount -= 1;
      }
    },
    getTime(val) {
      let date = new Date(val);
      let time = date.toLocaleDateString("ru");
      return time.slice(0, 5);
    },
    getMonth(num) {
      return MONTHS[num];
    },
    reset() {
      if (this.$refs.datepicker) {
        this.$refs.datepicker.clearValue();
      }
    },
    getValue(index) {
      if (this.modelValue) {
        if (this.modelValue[index]) {
          return this.getTime(this.modelValue[index]);
        } else {
          return "";
        }
      }
    },
    handleMonthYear({ month, year }) {
      this.month = month;

      this.nextYear = year > this.year;

      this.currentYear = year;

      this.$emit("changeMonth", { month, year });
    },
    findFalsyIndexes(arr) {
      const indexes = [];

      for (let i = 0; i < arr.length; i++) {
        if (!arr[i]) {
          indexes.push(i);
        }
        if (arr[i] === 2) {
          indexes.push(i);
        }
      }

      return indexes;
    },
  },

  computed: {
    screenWidth() {
      return this.$store.getters.screenType;
    },
  },

  mounted() {
    this.$nextTick(() => {
      if (this.isWidget) {
        if (this.screenWidth === "mobile") {
          this.cellWidth = "40px";
        }
      }
    });

    const date = new Date();

    if (this.dateVal) {
      this.modelValue = new Date(this.dateVal);
      this.month = this.modelValue.getMonth();
      this.year = this.modelValue.getFullYear();
      this.currentYear = this.modelValue.getFullYear();
    } else {
      this.month = date.getMonth();
      this.year = date.getFullYear();
      this.currentYear = date.getFullYear();
    }

    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    this.findFalsyIndexes(this.freeMonthDays).forEach((el) => {
      this.highlightedDates.push(addDays(firstDay, el));
    });

    const elem = document.querySelector(
      "div.main-block > div > div > div:nth-child(2)"
    );

    elem.style.width = "100%";

    if (this.edit) {
      this.cellWidth = "60px";
    }

    if (this.minDate) {
      const currentDate = new Date();

      const day = currentDate.toLocaleString("ru", {
        timeZone: "Asia/Yekaterinburg",
        day: "numeric",
      });

      this.minDateCalendar = new Date();
      this.minDateCalendar.setDate(day);
      this.minDateCalendar.setHours(0);
      this.minDateCalendar.setMinutes(0);
      this.minDateCalendar.setSeconds(0);
    }
  },
};
</script>

<style lang="scss" scoped>
.custom-month-year-component {
  display: flex;
  align-items: center;
  margin: 0 24px;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;

  text-align: center;
  color: #222222;

  &._edit {
    margin: 0 24px;
  }
}

.icon {
  display: flex;
  box-sizing: border-box;
  width: 25px;
  height: 25px;

  &._left {
    transform: rotate(-90deg);
  }

  &._right {
    transform: rotate(90deg);
  }

  &._hide {
    span {
      display: none;
    }
  }
}

.custom-icon {
  padding: 5px;
  display: flex;
  height: 25px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 25px;
  color: var(--dp-icon-color);
  text-align: center;
  border-radius: 50%;

  svg {
    height: 20px;
    width: 20px;
  }

  &:hover {
    background: var(--dp-hover-color);
  }
}

.month-year {
  display: flex;
  width: 100%;
  margin-bottom: 16px;
  align-items: center;
  justify-content: space-between;

  &._sidebar {
    justify-content: center;
  }
}

.wrapper-datapicker {
  display: flex;
  justify-content: center;
  padding: 0;

  @include mobile {
    padding: 0;
  }

  &._edit {
    padding: 0;
  }
}

.datapicker {
  :deep(.dp__outer_menu_wrap) {
    position: relative;
    width: 100%;
  }
  :deep(.dp__menu) {
    width: 100%;
  }
  :deep(.dp__main div) {
    width: 100%;
  }
  :deep(.dp__calendar_header) {
    width: 100%;
  }
  :deep(.dp__calendar) {
    width: 100%;
  }

  :deep(.dp__calendar_item) {
    display: flex;
    justify-content: center;
  }
  :deep(.dp__calendar_header_item) {
    font-family: $font-family-primary;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #858585;

    &:first-letter {
      text-transform: uppercase;
    }

    &:nth-child(6),
    &:nth-child(7) {
      color: #f18e35;
    }
  }
  :deep(.dp__cell_offset) {
    display: none;
  }

  :deep(.dp__range_start) {
    border-radius: 8px;
  }
  :deep(.dp__range_end) {
    border-radius: 8px;
  }
  :deep(.dp__button, .dp__calendar_header_separator, .dp__arrow_top) {
    display: none;
  }
  :deep(.dp__month_year_row) {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    .separator {
      display: none;
      margin: 0;
    }
  }
  :deep(.dp__action_row) {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  :deep(.dp__menu) {
    padding-top: 32px;
    border: none !important;

    @include mobile {
      padding: 0;
    }
  }

  :deep(.dp__calendar_item) {
    height: 40px;
  }

  :deep(.dp__cell_inner) {
    width: v-bind("cellWidth");
    border-radius: 8px;
  }

  :deep(.dp__cell_disabled) {
    background-color: #fdebeb;
    color: #222222;
  }

  :deep(.dp__theme_light) {
    --dp-primary-color: #f18e35;
    --dp-hover-color: #fef6e8;
    --dp-highlight-color: #fdebeb;
    border: none;
  }
  :deep(.dp__menu_inner) {
    padding: 0;
  }
  :deep(.dp__calendar_header_separator) {
    display: none;
  }

  &._widget {
    width: 528px;
  }
}
</style>
