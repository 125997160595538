<template>
  <template v-if="isObject">
    <ObjectItem v-show="false" ref="objectItem" />
  </template>
  <div class="map-container">
    <div id="map" />
    <ReviewForm
      v-if="$store.state.clientRestaurants.editMode === 'reviewForm'"
    />
    <BigObjectItem v-else-if="clickedCatering" />
    <Items v-else-if="$store.state.clientRestaurants.clickedCluster" />
  </div>
</template>

<script>
import ObjectItem from "@/components/pages/home/ObjectItem.vue";
import {
  addMarkersToMap,
  initMap,
  removeMap,
} from "@/assets/js/utils/mapUtils";
import BigObjectItem from "@/components/pages/home/BigObjectItem.vue";
import ReviewForm from "@/components/ReviewForm.vue";
import Items from "@/components/pages/home/Items.vue";

export default {
  name: "VMap",

  components: {
    Items,
    BigObjectItem,
    ObjectItem,
    ReviewForm,
  },

  props: {
    items: Array,
    zoom: Number,
    isObject: {
      default: true,
      type: Boolean,
    },
    center: Array,
  },

  mounted() {
    setTimeout(() => {
      initMap(
        this.items,
        this.$refs.objectItem,
        this.isObject,
        this.zoom,
        this.center
      );
    });
  },

  watch: {
    items: {
      handler() {
        setTimeout(() => {
          addMarkersToMap(this.items, this.$refs.objectItem);
        });
      },
      deep: true,
    },
  },

  computed: {
    clickedCatering() {
      return this.$route.params.catId;
    },
  },

  beforeUnmount() {
    removeMap();
  },
};
</script>

<style scoped>
#map {
  width: 100%;
  height: 100%;
}

.map-container {
  position: relative;
  width: 100%;
  height: 100%;
}
</style>
