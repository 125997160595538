<template>
  <svg
    width="22"
    height="21"
    viewBox="0 0 22 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17 12.7778C17 14.0168 17.5227 15.1803 18.4107 16H3.58931C4.47733 15.1803 5 14.0168 5 12.7778V7.15789C5 5.10823 5.65487 3.59184 6.67353 2.58858C7.69587 1.58169 9.17772 1 11 1C12.8223 1 14.3041 1.58169 15.3265 2.58858C16.3451 3.59184 17 5.10823 17 7.15789V12.7778Z"
      stroke="#222222"
      stroke-width="2"
    />
    <path
      d="M9 19C9.45611 19.6072 10.1822 20 11 20C11.8178 20 12.5439 19.6072 13 19"
      stroke="#222222"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: "IconRing",
};
</script>
