import { Long_Polling } from "@/assets/js/api/longPollings/longPolling";

export const notificationsLongPolling = {
  timeoutId: null,
  isStopped: false,
  interval: 1000,
  abortController: null,

  async start(userId, realm, callback, interval = this.interval) {
    if (this.isStopped) {
      return;
    }

    if (!this.timeoutId) {
      this.timeoutId = setTimeout(async () => {
        await this.poll(userId, realm, callback, interval);
      }, interval);
    }
  },

  async poll(userId, realm, callback, interval) {
    try {
      this.abortController = new AbortController();
      const signal = this.abortController.signal;

      const response = await Long_Polling.getNotifications(userId, realm, {
        signal,
      });

      if (this.isStopped) {
        return;
      }

      if (response.status !== 200) {
        this.timeoutId = setTimeout(async () => {
          await this.poll(userId, realm, callback, interval);
        }, interval);
      } else {
        if (callback && typeof callback === "function") {
          callback(response.data);
        }

        this.timeoutId = setTimeout(async () => {
          await this.poll(userId, realm, callback, interval);
        }, interval);
      }
    } catch (e) {
      console.error(e);

      if (this.isStopped) {
        return;
      }

      if (e.name === "AbortError") {
        return;
      }

      this.timeoutId = setTimeout(async () => {
        await this.poll(userId, realm, callback, interval);
      }, interval);
    }
  },

  stop() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }

    if (this.abortController) {
      this.abortController.abort();
    }

    this.isStopped = true;
  },

  reset() {
    this.isStopped = false;
  },
};
