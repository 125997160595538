import axios from "axios";
import { memoizedRefreshToken } from "@/assets/js/utils/refreshToken";

const instance = axios.create({
  baseURL: "/api/v1/",
  withCredentials: true,
  timeout: 10000,
  headers: { "Content-Type": "application/json" },
});

instance.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] =
      "Bearer " + localStorage.getItem("accessToken");
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error?.config;

    if (error?.response?.status === 401 && !config?.sent) {
      config.sent = true;

      const result = await memoizedRefreshToken();

      if (result?.accessToken) {
        config.headers = {
          ...config.headers,
          authorization: `Bearer ${result?.accessToken}`,
        };
      }

      return axios(config);
    }
    return Promise.reject(error);
  }
);

export const API = {
  statistics: {
    getModeratorData(username) {
      return instance.post(`statistics/rev/user`, username);
    },
    getManagerData(username) {
      return instance.post(`statistics/user`, username);
    },
    getManagersData(offset) {
      return instance.get(`statistics/users/${offset}`);
    },
    getModeratorsData(offset) {
      return instance.get(`statistics/rev/users/${offset}`);
    },
    getTeamsData(offset) {
      return instance.get(`statistics/teams/${offset}`);
    },
    getTeamData(data) {
      return instance.post(`statistics/team`, data);
    },
    getBizData(offset) {
      return instance.get(`statistics/cats/${offset}`);
    },
  },
  notifications: {
    activateItem(data) {
      return instance.post(`tariff-notification/activate`, data);
    },
    editItem(data) {
      return instance.post(`tariff-notification/edit`, data);
    },
    getItems() {
      return instance.get(`tariff-notification`);
    },
  },
  general: {
    uploadAvatar(id, type, data) {
      return instance.post(`files/${type}/avatar/${id}`, data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    },
    getBalance(data) {
      return instance.post(`balance`, data, {
        timeout: 60000,
      });
    },
  },
  client: {
    getUser(username) {
      return instance.get(`client/account/${username}`);
    },
    deleteUser(data) {
      return instance.delete(`client/account`, { data: data });
    },
    updateUser(data) {
      return instance.put(`client/account`, data);
    },
    getStatus() {
      return instance.get(`client/sms-reminds`);
    },
    logout(data) {
      return instance.post("client/logout", data);
    },
    logoutAll(data) {
      return instance.post("client/logout", data);
    },
    checkRegistration(data) {
      return instance.post(`client/login-or-register`, data);
    },
    checkRegistrationByBusiness(data) {
      return instance.post(`client/check-or-register`, data);
    },
    setPassword(data) {
      return instance.post("client/set-password", data);
    },
  },
  business: {
    auth: {
      logout(data) {
        return instance.post("biz/logout", data);
      },
      logoutAll(data) {
        return instance.post("biz/logout", data);
      },
      getMajor(username) {
        return instance.get(`org_acc/biz/${username}`);
      },
      getHostess(username) {
        return instance.get(`hostess/${username}`);
      },
      getMarketer(username) {
        return instance.get(`marketer/${username}`);
      },
    },
    resetPasswordByOwner(data) {
      return instance.post("org_acc/reset-password/biz", data);
    },
    resetPasswordByAdmin(data) {
      return instance.post("org_acc/reset-password/eviso", data);
    },
    setPassword(data) {
      return instance.post("biz/set-password", data);
    },
    major: {
      getMajor(username) {
        return instance.get(`org_acc/biz/${username}`);
      },
      updateMajor(data) {
        return instance.put("org_acc/biz/", data);
      },
    },
    catering: {
      addFavorite(data) {
        return instance.post(`favorite`, data);
      },
      getFavorites() {
        return instance.get(`favorites`);
      },
      getAllCatering(data) {
        return instance.post(`catering/cats`, data);
      },
      getAllCateringByBiz(data) {
        return instance.post(`org_acc/biz/cats`, data);
      },
      getCatering(id) {
        return instance.get(`catering/cat/${id}`);
      },
      deleteCatering(data) {
        return instance.delete(`catering/cat`, { data: data });
      },
      createCatering(data) {
        return instance.post(`catering/cat`, data);
      },
      updateCatering(data) {
        return instance.put(`catering/eviso/cat`, data);
      },
      updateCateringByBiz(data) {
        return instance.put(`catering/biz/cat`, data);
      },
      getSuggestions(realm, data) {
        return instance.post(
          `dadata/${realm === "biz" ? "biz" : "eviso"}/suggest`,
          data
        );
      },
      getCoords(realm, data) {
        return instance.post(
          `${realm === "biz" ? "biz" : "eviso"}/dadata`,
          data
        );
      },
      getCateringTempId() {
        return instance.get(`catering/uuid`);
      },
      async uploadImageByEviso(id, data) {
        const result = await memoizedRefreshToken();
        return instance.post(`files/org/cat/${id}`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${result?.accessToken}`,
          },
        });
      },
      async uploadImageByBiz(id, data) {
        const result = await memoizedRefreshToken();
        return instance.post(`files/business/cat/${id}`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: `Bearer ${result?.accessToken}`,
          },
        });
      },
      getCateringsWithoutClients(data) {
        return instance.post(`business/cats/free`, data);
      },
      linkB2BToCatering(data) {
        return instance.post(`org_acc/cats/link`, data);
      },
    },
    employees: {
      getHostess(username) {
        return instance.get(`hostess/${username}`);
      },
      getMarketer(username) {
        return instance.get(`marketer/${username}`);
      },
      getCateringsByMarketer(data) {
        return instance.post(`marketer/cats`, data);
      },
      getHostesses(data) {
        return instance.post(`hostesses`, data);
      },
      getMarketers(data, realm = "biz") {
        return instance.post(`${realm}/marketers`, data);
      },
      createHostess(data) {
        return instance.post(`hostess`, data);
      },
      updateHostess(data) {
        return instance.put(`hostess`, data);
      },
      deleteHostess(data) {
        return instance.delete("hostess/biz", { data: data });
      },
      deleteHostessByAdmin(data) {
        return instance.delete("hostess/eviso", { data: data });
      },
      createMarketer(data) {
        return instance.post(`marketer`, data);
      },
      updateMarketer(data) {
        return instance.put(`marketer`, data);
      },
      deleteMarketer(data) {
        return instance.delete("marketer/biz", { data: data });
      },
      deleteMarketerByAdmin(data) {
        return instance.delete("marketer/eviso", { data: data });
      },
      getCatsByHostess() {
        return instance.get(`hostess/cats`);
      },
    },
  },
  eviso: {
    auth: {
      logout(data) {
        return instance.post("eviso/logout", data);
      },
      logoutAll(data) {
        return instance.post("eviso/logout", data);
      },
      setPassword(data) {
        return instance.post(`eviso/set-password`, data);
      },
    },
    journal: {
      getRootJournal(data) {
        return instance.post(`org/journal/root-journal`, data);
      },
      getAdminJournal(data) {
        return instance.post(`org/journal/journal`, data);
      },
    },
    admins: {
      getAdmin(id) {
        return instance.get(`org/admins/${id}`);
      },
      getAdmins() {
        return instance.get(`org/admins`);
      },
      createAdmin(data) {
        return instance.post("org/admins", data);
      },
      deleteAdmin(data) {
        return instance.delete(`org/admins`, { data: data });
      },
      updateAdmin(data) {
        return instance.put(`org/admins`, data);
      },
      resetPassword(data) {
        return instance.post(`org/admins/reset-password`, data);
      },
    },
    tariffs: {
      getAllItems() {
        return instance.get(`tariff/all`);
      },
      getAllItemsByBiz() {
        return instance.get(`tariff/biz/all`);
      },
      getItem(id) {
        return instance.get(`tariff/${id}`);
      },
      getCurrentItem(data) {
        return instance.post("tariff/current", data);
      },
      createItem(data) {
        return instance.post("tariff", data, {
          timeout: 60000,
        });
      },
      updateItem(data) {
        return instance.put("tariff", data, {
          timeout: 60000,
        });
      },
      updateItemStatus(data) {
        return instance.post("tariff/status", data);
      },
      deleteTariff(data) {
        return instance.delete(`tariff`, { data: data, timeout: 60000 });
      },
      payTariff(data) {
        return instance.post("tariff/activate", data, {
          timeout: 60000,
        });
      },
      toggleTariffAutoRenewal(data) {
        return instance.post("tariff/auto-renewal", data, {
          timeout: 60000,
        });
      },
      getHistory(data) {
        return instance.post("act-history", data, {
          timeout: 60000,
        });
      },
      getSubscriptions() {
        return instance.get(`subscriptions`);
      },
    },
    packages: {
      getAllItems() {
        return instance.get(`package/all`);
      },
      getAllItemsByBiz() {
        return instance.get(`package/biz/all`);
      },
      getItem(id) {
        return instance.get(`package/${id}`);
      },
      createItem(data) {
        return instance.post("package", data, {
          timeout: 60000,
        });
      },
      updateItem(data) {
        return instance.put("package", data, {
          timeout: 60000,
        });
      },
      updateItemStatus(data) {
        return instance.post("package/status", data);
      },
      deleteItem(data) {
        return instance.delete(`package`, { data: data, timeout: 60000 });
      },
      deletePresentItem(data) {
        return instance.delete(`package/gift-remove`, { data: data });
      },
      payPackage(data) {
        return instance.post("package/activate", data, {
          timeout: 60000,
        });
      },
      createPresent(data) {
        return instance.post("package/gift-activate", data);
      },
      getAllPresents() {
        return instance.get(`package/activated`);
      },
      getStartGift() {
        return instance.get(`package/start-gift`);
      },
      updateStartPresent(data) {
        return instance.post("package/start-gift", data);
      },
    },
    widget: {
      getCaterings() {
        return instance.get(`widget/catering`);
      },
      createItem(data) {
        return instance.post("widget", data);
      },
      updateItem(data) {
        return instance.put("widget", data);
      },
      deleteItem(data) {
        return instance.delete(`widget`, { data: data });
      },
      getAllItems(data) {
        return instance.post("widgets", data);
      },
    },
    clients: {
      getClients(data) {
        return instance.post(`org/admins/clients`, data);
      },
      blockClient(data) {
        return instance.put(`org/admins/clients`, data);
      },
    },
    businessClients: {
      getClient(username) {
        return instance.get(`org_acc/eviso/${username}`);
      },
      getClientByBiz(username) {
        return instance.get(`org_acc/biz/${username}`);
      },
      getClients(data) {
        return instance.post(`org_accs`, data);
      },
      getCateringsByBusinessClient(data) {
        return instance.post(`org_acc/biz/cats`, data);
      },
      getCateringsByEviso(data) {
        return instance.post(`org_acc/eviso/cats`, data);
      },
      getEntitiesByEviso(data) {
        return instance.post(`org_acc/eviso/entities`, data);
      },
      getEntitiesByBusinessClient(data) {
        return instance.post(`org_acc/biz/entities`, data);
      },

      createClient(data) {
        return instance.post(`org_acc`, data);
      },
      updateClient(data) {
        return instance.put(`org_acc`, data);
      },
      updateClientByEviso(data) {
        return instance.put(`org_acc/eviso`, data);
      },
      deleteClient(data) {
        return instance.delete("org_acc", { data: data });
      },
      deleteCatering(data) {
        return instance.post(`org_acc/cats/link`, { ...data, link: false });
      },
      addCaterings(data) {
        return instance.post(`org_acc/cats/link`, { ...data, link: true });
      },
    },
    employee: {
      teams: {
        getTeams() {
          return instance.get("org/employee/team");
        },
        createTeam(data) {
          return instance.post(`org/employee/team`, data);
        },
        updateTeam(data) {
          return instance.put(`org/employee/team`, data);
        },
        deleteManagerTeam(data) {
          return instance.delete("org/employee/team", { data: data });
        },
      },
      exclude: {
        getDeleteRequests() {
          return instance.get(`org/employee/exclude`);
        },
        createDeleteRequest(data) {
          return instance.post(`org/employee/exclude`, data);
        },
        acceptDeleteRequest(data) {
          return instance.delete(`org/employee/exclude`, { data: data });
        },
      },
      managers: {
        getEmployee(username) {
          return instance.get(`org/employee/${username}`);
        },
        getEmployees() {
          return instance.get("org/employee");
        },
        createEmployee(data) {
          return instance.post("org/employee", data);
        },
        updateEmployee(data) {
          return instance.put("org/employee", data);
        },
        deleteEmployee(data) {
          return instance.delete("org/employee", { data: data });
        },
        resetPassword(data) {
          return instance.post(`org/employee/reset-password`, data);
        },
      },
    },
    moderator: {
      getModerator(username) {
        return instance.get(`org/moderator/${username}`);
      },
      getModerators() {
        return instance.get("org/moderator");
      },
      linkModerator(data) {
        return instance.post("org_acc/eviso/man_link", data);
      },
      createModerator(data) {
        return instance.post("org/moderator", data);
      },
      updateModerator(data) {
        return instance.put("org/moderator", data);
      },
      deleteModerator(data) {
        return instance.delete("org/moderator", { data: data });
      },
      resetPassword(data) {
        return instance.post(`org/moderator/reset-password`, data);
      },
    },
    secretaries: {
      getSecretary(username) {
        return instance.get(`org/secretary/${username}`);
      },
      getSecretaries() {
        return instance.get("org/secretary");
      },
      createSecretary(data) {
        return instance.post("org/secretary", data);
      },
      updateSecretary(data) {
        return instance.put("org/secretary", data);
      },
      deleteSecretary(data) {
        return instance.delete("org/secretary", { data: data });
      },
      resetPassword(data) {
        return instance.post(`org/secretary/reset-password`, data);
      },
    },
  },
  reviews: {
    getAuthorItems(userId) {
      return instance.get(`review/client/${userId}`);
    },
    getCatItems(catId) {
      return instance.get(`review/cat/${catId}`);
    },
    getId() {
      return instance.get(`review/uuid`);
    },
    getCateringItems(id) {
      return instance.get(`review/cat/${id}`);
    },
    getCateringItemsMap(id) {
      return instance.get(`review/cat/${id}/map`);
    },
    getItemsByModerator(data) {
      return instance.post("moderator/cats", data);
    },
    createItem(data) {
      return instance.post("review", data);
    },
    updateItemStatus(data) {
      return instance.put("review", data);
    },
    deleteItem(data) {
      return instance.delete("review", { data: data });
    },
    deleteCommentItem(data) {
      return instance.delete("review/comment", { data: data });
    },
    uploadImage(id, data) {
      return instance.post(`files/client/rev/${id}`, data, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    },
    uploadCommentImage(id, cid, data, client) {
      return instance.post(
        `files/${client ? "client" : "business"}/rev/${id}/comment/${cid}`,
        data,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
    },
    deleteImageComment(revId, cid, fileId, role) {
      return instance.delete(
        `files/${role}/rev/${revId}/comment/${cid}/${fileId}`
      );
    },
    deleteImage(revId, fileId) {
      return instance.delete(`files/client/rev/${revId}/${fileId}`);
    },
    toggleLike(data) {
      return instance.post(`review/like/toggle`, data);
    },
    toggleLikeComment(data) {
      return instance.post(`review/comment/like/toggle`, data);
    },
    createComment(data) {
      return instance.post(`review/comment`, data);
    },
    getImages(id) {
      return instance.get(`files/rev/${id}`);
    },
    getCommentPhotos(id, cid) {
      return instance.get(`files/rev/${id}/comment/${cid}`);
    },
  },
  counterparties: {
    getLegalEntities(data) {
      return instance.post(`org_acc/eviso/entity/jurs`, data);
    },
    getIps(data) {
      return instance.post(`org_acc/eviso/entity/ips`, data);
    },
    getLegalEntitiesByBiz(data) {
      return instance.post(`org_acc/biz/entity/jurs`, data);
    },
    getIpsByBiz(data) {
      return instance.post(`org_acc/biz/entity/ips`, data);
    },
    getInfoByEviso(data) {
      return instance.post(`dadata/eviso/entity`, data);
    },
    getInfoByBiz(data) {
      return instance.post(`dadata/biz/entity`, data);
    },
    createIpByEviso(data) {
      return instance.post(`org_acc/eviso/entity/ip`, data);
    },
    createJurByEviso(data) {
      return instance.post(`org_acc/eviso/entity/jur`, data);
    },
    createIpByBiz(data) {
      return instance.post(`org_acc/biz/entity/ip`, data);
    },
    createJurByBiz(data) {
      return instance.post(`org_acc/biz/entity/jur`, data);
    },
    updateIpByEviso(data) {
      return instance.put(`org_acc/eviso/entity/ip`, data);
    },
    updateJurByEviso(data) {
      return instance.put(`org_acc/eviso/entity/jur`, data);
    },
    updateIpByBiz(data) {
      return instance.put(`org_acc/biz/entity/ip`, data);
    },
    updateJurByBiz(data) {
      return instance.put(`org_acc/biz/entity/jur`, data);
    },
    deleteEntityJurByEviso(data) {
      return instance.delete("org_acc/eviso/entity/jur", {
        data: data,
        timeout: 60000,
      });
    },
    deleteEntityIpByEviso(data) {
      return instance.delete("org_acc/eviso/entity/ip", {
        data: data,
        timeout: 60000,
      });
    },
    deleteEntityJurByBiz(data) {
      return instance.delete("org_acc/biz/entity/jur", {
        data: data,
        timeout: 60000,
      });
    },
    deleteEntityIpByBiz(data) {
      return instance.delete("org_acc/biz/entity/ip", {
        data: data,
        timeout: 60000,
      });
    },
    getFreeCatering(data) {
      return instance.post(`org_acc/cats/free`, data);
    },
    inviteEdm(roleGroup, data) {
      return instance.post(`${roleGroup}/edm-invite`, data, {
        timeout: 60000,
      });
    },
  },
  notices: {
    markAsRead(data, realm) {
      return instance.post(`${realm}/notification`, data);
    },
    getNotifications(userId, realm) {
      return instance.get(`${realm}/notification/${userId}`);
    },
  },
  scheme: {
    createSchemeByEviso(data) {
      return instance.post(`catering/eviso/seating-map`, data);
    },
    createSchemeByMajor(data) {
      return instance.post(`catering/biz/seating-map`, data);
    },
    updateSchemeByEviso(data) {
      return instance.put(`catering/eviso/seating-map`, data);
    },
    updateSchemeByMajor(data) {
      return instance.put(`catering/biz/seating-map`, data);
    },
  },
  booking: {
    bookTable(data) {
      return instance.post(`book`, data);
    },
    getBookingItemsByClient(data) {
      return instance.post(`booking/client`, data);
    },
    getBookings(data) {
      return instance.post(`booking/full`, data);
    },
    getSeats(data) {
      return instance.post(`status-get`, data);
    },
    bookEdit(data) {
      return instance.put(`book`, data);
    },
    cancelBooking(data) {
      return instance.put(`book/client/edit`, data);
    },
    visitChange(data) {
      return instance.post(`visit/change`, data);
    },
    visitEnd(data) {
      return instance.post(`visit/end`, data);
    },
    visitStart(data) {
      return instance.post(`visit/start`, data);
    },
    waitingEdit(data) {
      return instance.put(`waiting/edit`, data);
    },
    waitingEnd(data) {
      return instance.put(`waiting/end`, data);
    },
    waitingNew(data) {
      return instance.post(`waiting/new`, data);
    },
    waitingStart(data) {
      return instance.post(`waiting/start`, data);
    },
    waitingReturn(data) {
      return instance.post(`waiting/return`, data);
    },
  },
  documents: {
    getItems(data) {
      return instance.post(`docs`, data, {
        timeout: 60000,
      });
    },
    getItemsByOrg(data) {
      return instance.post(`org/docs`, data, {
        timeout: 60000,
      });
    },
    getItemsByBiz(data) {
      return instance.post(`biz/docs`, data, {
        timeout: 60000,
      });
    },
    getBillItems(data) {
      return instance.post(`docs/bills`, data);
    },
    getContractItems(data) {
      return instance.post(`docs/contracts`, data);
    },
    getRejectedItems(data) {
      return instance.post(`problematic/in_work`, data, {
        timeout: 60000,
      });
    },
    getProcessedItems(data) {
      return instance.post(`problematic/done`, data, {
        timeout: 60000,
      });
    },
    changeDocumentStatus(data) {
      return instance.post(`status`, data, {
        timeout: 60000,
      });
    },
    getDocumentFile(data) {
      return instance.post(`pdf`, data, {
        timeout: 60000,
      });
    },
    getDocumentFileByBiz(data) {
      return instance.post(`biz/pdf-show`, data, {
        timeout: 60000,
      });
    },
    getDocumentEntities(data) {
      return instance.post(`problematic/list`, data, {
        timeout: 60000,
      });
    },
    changePaymentInfo(data) {
      return instance.post(`payment`, data, {
        timeout: 60000,
      });
    },
    changePaymentComment(data) {
      return instance.post(`problematic/comment`, data, {
        timeout: 60000,
      });
    },
    makePayment(data) {
      return instance.post(`offer`, data, {
        timeout: 60000,
      });
    },
    makePaymentByOrg(data) {
      return instance.post(`org/offer`, data, {
        timeout: 60000,
      });
    },
    changeBalanceByOrg(data) {
      return instance.post(`add-balance`, data);
    },
    getDocumentPDFFile(url) {
      return instance.get(url, {
        headers: { "Content-Type": "application/pdf" },
        responseType: "blob",
      });
    },
    sendActByEmail(data) {
      return instance.post(`biz/email-pdf`, data, {
        timeout: 60000,
      });
    },
  },
};
