import { Long_Polling } from "@/assets/js/api/longPollings/longPolling";

export const seatsInfoLongPolling = {
  timeoutId: null,
  isStopped: false,
  interval: 1000,
  abortController: null,

  async start(data, callback, interval = this.interval) {
    if (this.isStopped) {
      return;
    }

    if (!this.timeoutId) {
      this.timeoutId = setTimeout(async () => {
        await this.poll(data, callback, interval);
      }, interval);
    }
  },

  async poll(data, callback, interval) {
    try {
      this.abortController = new AbortController();
      const signal = this.abortController.signal;

      const response = await Long_Polling.getSeatsInfo(data, { signal });

      if (this.isStopped) {
        return;
      }

      if (callback && typeof callback === "function") {
        callback(response.data);
      }

      if (!this.isStopped) {
        this.timeoutId = setTimeout(async () => {
          await this.poll(data, callback, interval);
        }, interval);
      }
    } catch (e) {
      if (this.isStopped) {
        return;
      }

      if (e.name === "AbortError") {
        return;
      }

      this.timeoutId = setTimeout(async () => {
        await this.poll(data, callback, interval);
      }, interval);
    }
  },

  stop() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }

    if (this.abortController) {
      this.abortController.abort();
    }

    this.isStopped = true;
  },

  reset() {
    this.isStopped = false;
  },
};
