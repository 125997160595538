import axios from "axios";
import { memoizedRefreshToken } from "@/assets/js/utils/refreshToken";

const instanceV2 = axios.create({
  baseURL: "/api/v2/",
  withCredentials: true,
});

instanceV2.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] =
      "Bearer " + localStorage.getItem("accessToken");
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

instanceV2.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error?.config;

    if (error?.response?.status === 401 && !config?.sent) {
      config.sent = true;

      const result = await memoizedRefreshToken();

      if (result?.accessToken) {
        config.headers = {
          ...config.headers,
          authorization: `Bearer ${result?.accessToken}`,
        };
      }

      return axios(config);
    }
    return Promise.reject(error);
  }
);

const instance = axios.create({
  baseURL: "/api/v1/",
  withCredentials: true,
});

instance.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] =
      "Bearer " + localStorage.getItem("accessToken");
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error?.config;

    if (error?.response?.status === 401 && !config?.sent) {
      config.sent = true;

      const result = await memoizedRefreshToken();

      if (result?.accessToken) {
        config.headers = {
          ...config.headers,
          authorization: `Bearer ${result?.accessToken}`,
        };
      }

      return axios(config);
    }
    return Promise.reject(error);
  }
);

export const Long_Polling = {
  getNotifications(userId, realm, { signal }) {
    return instanceV2.get(`${realm}/notification/${userId}`, {
      timeout: 20000,
      signal,
    });
  },
  getSeatsInfo(data, { signal }) {
    return instance.post("status-long", data, { timeout: 20000, signal });
  },
  getBookingInfo(data, { signal }) {
    return instance.post("book-long", data, { timeout: 20000, signal });
  },
};
