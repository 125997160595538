<template>
  <HeaderReservations v-if="isReservations" />
  <HeaderWidget v-else-if="isWidgetPage" />
  <HeaderScheme v-else-if="isHeaderScheme && isMobile" />
  <Header v-else-if="!isEvisoBusinessAuth && !isBookingSchemeEditor" />
  <HeaderSchemeEditor v-else-if="isBookingSchemeEditor" />
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

import Header from "@/components/ui/header/Header.vue";
import HeaderWidget from "@/components/ui/header/HeaderWidget.vue";
import HeaderScheme from "@/components/ui/header/HeaderScheme.vue";
import HeaderSchemeEditor from "@/components/ui/header/HeaderSchemeEditor.vue";
import HeaderReservations from "@/components/ui/header/HeaderReservation.vue";

const store = useStore();
const route = useRoute();

const isWidgetPage = computed(() => {
  return route.name === "widget";
});

const isReservations = computed(() => {
  return route.name?.includes("catering/business-booking/:catId");
});

const isEvisoBusinessAuth = computed(() => {
  return route.name === "auth-biz" || route.name === "auth-eviso";
});

const isBookingSchemeEditor = computed(() => {
  return route.name === "booking-scheme";
});

const isHeaderScheme = computed(() => {
  return route.name === "booking";
});

const isMobile = computed(() => {
  return store.getters.screenType === "mobile";
});
</script>
