<template>
  <perfect-scrollbar class="container" v-click-outside="clickOutside">
    <ObjectItem
      v-for="catering in caterings"
      :catId="catering"
      :key="catering"
    />
  </perfect-scrollbar>
</template>

<script>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";

import router from "@/router";
import { API_WITHOUT_GUARDS } from "@/assets/js/api/apiWithoutGuards";
import "swiper/css/bundle";
import { RESTAURANT_TYPES } from "@/assets/js/utils/consts";
import Logo from "@/assets/images/logo.png";
import ObjectItem from "@/components/pages/home/ObjectItem.vue";
import SwiperModal from "@/components/modals/SwiperModal.vue";

export default {
  name: "Items",
  components: {
    ObjectItem,
    PerfectScrollbar,
  },

  data() {
    return {
      details: {},
      isLoaded: false,
      btnHovered: false,
      images: [],
      logo: "",
      defaultLogo: Logo,
      photos: [],
      swiper: null,
    };
  },

  methods: {
    openModal(images, index, isComment) {
      this.$store.dispatch("openModal", {
        value: {
          component: SwiperModal,
          props: {
            images: {
              urls: isComment ? images : images.map((el) => el.url),
              initIndex: index,
            },
          },
        },
      });
    },
    book() {
      router.push(`client-booking`);
    },
    getType(id) {
      return RESTAURANT_TYPES[id];
    },
    clickOutside() {
      this.isLoaded = false;
    },
  },

  computed: {
    isDisabled() {
      if (this.details?.scheme) {
        return !(!this.isAuth || this.role === "CLIENT");
      } else {
        return true;
      }
    },
    isFav() {
      return !!this.$store.state.restaurants.favRestaurants.find(
        (el) => el.catId === this.cateringID
      );
    },
    roleGroup() {
      return this.$store.getters.roleGroup;
    },
    role() {
      return this.$store.getters.role;
    },
    isAuth() {
      return this.$store.state.general.auth.isAuth;
    },
    userId() {
      return this.$store.state.general.userInfo.id;
    },
    caterings() {
      return this.$store.state.clientRestaurants.clickedCluster.map(
        (el) => el.options.catId
      );
    },
    page() {
      return this.$store.state.reviews.page;
    },
    pagesCount() {
      return this.$store.state.reviews.pagesCount;
    },
    reviews() {
      return this.$store.state.reviews.items;
    },
  },

  watch: {
    async cateringID(value) {
      this.isLoaded = false;
      this.$store.commit({ type: "resetCateringReview" });
      const res = await API_WITHOUT_GUARDS.client.getCateringByID(
        this.cateringID
      );
      if (value) {
        this.details = res.data;
        this.$store.dispatch("loadCateringItems", {
          value: this.details.id,
        });
        setTimeout(() => {
          const el = document.getElementsByClassName("ps__rail-y")[0];
          el.addEventListener("click", (event) => {
            event.stopPropagation();
          });
          el.addEventListener("mousemove", (event) => {
            event.stopPropagation();
          });
          el.addEventListener("dbclick", (event) => {
            event.stopPropagation();
          });
          el.addEventListener("wheel", (event) => {
            event.stopPropagation();
          });
        });
      }
      this.isLoaded = true;
    },
  },

  beforeUnmount() {
    this.$store.commit({ type: "setReviews", value: [] });
  },
};
</script>

<style scoped lang="scss">
.svg-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000000;
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 100%;
  background-color: #ffffff;
  border-left: 1px solid #d6d6d6;
  cursor: auto;
  overflow-y: auto;
}

.check {
  padding: 24px 16px;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  border-bottom: 1px solid #d6d6d6;

  &-bold {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
  }
}

.reviews {
  padding: 24px 0 8px 0;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  border-bottom: 1px solid #d6d6d6;

  &-header {
    padding: 0 24px;
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;

    span {
      color: #f18e35;
    }
  }

  .review {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  .more {
    padding: 0 16px 24px 16px;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #f18e35;
    cursor: pointer;
  }
}

.empty-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #858585;

  svg {
    margin-bottom: 18px;
  }

  .question {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 8px;
  }

  .action {
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
}

.features {
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  border-bottom: 1px solid #d6d6d6;

  &-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 16px;
  }

  &-blocks {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;

    div {
      margin-right: 10px;
      padding: 4px 12px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      border: 1px solid #f18e35;
      border-radius: 8px;
    }
  }
}

.kitchens {
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  border-bottom: 1px solid #d6d6d6;

  &-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 16px;
  }

  &-blocks {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;

    div {
      margin-right: 10px;
      padding: 4px 12px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      border: 1px solid #f18e35;
      border-radius: 8px;
    }
  }
}

.main-info {
  display: flex;
  align-items: flex-start;
  padding: 24px 16px;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #222222;
  border-bottom: 1px solid #d6d6d6;

  &-price {
    margin-left: auto;
  }

  &-rating {
    display: flex;
    align-items: center;
    margin-top: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #858585;

    &-number {
      margin-right: 10px;
    }

    svg:not(:last-child) {
      margin-right: 6px;
    }
  }

  &-title {
    overflow-wrap: break-word;

    &-name {
      font-family: $font-family-secondary;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
    }

    &-type {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
    }
  }

  &-default-logo {
    flex: 0 0 auto;
    width: 62px;
    height: 62px;
    margin-right: 16px;
    border-radius: 50%;
    background-color: #ebebeb;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    filter: grayscale(1);
  }

  &-logo {
    flex: 0 0 auto;
    width: 62px;
    height: 62px;
    margin-right: 16px;
    border-radius: 50%;
  }
}

.details {
  padding: 26px 18px;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #222222;
  border-bottom: 1px solid #d6d6d6;

  & > div {
    display: flex;
    align-items: center;
  }

  & > div:not(:last-child) {
    margin-bottom: 18px;
  }

  span,
  a {
    margin-left: 6px;
  }

  &-tablo {
    margin-right: 12px;
    color: #f18e35;
  }
}

.swiper {
  width: 400px;
  height: 220px;
  overflow: initial;

  .default-image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 220px;
    filter: grayscale(1);
    background: #ebebeb;

    img {
      width: 100px;
      height: 100px;
    }
  }

  .image {
    height: 220px;
    cursor: pointer;
  }
}

.btns {
  padding: 24px 16px;
}

.btn {
  padding: 12px 20px;
  width: 368px;
  height: 52px;
  border-radius: 12px;

  @include mobile {
    width: 100%;
  }

  &:first-child {
    margin-bottom: 16px;
  }
}

.stars {
  margin-top: 16px;
}

.favorite {
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: 1;
  cursor: pointer;
}

.swiper {
  height: 100%;
}

.button-next {
  position: absolute;
  top: 50%;
  right: 8px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  z-index: 15;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(34, 34, 34, 0.5);
  transform: translateY(-50%) rotate(180deg);
}

.button-prev {
  position: absolute;
  top: 50%;
  left: 8px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  z-index: 15;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(34, 34, 34, 0.5);
  transform: translateY(-50%);
}

.swiper-button-lock {
  display: none;
}

.mySwiper {
  overflow: hidden;
}
</style>
