<template>
  <Teleport to="body">
    <Transition name="modal-fade">
      <div
        class="modal-wrapper"
        @click.self="onClickOutsideHandler"
        aria-modal="true"
        v-if="isOpen"
        role="dialog"
        tabindex="-1"
        ref="modal"
      >
        <div class="inner">
          <h2>{{ title }}</h2>
          <p>{{ text }}</p>
          <div class="buttons">
            <VButton :outline="reverse" class="button" @click="acceptHandler">
              {{ acceptButtonText }}
            </VButton>
            <VButton class="button" @click="cancelHandler" :outline="!reverse">
              {{ cancelButtonText }}
            </VButton>
          </div>
          <IconPopupCross
            v-if="screenWidth !== 'mobile'"
            class="modal-cross"
            @click="isOpen = false"
          />
        </div>
      </div>
    </Transition>
  </Teleport>
  <slot :open="openHandler"></slot>
</template>

<script setup>
import {
  ref,
  onMounted,
  onUnmounted,
  computed,
  defineEmits,
  defineProps,
  defineExpose,
} from "vue";
import { useStore } from "vuex";
import IconPopupCross from "@/components/icons/IconPopupCross.vue";
import VButton from "@/components/ui/VButton.vue";

const store = useStore();
const modal = ref(null);

defineProps({
  title: String,
  text: String,
  acceptButtonText: String,
  cancelButtonText: String,
  outline: Boolean,
  reverse: {
    default: false,
    type: Boolean,
  },
});

const emit = defineEmits(["accept", "cancel"]);

const isOpen = ref(false);

const openHandler = () => {
  isOpen.value = true;
};
const acceptHandler = () => {
  emit("accept");
  isOpen.value = false;
};
const cancelHandler = () => {
  emit("cancel");
  isOpen.value = false;
};

function keydownListener(event) {
  if (event.key === "Escape") store.dispatch("closeModal");
}

onMounted(() => {
  document.addEventListener("keydown", keydownListener);
});

onUnmounted(() => {
  document.removeEventListener("keydown", keydownListener);
});

const onClickOutsideHandler = () => {
  isOpen.value = false;
};

const screenWidth = computed(() => {
  return store.getters.screenType;
});

defineExpose({
  isOpen,
});
</script>

<style lang="scss" scoped>
.modal-wrapper {
  position: fixed;
  left: 0;
  top: 0;

  z-index: 9999999999;

  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);

  display: flex;
}

.modal-fade-enter-from,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: 0.25s ease all;
}

.inner {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 448px;
  margin: auto;
  padding: 32px 56px 40px 56px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;

  @media screen and (max-width: 841px) {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    box-shadow: 0 -2px 10px 0 #0000000d;
    border-radius: 20px 20px 0 0;
    padding: 32px 24px 24px 24px;
  }
}

h2 {
  margin-bottom: 24px;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #f0811e;
}

p {
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #222222;
}

.buttons {
  display: flex;
  gap: 16px;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 32px;
}

.button {
  width: 100%;
}

.modal-cross {
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
}
</style>
