<template>
  <div class="inner">
    <h2>Вход</h2>
    <form
      ref="form"
      class="form"
      @submit.prevent="handleSubmit"
      @keydown.enter="handleSubmit"
    >
      <VInput
        v-model:value="username"
        class="input"
        :mask="role === 'client' ? '+7(###) ###-##-##' : ''"
        :placeholder="placeholder"
        @input="error = ''"
        @enter="handleSubmit"
      />

      <VInput
        v-model:value="password"
        class="input"
        type="password"
        placeholder="Пароль"
        @input="error = ''"
        @enter="handleSubmit"
      />

      <span v-if="error" class="error">
        {{ error }}
      </span>

      <p class="item" @click="onRestorationClickHandler">Забыли пароль?</p>

      <div class="buttons">
        <AsyncButton class="button" type="submit" :is-pending="isPending">
          Войти
        </AsyncButton>
      </div>
    </form>
    <IconPopupCross
      v-if="screenWidth !== 'mobile' && !isEvisoBusinessAuth"
      class="modal-cross"
      @click="$store.dispatch('closeModal')"
    />
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

const store = useStore();
const route = useRoute();

const screenWidth = computed(() => {
  return store.getters.screenType;
});

const isEvisoBusinessAuth = computed(() => {
  return route.name === "auth-biz" || route.name === "auth-eviso";
});
</script>

<script>
import AsyncButton from "@/components/ui/AsyncButton.vue";
import VInput from "@/components/ui/VInput.vue";
import jwt_decode from "jwt-decode";
import router from "@/router";
import { API } from "@/assets/js/api/api";
import { API_WITHOUT_GUARDS } from "@/assets/js/api/apiWithoutGuards";
import IconPopupCross from "@/components/icons/IconPopupCross.vue";
import AuthBannedAccountModal from "@/components/modals/auth/AuthBannedAccountModal.vue";
import AuthRestorationModal from "@/components/modals/auth/AuthRestorationModal.vue";

export default {
  name: "AuthLoginModal",

  components: {
    IconPopupCross,
    AsyncButton,
    VInput,
  },

  data() {
    return {
      username: "",
      password: "",
      email: "",
      error: "",
      isPending: false,
    };
  },

  computed: {
    role() {
      if (this.$route.name === "auth-eviso") {
        return "eviso";
      } else if (this.$route.name === "auth-biz") {
        return "biz";
      } else return "client";
    },
    roleGroup() {
      return this.$store.getters.roleGroup;
    },
    placeholder() {
      if (this.role === "eviso") {
        return "Email";
      } else if (this.role === "biz") {
        return "Телефон или email";
      } else return "Телефон";
    },
  },

  methods: {
    onRestorationClickHandler() {
      this.$store.dispatch("openModal", {
        value: {
          component: AuthRestorationModal,
          props: {
            roleType: this.role,
          },
        },
      });
    },
    async handleSubmit() {
      try {
        this.isPending = true;
        let res;

        if (this.role === "eviso") {
          res = await API_WITHOUT_GUARDS.eviso.login({
            username: this.username,
            password: this.password,
          });
        } else if (this.role === "biz") {
          res = await API_WITHOUT_GUARDS.business.login({
            username: this.username,
            password: this.password,
          });
        } else {
          res = await API_WITHOUT_GUARDS.client.login({
            username: this.username.replace(/[^0-9+]/g, ""),
            password: this.password,
          });
        }
        this.$store.dispatch("setLogin", { value: true });
        this.$store.commit({
          type: "setUser",
          value: res.data.user,
        });
        localStorage.setItem("accessToken", res.data.jwt.accessToken);
        localStorage.setItem("refreshToken", res.data.jwt.refreshToken);
        const token = res.data.jwt.accessToken;
        const decoded = jwt_decode(token);
        this.$store.commit({ type: "setjwtAT", value: decoded });
        let resLogin;
        if (
          decoded.realm_access.roles.includes("ROOT") ||
          decoded.realm_access.roles.includes("ADMIN")
        ) {
          resLogin = await API.eviso.admins.getAdmin(
            decoded.preferred_username
          );
          this.$store.commit({ type: "setUserInfo", value: resLogin.data });
        } else if (
          decoded.realm_access.roles.includes("HEAD") ||
          decoded.realm_access.roles.includes("MANAGER")
        ) {
          resLogin = await API.eviso.employee.managers.getEmployee(
            decoded.preferred_username
          );
          this.$store.commit({ type: "setUserInfo", value: resLogin.data });
        } else if (decoded.realm_access.roles.includes("MODERATOR")) {
          resLogin = await API.eviso.moderator.getModerator(
            decoded.preferred_username
          );
          this.$store.commit({ type: "setUserInfo", value: resLogin.data });
        } else if (decoded.realm_access.roles.includes("SECRETARY")) {
          resLogin = await API.eviso.secretaries.getSecretary(
            decoded.preferred_username
          );
          this.$store.commit({ type: "setUserInfo", value: resLogin.data });
        } else if (decoded.realm_access.roles.includes("MAJOR")) {
          resLogin = await API.business.auth.getMajor(
            decoded.preferred_username
          );

          let balance = 0;
          let entityBalances = [];

          try {
            const { data } = await API.general.getBalance({
              ownerId: resLogin.data.id,
            });
            balance = data.sum;
            entityBalances = data.entityBalances;
          } catch (e) {
            balance = null;
            console.log(e);
          }

          this.$store.commit({
            type: "setUserInfo",
            value: { ...resLogin.data, balance, entityBalances },
          });
        } else if (decoded.realm_access.roles.includes("HOSTESS")) {
          resLogin = await API.business.auth.getHostess(
            decoded.preferred_username
          );
          this.$store.commit({ type: "setUserInfo", value: resLogin.data });
        } else if (decoded.realm_access.roles.includes("MARKETER")) {
          resLogin = await API.business.auth.getMarketer(
            decoded.preferred_username
          );

          let balance = 0;
          let entityBalances = [];

          try {
            const { data } = await API.general.getBalance({
              ownerId: resLogin.data.ownerId,
            });
            balance = data.sum;
            entityBalances = data.entityBalances;
          } catch (e) {
            balance = null;
            console.log(e);
          }

          this.$store.commit({
            type: "setUserInfo",
            value: { ...resLogin.data, balance, entityBalances },
          });
        } else {
          resLogin = await API.client.getUser(decoded.preferred_username);
          this.$store.commit({ type: "setUserInfo", value: resLogin.data });
        }

        if (decoded.realm_access.roles.includes("ROOT")) {
          router.push("/account/administrators");
        } else if (decoded.realm_access.roles.includes("ADMIN")) {
          router.push("/account/catering");
        } else if (decoded.realm_access.roles.includes("HEAD")) {
          router.push("/account/catering");
        } else if (decoded.realm_access.roles.includes("MANAGER")) {
          router.push("/account/catering");
        } else if (decoded.realm_access.roles.includes("MODERATOR")) {
          router.push("/account/reviews");
        } else if (decoded.realm_access.roles.includes("SECRETARY")) {
          router.push("/account/settings");
        } else if (decoded.realm_access.roles.includes("MAJOR")) {
          router.push("/account/catering");
        } else if (decoded.realm_access.roles.includes("HOSTESS")) {
          router.push("/account/catering/business-booking");
        } else if (decoded.realm_access.roles.includes("MARKETER")) {
          router.push("/account/catering");
        } else {
          router.push("/");
        }
        this.$store.dispatch("closeModal");
        this.$store.dispatch("initNotices");
        let avatar;
        try {
          avatar = await API_WITHOUT_GUARDS.user.getAvatar(
            resLogin.data.id,
            this.roleGroup
          );
        } catch (e) {
          avatar = null;
          console.log(e);
        }
        this.$store.commit({
          type: "setAvatar",
          value: avatar?.data?.url,
        });
      } catch (e) {
        if (e.response.status === 403) {
          this.$store.dispatch("openModal", {
            value: {
              component: AuthBannedAccountModal,
              props: {
                phone: this.phone,
              },
            },
          });
        } else {
          this.error = e.response?.data?.message;
        }
      } finally {
        this.isPending = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.inner {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 448px;
  margin: auto;
  padding: 32px 56px 40px 56px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;

  @media screen and (max-width: 841px) {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    box-shadow: 0 -2px 10px 0 #0000000d;
    border-radius: 20px 20px 0 0;
    padding: 32px 24px 24px 24px;
  }
}

h2 {
  margin-bottom: 24px;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #f0811e;
}

p {
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #222222;
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 32px;
}

.button {
  width: 100%;
}

.modal-cross {
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
}

.form {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.input {
  height: 40px;
  margin-top: 16px;

  &:first-child {
    margin-top: 0;
  }
}

.error {
  position: relative;
  margin-top: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #f03738;
}

.item {
  display: flex;
  justify-content: center;
  margin-top: 24px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #f18e35;
  cursor: pointer;
  user-select: none;
}
</style>
