<template>
  <div class="container">
    <span v-if="isNumber" class="number">{{ value }}</span>
    <div class="stars">
      <IconStar :is-filled="getFilledValue(1)" />
      <IconStar :is-filled="getFilledValue(2)" />
      <IconStar :is-filled="getFilledValue(3)" />
      <IconStar :is-filled="getFilledValue(4)" />
      <IconStar :is-filled="getFilledValue(5)" />
    </div>
    <span v-if="date" class="date">{{ formattedDate }}</span>
  </div>
</template>

<script>
import IconStar from "@/components/icons/IconStar";
import { getDate } from "@/assets/js/utils/helpers";

export default {
  name: "VStars",
  components: {
    IconStar,
  },
  props: {
    isNumber: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Number,
      default: 0,
    },
    date: {
      type: String,
      default: "",
    },
  },
  methods: {
    getFilledValue(value) {
      return Math.round(this.value) >= value;
    },
  },
  computed: {
    formattedDate() {
      return getDate(this.date);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #858585;

  svg:not(:last-child) {
    margin-right: 6px;
  }
}

.number {
  margin-right: 10px;
}

.date {
  margin-left: 10px;
}
</style>
