<template>
  <VButton class="AsyncButton" :disabled="isDisabled || isPending">
    <template #leftAddon>
      <transition name="fade-fast">
        <IconLoad v-if="isPending" class="preloader" />
      </transition>
    </template>
    <slot></slot>
  </VButton>
</template>

<script>
import VButton from "@/components/ui/VButton";
import IconLoad from "@/components/icons/IconLoad";

export default {
  name: "AsyncButton",
  components: { VButton, IconLoad },
  props: {
    isPending: Boolean,
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.AsyncButton {
  position: relative;

  &:global(.v-button) {
    height: 40px;
    padding: 0 3.25rem;
  }
}

.preloader {
  position: relative;
  z-index: 1;
  width: 16px;
  height: 16px;

  animation: 3s linear infinite rotate;

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
}
</style>
