<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.0026 15.9987C14.5359 15.9987 13.2804 15.4765 12.2359 14.432C11.1915 13.3876 10.6693 12.132 10.6693 10.6654C10.6693 9.1987 11.1915 7.94314 12.2359 6.8987C13.2804 5.85425 14.5359 5.33203 16.0026 5.33203C17.4693 5.33203 18.7248 5.85425 19.7693 6.8987C20.8137 7.94314 21.3359 9.1987 21.3359 10.6654C21.3359 12.132 20.8137 13.3876 19.7693 14.432C18.7248 15.4765 17.4693 15.9987 16.0026 15.9987ZM24.0026 26.6654H8.0026C7.26927 26.6654 6.64171 26.4045 6.11994 25.8827C5.59727 25.36 5.33594 24.732 5.33594 23.9987V22.932C5.33594 22.1765 5.5306 21.4818 5.91994 20.848C6.30838 20.2151 6.82483 19.732 7.46927 19.3987C8.84705 18.7098 10.247 18.1929 11.6693 17.848C13.0915 17.504 14.5359 17.332 16.0026 17.332C17.4693 17.332 18.9137 17.504 20.3359 17.848C21.7582 18.1929 23.1582 18.7098 24.5359 19.3987C25.1804 19.732 25.6968 20.2151 26.0853 20.848C26.4746 21.4818 26.6693 22.1765 26.6693 22.932V23.9987C26.6693 24.732 26.4084 25.36 25.8866 25.8827C25.3639 26.4045 24.7359 26.6654 24.0026 26.6654ZM8.0026 23.9987H24.0026V22.932C24.0026 22.6876 23.9417 22.4654 23.8199 22.2654C23.6973 22.0654 23.5359 21.9098 23.3359 21.7987C22.1359 21.1987 20.9248 20.7485 19.7026 20.448C18.4804 20.1485 17.247 19.9987 16.0026 19.9987C14.7582 19.9987 13.5248 20.1485 12.3026 20.448C11.0804 20.7485 9.86927 21.1987 8.66927 21.7987C8.46927 21.9098 8.30838 22.0654 8.1866 22.2654C8.06394 22.4654 8.0026 22.6876 8.0026 22.932V23.9987ZM16.0026 13.332C16.7359 13.332 17.3639 13.0707 17.8866 12.548C18.4084 12.0263 18.6693 11.3987 18.6693 10.6654C18.6693 9.93203 18.4084 9.30448 17.8866 8.7827C17.3639 8.26003 16.7359 7.9987 16.0026 7.9987C15.2693 7.9987 14.6417 8.26003 14.1199 8.7827C13.5973 9.30448 13.3359 9.93203 13.3359 10.6654C13.3359 11.3987 13.5973 12.0263 14.1199 12.548C14.6417 13.0707 15.2693 13.332 16.0026 13.332Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "IconPersonSidebar",
  props: {
    color: {
      type: String,
      default: "#F18E35",
    },
  },
};
</script>

<style scoped></style>
