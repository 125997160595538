<template>
  <div class="wrapper" @click="close">
    <span class="notice" v-html="data.text" />
    <IconCross class="cross" />
  </div>
</template>

<script>
import IconCross from "@/components/icons/IconCross.vue";

export default {
  name: "SingleNotice",
  components: { IconCross },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      timeout: null,
    };
  },
  methods: {
    close() {
      this.$emit("close", this.data.ID);
    },
  },

  mounted() {
    this.timeout = setTimeout(() => {
      this.$emit("hide", this.data.ID);
    }, 10000);
  },

  beforeMount() {
    clearInterval(this.timeout);
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 553px;
  padding: 14px 52px 14px 24px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 16px 0 #00000014;
  cursor: pointer;

  @include mobile {
    width: calc(100% - 32px);
  }
}

.notice {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #222222;

  & :deep(.bold) {
    font-weight: 600;
    color: #222222;
  }

  & :deep(.color) {
    font-weight: 600;
    color: #f18e35;
  }
}

.cross {
  position: absolute;
  top: 14px;
  right: 16px;
  transform: translateX(-50%);
}
</style>
