<template>
  <svg
    width="111"
    height="64"
    viewBox="0 0 111 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_14129_223966)">
      <path
        d="M63.0989 19.3031C63.0747 2.46643 46.6049 6.754 37.0525 7.77313C36.2408 5.16199 36.0228 4.02226 35.3262 1.48951C51.0449 -1.41108 69.6529 -1.45932 70.3979 14.3221C70.6281 19.1584 70.404 24.0008 70.404 29.1507C67.333 29.1507 65.643 29.1808 62.6022 29.1808C62.5356 25.9425 57.8412 23.6028 53.5344 26.0752C45.666 30.5919 36.7981 32.2503 33.612 24.4229C30.2078 16.0468 36.3256 7.62237 45.878 9.70887C51.7172 10.9873 58.5075 15.7694 63.0989 19.3031ZM53.062 20.1655C51.5658 19.3815 47.144 16.9272 45.5933 16.2638C38.2579 13.1281 38.0338 27.5526 46.7927 23.0842"
        fill="#E7730A"
      />
      <path
        d="M0 28.9034V0.0301514H8.35301V11.3009H20.9643V0.0301514H29.3718V28.9034H20.9643V18.1212H8.35301V28.9095H0V28.9034Z"
        fill="#E7730A"
      />
      <path
        d="M73.4205 28.9034V0.0301514H82.2097L93.1552 19.7795H89.6541L101.345 0.0301514H109.213L109.322 28.9034H101.72V8.61736L103.016 9.47366L93.0462 26.1114H89.4905L79.4657 8.88269L81.0285 8.50881V28.9034H73.4266H73.4205Z"
        fill="#E7730A"
      />
      <path
        d="M9.81282 63.2763V39.3419L11.7027 41.2716H0V34.4031H27.9787V41.2716H16.282L18.1719 39.3419V63.2763H9.81888H9.81282Z"
        fill="#E7730A"
      />
      <path
        d="M42.4556 63.7106C40.1902 63.7106 38.2518 63.3246 36.6345 62.5588C35.0172 61.7929 33.7755 60.7436 32.9154 59.4169C32.0552 58.0963 31.6191 56.6068 31.6191 54.9605C31.6191 53.3142 32.0431 51.7403 32.8851 50.4498C33.727 49.1593 35.0657 48.1523 36.9011 47.4166C38.7364 46.6809 41.123 46.319 44.0729 46.319H51.7838V51.2036H44.9936C43.0189 51.2036 41.6621 51.5232 40.9231 52.1685C40.1841 52.8137 39.8207 53.6158 39.8207 54.5806C39.8207 55.654 40.2447 56.5043 41.0867 57.1314C41.9286 57.7586 43.0916 58.0722 44.5635 58.0722C46.0355 58.0722 47.2227 57.7405 48.3372 57.0772C49.4518 56.4138 50.2574 55.443 50.7602 54.1525L52.0564 58.0179C51.4446 59.8813 50.3301 61.2924 48.7128 62.2572C47.0955 63.2221 45.0118 63.7045 42.4617 63.7045L42.4556 63.7106ZM51.2993 63.2824V57.6501L50.7602 56.4138V46.3251C50.7602 44.5341 50.215 43.1411 49.1186 42.14C48.0223 41.139 46.3444 40.6385 44.079 40.6385C42.5343 40.6385 41.014 40.8797 39.5239 41.3621C38.0338 41.8445 36.7678 42.4958 35.7259 43.322L32.7094 37.4725C34.2904 36.363 36.1924 35.5066 38.4214 34.8976C40.6505 34.2885 42.916 33.987 45.2117 33.987C49.6335 33.987 53.0619 35.0242 55.5091 37.0987C57.9502 39.1731 59.1737 42.4114 59.1737 46.8135V63.2884H51.3053L51.2993 63.2824Z"
        fill="#E7730A"
      />
      <path
        d="M106.608 37.3821C102.108 34.7951 97.1045 36.2062 92.295 38.8957C89.0907 28.614 74.0989 31.5025 70.4888 41.1872V33.981H62.693V63.9457H69.9194C70.725 36.3268 86.2983 34.9941 86.8192 42.3451C63.6137 57.5837 98.9036 71.6405 94.3 45.3542C112.205 34.3006 101.902 59.8934 99.267 64.006L106.602 63.9578C106.73 62.408 116.421 43.0325 106.602 37.3881L106.608 37.3821ZM85.8501 54.5444C82.9002 53.6037 84.6507 49.5393 88.8484 47.8387C89.3875 51.2639 89.2482 55.3947 85.8501 54.5444Z"
        fill="#E7730A"
      />
    </g>
    <defs>
      <clipPath id="clip0_14129_223966">
        <rect width="111" height="64" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "LogoIcon",
};
</script>

<style scoped></style>
