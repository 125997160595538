<template>
  <div class="inner">
    <div class="icon-wrapper">
      <IconSwooshModal />
    </div>
    <h2>Стол №{{ tableNumber }}</h2>
    <p v-if="duration">
      Гостей посадили за стол с {{ visitStart }} до {{ visitEnd }}
    </p>
    <p v-else>Ожидаем гостей</p>
  </div>
</template>

<!--<script setup>-->
<!--import { computed } from "vue";-->
<!--import { useStore } from "vuex";-->
<!--import IconSwooshModal from "@/components/icons/IconSwooshModal.vue";-->

<!--const store = useStore();-->

<!--const screenWidth = computed(() => {-->
<!--  return store.getters.screenType;-->
<!--});-->
<!--</script>-->

<script>
import IconSwooshModal from "@/components/icons/IconSwooshModal.vue";

export default {
  name: "BookingSeatsSuccessModal",

  components: {
    IconSwooshModal,
  },

  props: {
    duration: Number,
    tableNumber: String,
  },

  data() {
    return {
      visitStart: "",
      visitEnd: "",
      closeTimer: null,
    };
  },

  mounted() {
    const visitStart = new Date();

    const visitEnd = new Date();
    visitEnd.setMinutes(visitEnd.getMinutes() + this.duration);

    this.visitStart = visitStart.toLocaleString("ru", {
      timeZone: "Asia/Yekaterinburg",
      hour: "2-digit",
      minute: "2-digit",
    });

    this.visitEnd = visitEnd.toLocaleString("ru", {
      timeZone: "Asia/Yekaterinburg",
      hour: "2-digit",
      minute: "2-digit",
    });

    this.closeTimer = setTimeout(() => {
      this.$store.dispatch("closeModal");
    }, 3000);
  },

  unmounted() {
    if (this.closeTimer) {
      clearTimeout(this.closeTimer);
    }
  },
};
</script>

<style lang="scss" scoped>
.inner {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 448px;
  margin: auto;
  padding: 32px 56px 40px 56px;
  background-color: #f18e35;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;

  @media screen and (max-width: 841px) {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    box-shadow: 0 -2px 10px 0 #0000000d;
    border-radius: 20px 20px 0 0;
    padding: 32px 24px 24px 24px;
  }
}

h2 {
  margin-bottom: 32px;
  text-align: center;
  font-size: 48px;
  font-weight: 600;
  line-height: 56px;
  color: #ffffff;
}

p {
  font-size: 28px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 32px;
}

.button {
  width: 100%;
}

.modal-cross {
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
}

.bold {
  font-weight: 600;
}

.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  width: 120px;
  border-radius: 50%;
  background-color: #ffffff;
  margin: 0 auto 16px;
}
</style>
