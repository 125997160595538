<template>
  <div class="inner">
    <h2>Закончились попытки</h2>
    <p>
      {{ message }}
      <span class="bold">{{ contacts }}</span>
    </p>
    <div class="buttons">
      <VButton @click="onBackClickHandler" class="button"> Понятно </VButton>
    </div>
    <IconPopupCross
      v-if="screenWidth !== 'mobile' && !isEvisoBusinessAuth"
      class="modal-cross"
      @click="store.dispatch('closeModal')"
    />
  </div>
  <IconBack
    v-if="screenWidth !== 'mobile' && isEvisoBusinessAuth"
    class="arrow"
    @click="onBackClickHandler"
  />
</template>

<script setup>
import { computed, defineProps } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import IconBack from "@/components/icons/IconBack.vue";
import AuthLoginModal from "@/components/modals/auth/AuthLoginModal.vue";
import IconPopupCross from "@/components/icons/IconPopupCross.vue";
import VButton from "@/components/ui/VButton.vue";

const store = useStore();
const route = useRoute();

const props = defineProps({
  contacts: String,
  catId: String,
  message: String,
});

const screenWidth = computed(() => {
  return store.getters.screenType;
});

const isEvisoBusinessAuth = computed(() => {
  return route.name === "auth-biz" || route.name === "auth-eviso";
});

const onBackClickHandler = () => {
  if (props.catId) {
    store.dispatch("closeModal");
  } else {
    store.dispatch("openModal", {
      value: {
        component: AuthLoginModal,
        props: null,
      },
    });
  }
};
</script>

<style lang="scss" scoped>
.inner {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 448px;
  margin: auto;
  padding: 32px 56px 40px 56px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;

  @media screen and (max-width: 841px) {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    box-shadow: 0 -2px 10px 0 #0000000d;
    border-radius: 20px 20px 0 0;
    padding: 32px 24px 24px 24px;
  }
}

h2 {
  margin-bottom: 24px;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #f0811e;
}

p {
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #222222;
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 32px;
}

.button {
  width: 100%;
}

.modal-cross {
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
}

.bold {
  color: #f18e35;
}

.arrow {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 200003;
  cursor: pointer;
}
</style>
