<template>
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 1C9.17708 1 7.4974 1.36458 5.96094 2.09375C4.45052 2.82292 3.23958 3.8125 2.32812 5.0625C1.44271 6.28646 1 7.64062 1 9.125C1 10.0885 1.19531 11 1.58594 11.8594C1.97656 12.7188 2.52344 13.513 3.22656 14.2422C3.01823 15.0495 2.60156 15.9089 1.97656 16.8203C1.61198 17.3411 1.3125 17.7188 1.07812 17.9531C1 18.0573 0.986979 18.1745 1.03906 18.3047C1.09115 18.4349 1.18229 18.5 1.3125 18.5C2.45833 18.5 3.57812 18.2656 4.67188 17.7969C5.42708 17.4583 6.14323 17.0286 6.82031 16.5078C8.14844 17.0026 9.54167 17.25 11 17.25C12.8229 17.25 14.4896 16.8854 16 16.1562C17.5365 15.4271 18.7474 14.4505 19.6328 13.2266C20.5443 11.9766 21 10.6094 21 9.125C21 7.64062 20.5443 6.28646 19.6328 5.0625C18.7474 3.8125 17.5365 2.82292 16 2.09375C14.4896 1.36458 12.8229 1 11 1Z"
      :stroke="color"
      stroke-width="1.7"
    />
  </svg>
</template>

<script>
export default {
  name: "IconReview",

  props: {
    color: {
      type: String,
      default: "#F18E35",
    },
  },
};
</script>

<style scoped></style>
