<template>
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0C8.17708 0 6.4974 0.364583 4.96094 1.09375C3.45052 1.82292 2.23958 2.8125 1.32812 4.0625C0.442708 5.28646 0 6.64062 0 8.125C0 9.08854 0.195312 10 0.585938 10.8594C0.976562 11.7188 1.52344 12.513 2.22656 13.2422C2.01823 14.0495 1.60156 14.9089 0.976562 15.8203C0.611979 16.3411 0.3125 16.7188 0.078125 16.9531C0 17.0573 -0.0130208 17.1745 0.0390625 17.3047C0.0911458 17.4349 0.182292 17.5 0.3125 17.5C1.45833 17.5 2.57812 17.2656 3.67188 16.7969C4.42708 16.4583 5.14323 16.0286 5.82031 15.5078C7.14844 16.0026 8.54167 16.25 10 16.25C11.8229 16.25 13.4896 15.8854 15 15.1562C16.5365 14.4271 17.7474 13.4505 18.6328 12.2266C19.5443 10.9766 20 9.60938 20 8.125C20 6.64062 19.5443 5.28646 18.6328 4.0625C17.7474 2.8125 16.5365 1.82292 15 1.09375C13.4896 0.364583 11.8229 0 10 0Z"
      fill="#ADADAD"
    />
  </svg>
</template>

<script>
export default {
  name: "CommentIcon",
};
</script>

<style scoped></style>
