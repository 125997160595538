<template>
  <a class="support" :href="supportLink" target="_blank">
    <div class="button">
      <IconGreyPhone />
      <span>Позвонить</span>
    </div>
  </a>
</template>

<script>
import IconGreyPhone from "@/components/icons/IconGreyPhone.vue";

export default {
  components: { IconGreyPhone },
  props: ["roleGroup"],
  computed: {
    supportLink() {
      return this.roleGroup === "client"
        ? "https://jivo.chat/2EfX44qOaO"
        : "https://jivo.chat/EjNEFglFfX";
    },
  },
};
</script>

<style scoped>
.support {
  margin-top: auto;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 100px;
  background-color: #f6f6f6;

  span {
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
    color: #858585;
  }
}
</style>
