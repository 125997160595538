<template>
  <perfect-scrollbar class="wrapper" ref="notices">
    <div v-if="screenWidth === 'mobile'" class="title">
      <span>Уведомления</span>
      <IconCross @click="$store.commit('toggleNotices')" />
    </div>
    <IconNotificationArrow
      v-else
      class="arrow"
      @click="$store.commit('toggleNotices')"
    />
    <Notice v-for="item in currentItems" is-read :data="item" :key="item.id" />
  </perfect-scrollbar>
</template>

<script setup>
import { ref, watch } from "vue";
import { onClickOutside } from "@vueuse/core";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

const store = useStore();
const route = useRoute();
const notices = ref(null);

onClickOutside(notices, (event) => {
  event.stopPropagation();
  store.commit({
    type: "toggleNotices",
  });
});

watch(
  () => route.path,
  () => {
    if (store.state.notices.isNotices) {
      store.commit({
        type: "toggleNotices",
      });
    }
  }
);
</script>

<script>
import Notice from "@/components/notice/Notice.vue";
import { API } from "@/assets/js/api/api";
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import IconNotificationArrow from "@/components/icons/IconNotificationArrow.vue";
import IconCross from "@/components/icons/IconCross.vue";

export default {
  name: "NoticeList",
  components: { IconCross, IconNotificationArrow, Notice, PerfectScrollbar },
  computed: {
    items() {
      return this.$store.state.notices.items;
    },
    roleGroup() {
      return this.$store.getters.roleGroup;
    },
    screenWidth() {
      return this.$store.getters.screenType;
    },
  },

  data() {
    return {
      currentItems: [],
      isRequestSuccess: false,
    };
  },

  async mounted() {
    this.currentItems = this.$store.state.notices.items;
    try {
      await API.notices.markAsRead(
        {
          notifications: this.items
            .filter((el) => el.deliveryType === 1)
            .map((el) => el.id),
          userID: this.$store.state.general.userInfo.id,
        },
        this.roleGroup
      );
      this.isRequestSuccess = true;
    } catch (e) {
      console.log(e);
    }
  },

  beforeUnmount() {
    if (this.isRequestSuccess) {
      this.$store.commit({ type: "setUnreadItems" });
    }
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  width: 288px;
  height: 100%;
  padding: 72px 16px 56px 16px;
  background-color: #fff;
  z-index: 1000000;
  box-shadow: 0 4px 16px 0 #00000014;

  @include mobile {
    position: fixed;
    top: 0;
    left: 0;
    right: auto;
    max-height: none;
    width: 100%;
    padding: 24px 16px 22px 16px;
  }
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  span {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
  }

  svg {
    cursor: pointer;
  }
}

.arrow {
  position: absolute;
  top: 24px;
  left: 16px;
  cursor: pointer;
}
</style>
