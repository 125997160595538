<template>
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 6.9783C0 6.4909 0.404744 6.09578 0.904022 6.09578H15.0591C15.5584 6.09578 15.9631 6.4909 15.9631 6.9783C15.9631 7.46571 15.5584 7.86083 15.0591 7.86083H0.904022C0.404744 7.86083 0 7.46571 0 6.9783Z"
      :fill="color"
    />
    <path
      d="M8.17276 13.7415C7.81972 13.3969 7.81972 12.8381 8.17276 12.4934L14.4194 6.39536C14.7724 6.05071 15.3448 6.05071 15.6978 6.39536C16.0509 6.74001 16.0509 7.29879 15.6978 7.64344L9.45124 13.7415C9.0982 14.0862 8.5258 14.0862 8.17276 13.7415Z"
      :fill="color"
    />
    <path
      d="M8.21013 0.258486C8.56318 -0.0861618 9.13557 -0.0861621 9.48861 0.258486L15.7352 6.35656C16.0883 6.70121 16.0883 7.25999 15.7352 7.60464C15.3822 7.94929 14.8098 7.94929 14.4567 7.60464L8.21013 1.50657C7.85709 1.16192 7.85709 0.603134 8.21013 0.258486Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "IconArrow",
  props: {
    color: {
      default: "#ffffff",
    },
  },
};
</script>

<style scoped></style>
