import { v4 as uuidv4 } from "uuid";
import Konva from "konva";

export function drawLines(gridLayer, viewRect, gridFullRect, stepSize) {
  gridLayer.clip({
    x: viewRect.x1,
    y: viewRect.y1,
    width: viewRect.x2 - viewRect.x1,
    height: viewRect.y2 - viewRect.y1,
  });

  let fullRect = gridFullRect;

  const xSize = fullRect.x2 - fullRect.x1,
    ySize = fullRect.y2 - fullRect.y1,
    xSteps = Math.round(xSize / stepSize),
    ySteps = Math.round(ySize / stepSize);

  for (let i = 0; i <= xSteps; i++) {
    gridLayer.add(
      new Konva.Line({
        x: fullRect.x1 + i * stepSize,
        y: fullRect.y1,
        points: [0, 0, 0, ySize],
        stroke: "rgba(0, 0, 0, 0.2)",
        strokeWidth: 1,
      })
    );
  }

  for (let i = 0; i <= ySteps; i++) {
    gridLayer.add(
      new Konva.Line({
        x: fullRect.x1,
        y: fullRect.y1 + i * stepSize,
        points: [0, 0, xSize, 0],
        stroke: "rgba(0, 0, 0, 0.2)",
        strokeWidth: 1,
      })
    );
  }

  gridLayer.add(
    new Konva.Rect({
      x: viewRect.x1 + 2,
      y: viewRect.y1 + 2,
      width: viewRect.x2 - viewRect.x1 - 4,
      height: viewRect.y2 - viewRect.y1 - 4,
      // strokeWidth: 4,
      // stroke: "red",
    })
  );

  gridLayer.batchDraw();
}

export function getTableStatusColor(status) {
  switch (status) {
    case "free":
      return { fill: "#019600", stroke: "#019600" };
    case "reserved":
      return { fill: "#D20A11", stroke: "#D20A11" };
    case "waiting":
      return { fill: "#F18E35", stroke: "#F18E35" };
    case "selected":
      return { fill: "#665191", stroke: "#665191" };
    default:
      return { fill: "#858585", stroke: "#858585" };
  }
}

export function createTablesForBooking(floors) {
  const roundTables = [];
  const squareTables = [];

  for (let i = 0; i < floors.length; i++) {
    roundTables.push(
      ...floors[i]
        .filter((el) => el.type === "roundTable")
        .map((el) => ({
          id: el.config.id,
          number: el.config.number,
          capacity: +el.config.capacity,
        }))
    );
    squareTables.push(
      ...floors[i]
        .filter((el) => el.type === "squareTable")
        .map((el) => ({
          id: el.config.id,
          number: el.config.number,
          capacity: +el.config.capacity,
        }))
    );
  }

  return [...roundTables, ...squareTables];
}

export function getNewCoords(coords, isGrid, step) {
  if (isGrid) {
    return Math.round(coords / step) * step;
  }
  return coords;
}

export function getNewScale(scale, isGrid) {
  if (isGrid) {
    return Math.round(scale / 0.05) * 0.05;
  }
  return scale;
}

export function addWall(floor, x, y) {
  const wall = {
    type: "wall",
    config: {
      rotation: 0,
      x,
      y,
      width: 200,
      height: 20,
      scaleX: 1,
      scaleY: 1,
      fill: "#858585",
      id: uuidv4(),
      opacity: 1,
    },
  };

  floor.push(wall);
}

export function sofaIconView(spineColor, seatColor, hasTable) {
  return (ctx) => {
    ctx.save();
    ctx.strokeStyle = "rgba(0,0,0,0)";
    ctx.miterLimit = 4;
    ctx.fillStyle = "rgba(0,0,0,0)";
    ctx.scale(0.9756097560975611, 0.9756097560975611);
    ctx.save();
    if (hasTable) {
      ctx.fillStyle = seatColor;
      ctx.strokeStyle = seatColor;
    } else {
      ctx.fillStyle = "#858585";
      ctx.strokeStyle = "#858585";
    }
    ctx.beginPath();
    ctx.moveTo(1, 1);
    ctx.lineTo(81, 1);
    ctx.bezierCurveTo(81, 1, 81, 1, 81, 1);
    ctx.lineTo(81, 41);
    ctx.bezierCurveTo(81, 41, 81, 41, 81, 41);
    ctx.lineTo(1, 41);
    ctx.bezierCurveTo(1, 41, 1, 41, 1, 41);
    ctx.lineTo(1, 1);
    ctx.bezierCurveTo(1, 1, 1, 1, 1, 1);
    ctx.closePath();
    ctx.fill();
    if (!hasTable) {
      ctx.stroke();
    }
    ctx.restore();
    ctx.save();
    if (hasTable) {
      ctx.fillStyle = spineColor;
      ctx.strokeStyle = spineColor;
    } else {
      ctx.fillStyle = "#ADADAD";
      ctx.strokeStyle = "#ADADAD";
    }
    ctx.translate(13, 10);
    ctx.rotate(1.5707963267948966);
    ctx.translate(-13, -10);
    ctx.beginPath();
    ctx.moveTo(13, 10);
    ctx.lineTo(44, 10);
    ctx.bezierCurveTo(44, 10, 44, 10, 44, 10);
    ctx.lineTo(44, 22);
    ctx.bezierCurveTo(44, 22, 44, 22, 44, 22);
    ctx.lineTo(13, 22);
    ctx.bezierCurveTo(13, 22, 13, 22, 13, 22);
    ctx.lineTo(13, 10);
    ctx.bezierCurveTo(13, 10, 13, 10, 13, 10);
    ctx.closePath();
    ctx.fill();
    if (!hasTable) {
      ctx.stroke();
    }
    ctx.restore();
    ctx.save();
    if (hasTable) {
      ctx.fillStyle = spineColor;
      ctx.strokeStyle = spineColor;
    } else {
      ctx.fillStyle = "#ADADAD";
      ctx.strokeStyle = "#ADADAD";
    }
    ctx.translate(81, 10);
    ctx.rotate(1.5707963267948966);
    ctx.translate(-81, -10);
    ctx.beginPath();
    ctx.moveTo(81, 10);
    ctx.lineTo(112, 10);
    ctx.bezierCurveTo(112, 10, 112, 10, 112, 10);
    ctx.lineTo(112, 22);
    ctx.bezierCurveTo(112, 22, 112, 22, 112, 22);
    ctx.lineTo(81, 22);
    ctx.bezierCurveTo(81, 22, 81, 22, 81, 22);
    ctx.lineTo(81, 10);
    ctx.bezierCurveTo(81, 10, 81, 10, 81, 10);
    ctx.closePath();
    ctx.fill();
    if (!hasTable) {
      ctx.stroke();
    }
    ctx.restore();
    ctx.save();
    if (hasTable) {
      ctx.fillStyle = spineColor;
      ctx.strokeStyle = spineColor;
    } else {
      ctx.fillStyle = "#ADADAD";
      ctx.strokeStyle = "#ADADAD";
    }
    ctx.beginPath();
    ctx.moveTo(1, 1);
    ctx.lineTo(81, 1);
    ctx.bezierCurveTo(81, 1, 81, 1, 81, 1);
    ctx.lineTo(81, 13);
    ctx.bezierCurveTo(81, 13, 81, 13, 81, 13);
    ctx.lineTo(1, 13);
    ctx.bezierCurveTo(1, 13, 1, 13, 1, 13);
    ctx.lineTo(1, 1);
    ctx.bezierCurveTo(1, 1, 1, 1, 1, 1);
    ctx.closePath();
    ctx.fill();
    if (!hasTable) {
      ctx.stroke();
    }
    ctx.restore();
    ctx.restore();
  };
}
export function sofaIcon() {
  return (ctx, shape) => {
    ctx.save();
    ctx.strokeStyle = "rgba(0,0,0,0)";
    ctx.miterLimit = 4;
    ctx.fillStyle = "rgba(0,0,0,0)";
    ctx.scale(0.9756097560975611, 0.9756097560975611);
    ctx.save();
    ctx.fillStyle = "#e2e2e2";
    ctx.strokeStyle = "#a0a0a0";
    ctx.beginPath();
    ctx.moveTo(1, 1);
    ctx.lineTo(81, 1);
    ctx.bezierCurveTo(81, 1, 81, 1, 81, 1);
    ctx.lineTo(81, 41);
    ctx.bezierCurveTo(81, 41, 81, 41, 81, 41);
    ctx.lineTo(1, 41);
    ctx.bezierCurveTo(1, 41, 1, 41, 1, 41);
    ctx.lineTo(1, 1);
    ctx.bezierCurveTo(1, 1, 1, 1, 1, 1);
    ctx.closePath();
    ctx.fillStrokeShape(shape);
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#e2e2e2";
    ctx.strokeStyle = "#a0a0a0";
    ctx.translate(13, 10);
    ctx.rotate(1.5707963267948966);
    ctx.translate(-13, -10);
    ctx.beginPath();
    ctx.moveTo(13, 10);
    ctx.lineTo(44, 10);
    ctx.bezierCurveTo(44, 10, 44, 10, 44, 10);
    ctx.lineTo(44, 22);
    ctx.bezierCurveTo(44, 22, 44, 22, 44, 22);
    ctx.lineTo(13, 22);
    ctx.bezierCurveTo(13, 22, 13, 22, 13, 22);
    ctx.lineTo(13, 10);
    ctx.bezierCurveTo(13, 10, 13, 10, 13, 10);
    ctx.closePath();
    ctx.fillStrokeShape(shape);
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#e2e2e2";
    ctx.strokeStyle = "#a0a0a0";
    ctx.translate(81, 10);
    ctx.rotate(1.5707963267948966);
    ctx.translate(-81, -10);
    ctx.beginPath();
    ctx.moveTo(81, 10);
    ctx.lineTo(112, 10);
    ctx.bezierCurveTo(112, 10, 112, 10, 112, 10);
    ctx.lineTo(112, 22);
    ctx.bezierCurveTo(112, 22, 112, 22, 112, 22);
    ctx.lineTo(81, 22);
    ctx.bezierCurveTo(81, 22, 81, 22, 81, 22);
    ctx.lineTo(81, 10);
    ctx.bezierCurveTo(81, 10, 81, 10, 81, 10);
    ctx.closePath();
    ctx.fillStrokeShape(shape);
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#e2e2e2";
    ctx.strokeStyle = "#a0a0a0";
    ctx.beginPath();
    ctx.moveTo(1, 1);
    ctx.lineTo(81, 1);
    ctx.bezierCurveTo(81, 1, 81, 1, 81, 1);
    ctx.lineTo(81, 13);
    ctx.bezierCurveTo(81, 13, 81, 13, 81, 13);
    ctx.lineTo(1, 13);
    ctx.bezierCurveTo(1, 13, 1, 13, 1, 13);
    ctx.lineTo(1, 1);
    ctx.bezierCurveTo(1, 1, 1, 1, 1, 1);
    ctx.closePath();
    ctx.fillStrokeShape(shape);
    ctx.restore();
    ctx.restore();
  };
}
export function roundChairIcon() {
  return (ctx, shape) => {
    ctx.save();
    ctx.miterLimit = 4;
    ctx.fillStyle = "rgba(0,0,0,0)";
    ctx.save();
    ctx.fillStyle = "rgba(0,0,0,0)";
    ctx.save();
    ctx.fillStyle = "#e2e2e2";
    ctx.translate(32.3172, 30.3602);
    ctx.rotate(-3.141592653589793);
    ctx.translate(-32.3172, -30.3602);
    ctx.beginPath();
    ctx.moveTo(43.8172, 30.36022);
    ctx.lineTo(43.8172, 30.36022);
    ctx.bezierCurveTo(
      50.168474623054124,
      30.36022,
      55.3172,
      35.508945376945874,
      55.3172,
      41.86022
    );
    ctx.lineTo(55.3172, 41.86022);
    ctx.bezierCurveTo(
      55.3172,
      48.21149462305412,
      50.168474623054124,
      53.36022,
      43.8172,
      53.36022
    );
    ctx.lineTo(43.8172, 53.36022);
    ctx.bezierCurveTo(
      37.465925376945876,
      53.36022,
      32.3172,
      48.21149462305412,
      32.3172,
      41.86022
    );
    ctx.lineTo(32.3172, 41.86022);
    ctx.bezierCurveTo(
      32.3172,
      35.508945376945874,
      37.465925376945876,
      30.36022,
      43.8172,
      30.36022
    );
    ctx.closePath();
    ctx.fillStrokeShape(shape);
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#e2e2e2";
    ctx.translate(31.8871, 7.5);
    ctx.rotate(-3.141592653589793);
    ctx.translate(-31.8871, -7.5);
    ctx.beginPath();
    ctx.moveTo(35.387100000000004, 7.5);
    ctx.lineTo(51.387100000000004, 7.5);
    ctx.bezierCurveTo(
      53.32009662440778,
      7.5,
      54.887100000000004,
      9.067003375592222,
      54.887100000000004,
      11
    );
    ctx.lineTo(54.887100000000004, 11);
    ctx.bezierCurveTo(
      54.887100000000004,
      12.932996624407778,
      53.32009662440778,
      14.5,
      51.387100000000004,
      14.5
    );
    ctx.lineTo(35.387100000000004, 14.5);
    ctx.bezierCurveTo(
      33.454103375592226,
      14.5,
      31.8871,
      12.932996624407778,
      31.8871,
      11
    );
    ctx.lineTo(31.8871, 11);
    ctx.bezierCurveTo(
      31.8871,
      9.067003375592222,
      33.454103375592226,
      7.5,
      35.387100000000004,
      7.5
    );
    ctx.closePath();
    ctx.fillStrokeShape(shape);
    ctx.restore();
    ctx.restore();
    ctx.restore();
  };
}
export function roundChairIconView(spineColor, seatColor, hasTable) {
  return (ctx) => {
    ctx.save();
    ctx.miterLimit = 4;
    if (hasTable) {
      ctx.fillStyle = seatColor;
      ctx.strokeStyle = seatColor;
    } else {
      ctx.fillStyle = "#858585";
      ctx.strokeStyle = "#858585";
    }
    ctx.save();
    ctx.fillStyle = "rgba(0,0,0,0)";
    ctx.save();
    if (hasTable) {
      ctx.fillStyle = seatColor;
      ctx.strokeStyle = seatColor;
    } else {
      ctx.fillStyle = "#858585";
      ctx.strokeStyle = "#858585";
    }
    ctx.translate(32.3172, 30.3602);
    ctx.rotate(-3.141592653589793);
    ctx.translate(-32.3172, -30.3602);
    ctx.beginPath();
    ctx.moveTo(43.8172, 30.36022);
    ctx.lineTo(43.8172, 30.36022);
    ctx.bezierCurveTo(
      50.168474623054124,
      30.36022,
      55.3172,
      35.508945376945874,
      55.3172,
      41.86022
    );
    ctx.lineTo(55.3172, 41.86022);
    ctx.bezierCurveTo(
      55.3172,
      48.21149462305412,
      50.168474623054124,
      53.36022,
      43.8172,
      53.36022
    );
    ctx.lineTo(43.8172, 53.36022);
    ctx.bezierCurveTo(
      37.465925376945876,
      53.36022,
      32.3172,
      48.21149462305412,
      32.3172,
      41.86022
    );
    ctx.lineTo(32.3172, 41.86022);
    ctx.bezierCurveTo(
      32.3172,
      35.508945376945874,
      37.465925376945876,
      30.36022,
      43.8172,
      30.36022
    );
    ctx.closePath();
    ctx.fill();
    if (!hasTable) {
      ctx.stroke();
    }
    ctx.restore();
    ctx.save();
    if (hasTable) {
      ctx.fillStyle = spineColor;
      ctx.strokeStyle = spineColor;
    } else {
      ctx.fillStyle = "#ADADAD";
      ctx.strokeStyle = "#ADADAD";
    }
    ctx.translate(31.8871, 7.5);
    ctx.rotate(-3.141592653589793);
    ctx.translate(-31.8871, -7.5);
    ctx.beginPath();
    ctx.moveTo(35.387100000000004, 7.5);
    ctx.lineTo(51.387100000000004, 7.5);
    ctx.bezierCurveTo(
      53.32009662440778,
      7.5,
      54.887100000000004,
      9.067003375592222,
      54.887100000000004,
      11
    );
    ctx.lineTo(54.887100000000004, 11);
    ctx.bezierCurveTo(
      54.887100000000004,
      12.932996624407778,
      53.32009662440778,
      14.5,
      51.387100000000004,
      14.5
    );
    ctx.lineTo(35.387100000000004, 14.5);
    ctx.bezierCurveTo(
      33.454103375592226,
      14.5,
      31.8871,
      12.932996624407778,
      31.8871,
      11
    );
    ctx.lineTo(31.8871, 11);
    ctx.bezierCurveTo(
      31.8871,
      9.067003375592222,
      33.454103375592226,
      7.5,
      35.387100000000004,
      7.5
    );
    ctx.closePath();
    ctx.fill();
    if (!hasTable) {
      ctx.stroke();
    }
    ctx.restore();
    ctx.restore();
    ctx.restore();
  };
}
export function squareChairIconView(spineColor, seatColor, hasTable) {
  return (ctx) => {
    ctx.save();
    ctx.strokeStyle = "rgba(0,0,0,0)";
    ctx.miterLimit = 4;
    ctx.fillStyle = "rgba(0,0,0,0)";
    ctx.save();
    ctx.fillStyle = "rgba(0,0,0,0)";
    ctx.save();
    if (hasTable) {
      ctx.fillStyle = seatColor;
      ctx.strokeStyle = seatColor;
    } else {
      ctx.fillStyle = "#858585";
      ctx.strokeStyle = "#858585";
    }
    ctx.translate(9.5, 27.5);
    ctx.rotate(-1.5707963267948966);
    ctx.translate(-9.5, -27.5);
    ctx.beginPath();
    ctx.moveTo(9.5, 27.5);
    ctx.lineTo(36.5, 27.5);
    ctx.bezierCurveTo(36.5, 27.5, 36.5, 27.5, 36.5, 27.5);
    ctx.lineTo(36.5, 48.5);
    ctx.bezierCurveTo(36.5, 48.5, 36.5, 48.5, 36.5, 48.5);
    ctx.lineTo(9.5, 48.5);
    ctx.bezierCurveTo(9.5, 48.5, 9.5, 48.5, 9.5, 48.5);
    ctx.lineTo(9.5, 27.5);
    ctx.bezierCurveTo(9.5, 27.5, 9.5, 27.5, 9.5, 27.5);
    ctx.closePath();
    ctx.fill();
    if (!hasTable) {
      ctx.stroke();
    }
    ctx.restore();
    ctx.save();
    if (hasTable) {
      ctx.fillStyle = spineColor;
      ctx.strokeStyle = spineColor;
    } else {
      ctx.fillStyle = "#ADADAD";
      ctx.strokeStyle = "#ADADAD";
    }
    ctx.translate(9.5, 6.5);
    ctx.rotate(-1.5707963267948966);
    ctx.translate(-9.5, -6.5);
    ctx.beginPath();
    ctx.moveTo(9.5, 6.5);
    ctx.lineTo(15.5, 6.5);
    ctx.bezierCurveTo(15.5, 6.5, 15.5, 6.5, 15.5, 6.5);
    ctx.lineTo(15.5, 27.5);
    ctx.bezierCurveTo(15.5, 27.5, 15.5, 27.5, 15.5, 27.5);
    ctx.lineTo(9.5, 27.5);
    ctx.bezierCurveTo(9.5, 27.5, 9.5, 27.5, 9.5, 27.5);
    ctx.lineTo(9.5, 6.5);
    ctx.bezierCurveTo(9.5, 6.5, 9.5, 6.5, 9.5, 6.5);
    ctx.closePath();
    ctx.fill();
    if (!hasTable) {
      ctx.stroke();
    }
    ctx.restore();
    ctx.save();
    if (hasTable) {
      ctx.fillStyle = seatColor;
      ctx.strokeStyle = seatColor;
    } else {
      ctx.fillStyle = "#858585";
      ctx.strokeStyle = "#858585";
    }
    ctx.beginPath();
    ctx.moveTo(0.5, 6.5);
    ctx.lineTo(6.5, 6.5);
    ctx.bezierCurveTo(6.5, 6.5, 6.5, 6.5, 6.5, 6.5);
    ctx.lineTo(6.5, 27.5);
    ctx.bezierCurveTo(6.5, 27.5, 6.5, 27.5, 6.5, 27.5);
    ctx.lineTo(0.5, 27.5);
    ctx.bezierCurveTo(0.5, 27.5, 0.5, 27.5, 0.5, 27.5);
    ctx.lineTo(0.5, 6.5);
    ctx.bezierCurveTo(0.5, 6.5, 0.5, 6.5, 0.5, 6.5);
    ctx.closePath();
    ctx.fill();
    if (!hasTable) {
      ctx.stroke();
    }
    ctx.restore();
    ctx.save();
    if (hasTable) {
      ctx.fillStyle = seatColor;
      ctx.strokeStyle = seatColor;
    } else {
      ctx.fillStyle = "#858585";
      ctx.strokeStyle = "#858585";
    }
    ctx.beginPath();
    ctx.moveTo(33.5, 6.5);
    ctx.lineTo(39.5, 6.5);
    ctx.bezierCurveTo(39.5, 6.5, 39.5, 6.5, 39.5, 6.5);
    ctx.lineTo(39.5, 27.5);
    ctx.bezierCurveTo(39.5, 27.5, 39.5, 27.5, 39.5, 27.5);
    ctx.lineTo(33.5, 27.5);
    ctx.bezierCurveTo(33.5, 27.5, 33.5, 27.5, 33.5, 27.5);
    ctx.lineTo(33.5, 6.5);
    ctx.bezierCurveTo(33.5, 6.5, 33.5, 6.5, 33.5, 6.5);
    ctx.closePath();
    ctx.fill();
    if (!hasTable) {
      ctx.stroke();
    }
    ctx.restore();
    ctx.restore();
    ctx.restore();
  };
}
export function squareChairIcon() {
  return (ctx, shape) => {
    ctx.save();
    ctx.strokeStyle = "rgba(0,0,0,0)";
    ctx.miterLimit = 4;
    ctx.fillStyle = "rgba(0,0,0,0)";
    ctx.save();
    ctx.fillStyle = "rgba(0,0,0,0)";
    ctx.save();
    ctx.fillStyle = "#e2e2e2";
    ctx.strokeStyle = "#a0a0a0";
    ctx.translate(9.5, 27.5);
    ctx.rotate(-1.5707963267948966);
    ctx.translate(-9.5, -27.5);
    ctx.beginPath();
    ctx.moveTo(9.5, 27.5);
    ctx.lineTo(36.5, 27.5);
    ctx.bezierCurveTo(36.5, 27.5, 36.5, 27.5, 36.5, 27.5);
    ctx.lineTo(36.5, 48.5);
    ctx.bezierCurveTo(36.5, 48.5, 36.5, 48.5, 36.5, 48.5);
    ctx.lineTo(9.5, 48.5);
    ctx.bezierCurveTo(9.5, 48.5, 9.5, 48.5, 9.5, 48.5);
    ctx.lineTo(9.5, 27.5);
    ctx.bezierCurveTo(9.5, 27.5, 9.5, 27.5, 9.5, 27.5);
    ctx.closePath();
    ctx.fillStrokeShape(shape);
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#e2e2e2";
    ctx.strokeStyle = "#a0a0a0";
    ctx.translate(9.5, 6.5);
    ctx.rotate(-1.5707963267948966);
    ctx.translate(-9.5, -6.5);
    ctx.beginPath();
    ctx.moveTo(9.5, 6.5);
    ctx.lineTo(15.5, 6.5);
    ctx.bezierCurveTo(15.5, 6.5, 15.5, 6.5, 15.5, 6.5);
    ctx.lineTo(15.5, 27.5);
    ctx.bezierCurveTo(15.5, 27.5, 15.5, 27.5, 15.5, 27.5);
    ctx.lineTo(9.5, 27.5);
    ctx.bezierCurveTo(9.5, 27.5, 9.5, 27.5, 9.5, 27.5);
    ctx.lineTo(9.5, 6.5);
    ctx.bezierCurveTo(9.5, 6.5, 9.5, 6.5, 9.5, 6.5);
    ctx.closePath();
    ctx.fillStrokeShape(shape);
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#e2e2e2";
    ctx.strokeStyle = "#a0a0a0";
    ctx.beginPath();
    ctx.moveTo(0.5, 6.5);
    ctx.lineTo(6.5, 6.5);
    ctx.bezierCurveTo(6.5, 6.5, 6.5, 6.5, 6.5, 6.5);
    ctx.lineTo(6.5, 27.5);
    ctx.bezierCurveTo(6.5, 27.5, 6.5, 27.5, 6.5, 27.5);
    ctx.lineTo(0.5, 27.5);
    ctx.bezierCurveTo(0.5, 27.5, 0.5, 27.5, 0.5, 27.5);
    ctx.lineTo(0.5, 6.5);
    ctx.bezierCurveTo(0.5, 6.5, 0.5, 6.5, 0.5, 6.5);
    ctx.closePath();
    ctx.fillStrokeShape(shape);
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#e2e2e2";
    ctx.strokeStyle = "#a0a0a0";
    ctx.beginPath();
    ctx.moveTo(33.5, 6.5);
    ctx.lineTo(39.5, 6.5);
    ctx.bezierCurveTo(39.5, 6.5, 39.5, 6.5, 39.5, 6.5);
    ctx.lineTo(39.5, 27.5);
    ctx.bezierCurveTo(39.5, 27.5, 39.5, 27.5, 39.5, 27.5);
    ctx.lineTo(33.5, 27.5);
    ctx.bezierCurveTo(33.5, 27.5, 33.5, 27.5, 33.5, 27.5);
    ctx.lineTo(33.5, 6.5);
    ctx.bezierCurveTo(33.5, 6.5, 33.5, 6.5, 33.5, 6.5);
    ctx.closePath();
    ctx.fillStrokeShape(shape);
    ctx.restore();
    ctx.restore();
    ctx.restore();
  };
}
export function ladderIcon() {
  return (ctx, shape) => {
    ctx.save();
    ctx.strokeStyle = "rgba(0,0,0,0)";
    ctx.miterLimit = 4;
    ctx.fillStyle = "rgba(0,0,0,0)";
    ctx.scale(0.4444444444444444, 0.4444444444444444);
    ctx.save();
    ctx.fillStyle = "#e2e2e2";
    ctx.strokeStyle = "#a0a0a0";
    ctx.translate(160.5, 79.5);
    ctx.rotate(-1.5707963267948966);
    ctx.translate(-160.5, -79.5);
    ctx.beginPath();
    ctx.moveTo(160.5, 79.5);
    ctx.lineTo(239.5, 79.5);
    ctx.bezierCurveTo(239.5, 79.5, 239.5, 79.5, 239.5, 79.5);
    ctx.lineTo(239.5, 98.5);
    ctx.bezierCurveTo(239.5, 98.5, 239.5, 98.5, 239.5, 98.5);
    ctx.lineTo(160.5, 98.5);
    ctx.bezierCurveTo(160.5, 98.5, 160.5, 98.5, 160.5, 98.5);
    ctx.lineTo(160.5, 79.5);
    ctx.bezierCurveTo(160.5, 79.5, 160.5, 79.5, 160.5, 79.5);
    ctx.closePath();
    ctx.fillStrokeShape(shape);
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "rgba(0,0,0,0)";
    ctx.strokeStyle = "#a0a0a0";
    ctx.lineWidth = 1.5;
    ctx.beginPath();
    ctx.moveTo(161, 40);
    ctx.lineTo(179, 40);
    ctx.fillStrokeShape(shape);
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#e2e2e2";
    ctx.strokeStyle = "#a0a0a0";
    ctx.translate(140.5, 79.5);
    ctx.rotate(-1.5707963267948966);
    ctx.translate(-140.5, -79.5);
    ctx.beginPath();
    ctx.moveTo(140.5, 79.5);
    ctx.lineTo(219.5, 79.5);
    ctx.bezierCurveTo(219.5, 79.5, 219.5, 79.5, 219.5, 79.5);
    ctx.lineTo(219.5, 98.5);
    ctx.bezierCurveTo(219.5, 98.5, 219.5, 98.5, 219.5, 98.5);
    ctx.lineTo(140.5, 98.5);
    ctx.bezierCurveTo(140.5, 98.5, 140.5, 98.5, 140.5, 98.5);
    ctx.lineTo(140.5, 79.5);
    ctx.bezierCurveTo(140.5, 79.5, 140.5, 79.5, 140.5, 79.5);
    ctx.closePath();
    ctx.fillStrokeShape(shape);
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "rgba(0,0,0,0)";
    ctx.strokeStyle = "#a0a0a0";
    ctx.lineWidth = 1.5;
    ctx.beginPath();
    ctx.moveTo(141, 40);
    ctx.lineTo(159, 40);
    ctx.fillStrokeShape(shape);
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#e2e2e2";
    ctx.strokeStyle = "#a0a0a0";
    ctx.translate(120.5, 79.5005);
    ctx.rotate(-1.5707963267948966);
    ctx.translate(-120.5, -79.5005);
    ctx.beginPath();
    ctx.moveTo(120.5, 79.5005);
    ctx.lineTo(199.5, 79.5005);
    ctx.bezierCurveTo(199.5, 79.5005, 199.5, 79.5005, 199.5, 79.5005);
    ctx.lineTo(199.5, 98.5005);
    ctx.bezierCurveTo(199.5, 98.5005, 199.5, 98.5005, 199.5, 98.5005);
    ctx.lineTo(120.5, 98.5005);
    ctx.bezierCurveTo(120.5, 98.5005, 120.5, 98.5005, 120.5, 98.5005);
    ctx.lineTo(120.5, 79.5005);
    ctx.bezierCurveTo(120.5, 79.5005, 120.5, 79.5005, 120.5, 79.5005);
    ctx.closePath();
    ctx.fillStrokeShape(shape);
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "rgba(0,0,0,0)";
    ctx.strokeStyle = "#a0a0a0";
    ctx.lineWidth = 1.5;
    ctx.beginPath();
    ctx.moveTo(121, 40.0005);
    ctx.lineTo(139, 40.0005);
    ctx.fillStrokeShape(shape);
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#e2e2e2";
    ctx.strokeStyle = "#a0a0a0";
    ctx.translate(100.5, 79.5);
    ctx.rotate(-1.5707963267948966);
    ctx.translate(-100.5, -79.5);
    ctx.beginPath();
    ctx.moveTo(100.5, 79.5);
    ctx.lineTo(179.5, 79.5);
    ctx.bezierCurveTo(179.5, 79.5, 179.5, 79.5, 179.5, 79.5);
    ctx.lineTo(179.5, 98.5);
    ctx.bezierCurveTo(179.5, 98.5, 179.5, 98.5, 179.5, 98.5);
    ctx.lineTo(100.5, 98.5);
    ctx.bezierCurveTo(100.5, 98.5, 100.5, 98.5, 100.5, 98.5);
    ctx.lineTo(100.5, 79.5);
    ctx.bezierCurveTo(100.5, 79.5, 100.5, 79.5, 100.5, 79.5);
    ctx.closePath();
    ctx.fillStrokeShape(shape);
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "rgba(0,0,0,0)";
    ctx.strokeStyle = "#a0a0a0";
    ctx.lineWidth = 1.5;
    ctx.beginPath();
    ctx.moveTo(101, 40);
    ctx.lineTo(119, 40);
    ctx.fillStrokeShape(shape);
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#e2e2e2";
    ctx.strokeStyle = "#a0a0a0";
    ctx.translate(80.5, 79.5);
    ctx.rotate(-1.5707963267948966);
    ctx.translate(-80.5, -79.5);
    ctx.beginPath();
    ctx.moveTo(80.5, 79.5);
    ctx.lineTo(159.5, 79.5);
    ctx.bezierCurveTo(159.5, 79.5, 159.5, 79.5, 159.5, 79.5);
    ctx.lineTo(159.5, 98.5);
    ctx.bezierCurveTo(159.5, 98.5, 159.5, 98.5, 159.5, 98.5);
    ctx.lineTo(80.5, 98.5);
    ctx.bezierCurveTo(80.5, 98.5, 80.5, 98.5, 80.5, 98.5);
    ctx.lineTo(80.5, 79.5);
    ctx.bezierCurveTo(80.5, 79.5, 80.5, 79.5, 80.5, 79.5);
    ctx.closePath();
    ctx.fillStrokeShape(shape);
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "rgba(0,0,0,0)";
    ctx.strokeStyle = "#a0a0a0";
    ctx.lineWidth = 1.5;
    ctx.beginPath();
    ctx.moveTo(81, 40);
    ctx.lineTo(99, 40);
    ctx.fillStrokeShape(shape);
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#e2e2e2";
    ctx.strokeStyle = "#a0a0a0";
    ctx.translate(60.5, 79.5);
    ctx.rotate(-1.5707963267948966);
    ctx.translate(-60.5, -79.5);
    ctx.beginPath();
    ctx.moveTo(60.5, 79.5);
    ctx.lineTo(139.5, 79.5);
    ctx.bezierCurveTo(139.5, 79.5, 139.5, 79.5, 139.5, 79.5);
    ctx.lineTo(139.5, 98.5);
    ctx.bezierCurveTo(139.5, 98.5, 139.5, 98.5, 139.5, 98.5);
    ctx.lineTo(60.5, 98.5);
    ctx.bezierCurveTo(60.5, 98.5, 60.5, 98.5, 60.5, 98.5);
    ctx.lineTo(60.5, 79.5);
    ctx.bezierCurveTo(60.5, 79.5, 60.5, 79.5, 60.5, 79.5);
    ctx.closePath();
    ctx.fillStrokeShape(shape);
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "rgba(0,0,0,0)";
    ctx.strokeStyle = "#a0a0a0";
    ctx.lineWidth = 1.5;
    ctx.beginPath();
    ctx.moveTo(61, 40);
    ctx.lineTo(79, 40);
    ctx.fillStrokeShape(shape);
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#e2e2e2";
    ctx.strokeStyle = "#a0a0a0";
    ctx.translate(40.5, 79.5);
    ctx.rotate(-1.5707963267948966);
    ctx.translate(-40.5, -79.5);
    ctx.beginPath();
    ctx.moveTo(40.5, 79.5);
    ctx.lineTo(119.5, 79.5);
    ctx.bezierCurveTo(119.5, 79.5, 119.5, 79.5, 119.5, 79.5);
    ctx.lineTo(119.5, 98.5);
    ctx.bezierCurveTo(119.5, 98.5, 119.5, 98.5, 119.5, 98.5);
    ctx.lineTo(40.5, 98.5);
    ctx.bezierCurveTo(40.5, 98.5, 40.5, 98.5, 40.5, 98.5);
    ctx.lineTo(40.5, 79.5);
    ctx.bezierCurveTo(40.5, 79.5, 40.5, 79.5, 40.5, 79.5);
    ctx.closePath();
    ctx.fillStrokeShape(shape);
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "rgba(0,0,0,0)";
    ctx.strokeStyle = "#a0a0a0";
    ctx.lineWidth = 1.5;
    ctx.beginPath();
    ctx.moveTo(41, 40);
    ctx.lineTo(59, 40);
    ctx.fillStrokeShape(shape);
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#e2e2e2";
    ctx.strokeStyle = "#a0a0a0";
    ctx.translate(20.5, 79.5);
    ctx.rotate(-1.5707963267948966);
    ctx.translate(-20.5, -79.5);
    ctx.beginPath();
    ctx.moveTo(20.5, 79.5);
    ctx.lineTo(99.5, 79.5);
    ctx.bezierCurveTo(99.5, 79.5, 99.5, 79.5, 99.5, 79.5);
    ctx.lineTo(99.5, 98.5);
    ctx.bezierCurveTo(99.5, 98.5, 99.5, 98.5, 99.5, 98.5);
    ctx.lineTo(20.5, 98.5);
    ctx.bezierCurveTo(20.5, 98.5, 20.5, 98.5, 20.5, 98.5);
    ctx.lineTo(20.5, 79.5);
    ctx.bezierCurveTo(20.5, 79.5, 20.5, 79.5, 20.5, 79.5);
    ctx.closePath();
    ctx.fillStrokeShape(shape);
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "rgba(0,0,0,0)";
    ctx.strokeStyle = "#a0a0a0";
    ctx.lineWidth = 1.5;
    ctx.beginPath();
    ctx.moveTo(21, 40);
    ctx.lineTo(39, 40);
    ctx.fillStrokeShape(shape);
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#e2e2e2";
    ctx.strokeStyle = "#a0a0a0";
    ctx.translate(0.5, 79.5);
    ctx.rotate(-1.5707963267948966);
    ctx.translate(-0.5, -79.5);
    ctx.beginPath();
    ctx.moveTo(0.5, 79.5);
    ctx.lineTo(79.5, 79.5);
    ctx.bezierCurveTo(79.5, 79.5, 79.5, 79.5, 79.5, 79.5);
    ctx.lineTo(79.5, 98.5);
    ctx.bezierCurveTo(79.5, 98.5, 79.5, 98.5, 79.5, 98.5);
    ctx.lineTo(0.5, 98.5);
    ctx.bezierCurveTo(0.5, 98.5, 0.5, 98.5, 0.5, 98.5);
    ctx.lineTo(0.5, 79.5);
    ctx.bezierCurveTo(0.5, 79.5, 0.5, 79.5, 0.5, 79.5);
    ctx.closePath();
    ctx.fillStrokeShape(shape);
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "rgba(0,0,0,0)";
    ctx.strokeStyle = "#a0a0a0";
    ctx.lineWidth = 1.5;
    ctx.beginPath();
    ctx.moveTo(1, 40);
    ctx.lineTo(19, 40);
    ctx.fillStrokeShape(shape);
    ctx.restore();
    ctx.restore();
  };
}
export function doorIcon() {
  return (ctx, shape) => {
    ctx.save();
    ctx.translate(-1, -1);
    ctx.strokeStyle = "rgba(0,0,0,0)";
    ctx.miterLimit = 4;
    ctx.fillStyle = "rgba(0,0,0,0)";
    ctx.save();
    ctx.fillStyle = "#e2e2e2";
    ctx.strokeStyle = "#a0a0a0";
    ctx.lineWidth = 1.5;
    ctx.beginPath();
    ctx.moveTo(15, 22);
    ctx.lineTo(15, 15.0049);
    ctx.bezierCurveTo(15, 3.25303, 5.66667, 0.781416, 0.999999, 1.01459);
    ctx.lineTo(1, 22);
    ctx.lineTo(15, 22);
    ctx.closePath();
    ctx.fillStyle = "#e2e2e2";
    ctx.fillStrokeShape(shape);
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#e2e2e2";
    ctx.strokeStyle = "#a0a0a0";
    ctx.lineWidth = 1.5;
    ctx.translate(1, 22);
    ctx.rotate(-1.5707963267948966);
    ctx.translate(-1, -22);
    ctx.beginPath();
    ctx.moveTo(1, 22);
    ctx.lineTo(5, 22);
    ctx.bezierCurveTo(5, 22, 5, 22, 5, 22);
    ctx.lineTo(5, 36);
    ctx.bezierCurveTo(5, 36, 5, 36, 5, 36);
    ctx.lineTo(1, 36);
    ctx.bezierCurveTo(1, 36, 1, 36, 1, 36);
    ctx.lineTo(1, 22);
    ctx.bezierCurveTo(1, 22, 1, 22, 1, 22);
    ctx.closePath();
    ctx.fillStyle = "#e2e2e2";
    ctx.fillStrokeShape(shape);
    ctx.restore();
    ctx.restore();
  };
}
export function doubleDoorIcon() {
  return (ctx, shape) => {
    ctx.save();
    ctx.translate(-1, -0.6);
    ctx.strokeStyle = "rgba(0,0,0,0)";
    ctx.miterLimit = 4;
    ctx.fillStyle = "rgba(0,0,0,0)";
    ctx.save();
    ctx.fillStyle = "#e2e2e2";
    ctx.strokeStyle = "#a0a0a0";
    ctx.lineWidth = 1.5;
    ctx.beginPath();
    ctx.moveTo(0.999997, 20.75);
    ctx.lineTo(1, 0.75);
    ctx.bezierCurveTo(6.0625, 0.750001, 14.5, 7.63136, 14.5, 14.1398);
    ctx.bezierCurveTo(14.5, 6.13983, 23.4437, 0.750004, 28, 0.750005);
    ctx.lineTo(28, 20.75);
    ctx.lineTo(0.999997, 20.75);
    ctx.closePath();
    ctx.fillStyle = "#e2e2e2";
    ctx.fillStrokeShape(shape);
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#e2e2e2";
    ctx.strokeStyle = "#a0a0a0";
    ctx.lineWidth = 1.5;
    ctx.translate(1, 21);
    ctx.rotate(-1.5707963267948966);
    ctx.translate(-1, -21);
    ctx.beginPath();
    ctx.moveTo(1, 21);
    ctx.lineTo(5, 21);
    ctx.bezierCurveTo(5, 21, 5, 21, 5, 21);
    ctx.lineTo(5, 48);
    ctx.bezierCurveTo(5, 48, 5, 48, 5, 48);
    ctx.lineTo(1, 48);
    ctx.bezierCurveTo(1, 48, 1, 48, 1, 48);
    ctx.lineTo(1, 21);
    ctx.bezierCurveTo(1, 21, 1, 21, 1, 21);
    ctx.closePath();
    ctx.fillStyle = "#e2e2e2";
    ctx.fillStrokeShape(shape);
    ctx.restore();
    ctx.restore();
  };
}

export function wcIconView() {
  return (ctx) => {
    ctx.save();
    ctx.strokeStyle = "rgba(0,0,0,0)";
    ctx.miterLimit = 4;
    ctx.fillStyle = "rgba(0,0,0,0)";
    ctx.save();
    ctx.fillStyle = "#e2e2e2";
    ctx.beginPath();
    ctx.moveTo(28, 0.5);
    ctx.lineTo(28, 0.5);
    ctx.bezierCurveTo(
      43.18783062034682,
      0.5,
      55.5,
      12.812169379653177,
      55.5,
      28
    );
    ctx.lineTo(55.5, 28);
    ctx.bezierCurveTo(
      55.5,
      43.18783062034682,
      43.18783062034682,
      55.5,
      28,
      55.5
    );
    ctx.lineTo(28, 55.5);
    ctx.bezierCurveTo(
      12.812169379653177,
      55.5,
      0.5,
      43.18783062034682,
      0.5,
      28
    );
    ctx.lineTo(0.5, 28);
    ctx.bezierCurveTo(
      0.5,
      12.812169379653177,
      12.812169379653177,
      0.5,
      28,
      0.5
    );
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "rgba(0,0,0,0)";
    ctx.strokeStyle = "#a0a0a0";
    ctx.beginPath();
    ctx.moveTo(28, 0.5);
    ctx.lineTo(28, 0.5);
    ctx.bezierCurveTo(
      43.18783062034682,
      0.5,
      55.5,
      12.812169379653177,
      55.5,
      28
    );
    ctx.lineTo(55.5, 28);
    ctx.bezierCurveTo(
      55.5,
      43.18783062034682,
      43.18783062034682,
      55.5,
      28,
      55.5
    );
    ctx.lineTo(28, 55.5);
    ctx.bezierCurveTo(
      12.812169379653177,
      55.5,
      0.5,
      43.18783062034682,
      0.5,
      28
    );
    ctx.lineTo(0.5, 28);
    ctx.bezierCurveTo(
      0.5,
      12.812169379653177,
      12.812169379653177,
      0.5,
      28,
      0.5
    );
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#a0a0a0";
    ctx.beginPath();
    ctx.moveTo(37.839, 35.7543);
    ctx.bezierCurveTo(36.6728, 35.7543, 35.599, 35.4841, 34.6177, 34.9437);
    ctx.bezierCurveTo(33.6363, 34.4032, 32.847, 33.6068, 32.2497, 32.5543);
    ctx.bezierCurveTo(31.6666, 31.4877, 31.375, 30.1792, 31.375, 28.629);
    ctx.bezierCurveTo(31.375, 27.093, 31.6737, 25.7846, 32.271, 24.7037);
    ctx.bezierCurveTo(32.8826, 23.6086, 33.6861, 22.7766, 34.6817, 22.2077);
    ctx.bezierCurveTo(35.6914, 21.6246, 36.7794, 21.333, 37.9457, 21.333);
    ctx.bezierCurveTo(38.8417, 21.333, 39.6452, 21.5179, 40.3563, 21.8877);
    ctx.bezierCurveTo(41.0674, 22.2432, 41.6577, 22.6628, 42.127, 23.1463);
    ctx.lineTo(40.463, 25.1517);
    ctx.bezierCurveTo(40.1074, 24.8246, 39.7306, 24.5615, 39.3323, 24.3623);
    ctx.bezierCurveTo(38.9483, 24.149, 38.5074, 24.0423, 38.0097, 24.0423);
    ctx.bezierCurveTo(37.3839, 24.0423, 36.8079, 24.2201, 36.2817, 24.5757);
    ctx.bezierCurveTo(35.7697, 24.9312, 35.3572, 25.4432, 35.0443, 26.1117);
    ctx.bezierCurveTo(34.7457, 26.7801, 34.5963, 27.5837, 34.5963, 28.5223);
    ctx.bezierCurveTo(34.5963, 29.9446, 34.9021, 31.0539, 35.5137, 31.8503);
    ctx.bezierCurveTo(36.1252, 32.6468, 36.943, 33.045, 37.967, 33.045);
    ctx.bezierCurveTo(38.5359, 33.045, 39.0408, 32.917, 39.4817, 32.661);
    ctx.bezierCurveTo(39.9368, 32.405, 40.335, 32.0992, 40.6763, 31.7437);
    ctx.lineTo(42.3403, 33.7063);
    ctx.bezierCurveTo(41.1741, 35.0717, 39.6737, 35.7543, 37.839, 35.7543);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#a0a0a0";
    ctx.beginPath();
    ctx.moveTo(15.9173, 35.4982);
    ctx.lineTo(13.3359, 21.5889);
    ctx.lineTo(16.5573, 21.5889);
    ctx.lineTo(17.5173, 28.0955);
    ctx.bezierCurveTo(17.6026, 28.8209, 17.695, 29.5533, 17.7946, 30.2929);
    ctx.bezierCurveTo(17.8942, 31.0182, 17.9866, 31.7506, 18.0719, 32.4902);
    ctx.lineTo(18.1573, 32.4902);
    ctx.bezierCurveTo(18.2995, 31.7506, 18.4346, 31.0182, 18.5626, 30.2929);
    ctx.bezierCurveTo(18.7048, 29.5533, 18.847, 28.8209, 18.9893, 28.0955);
    ctx.lineTo(20.4826, 21.5889);
    ctx.lineTo(23.1493, 21.5889);
    ctx.lineTo(24.6426, 28.0955);
    ctx.bezierCurveTo(24.7848, 28.7924, 24.9199, 29.5106, 25.0479, 30.2502);
    ctx.bezierCurveTo(25.1902, 30.9898, 25.3324, 31.7364, 25.4746, 32.4902);
    ctx.lineTo(25.5599, 32.4902);
    ctx.bezierCurveTo(25.6595, 31.7364, 25.7519, 30.9969, 25.8373, 30.2715);
    ctx.bezierCurveTo(25.9368, 29.532, 26.0364, 28.8066, 26.1359, 28.0955);
    ctx.lineTo(27.0959, 21.5889);
    ctx.lineTo(30.1039, 21.5889);
    ctx.lineTo(27.6293, 35.4982);
    ctx.lineTo(23.6826, 35.4982);
    ctx.lineTo(22.3173, 29.1835);
    ctx.bezierCurveTo(22.2035, 28.6431, 22.0968, 28.1026, 21.9973, 27.5622);
    ctx.bezierCurveTo(21.9119, 27.0075, 21.8337, 26.4742, 21.7626, 25.9622);
    ctx.lineTo(21.6773, 25.9622);
    ctx.bezierCurveTo(21.5919, 26.4742, 21.4995, 27.0075, 21.3999, 27.5622);
    ctx.bezierCurveTo(21.3146, 28.1026, 21.215, 28.6431, 21.1013, 29.1835);
    ctx.lineTo(19.7999, 35.4982);
    ctx.lineTo(15.9173, 35.4982);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.restore();
  };
}
export function motherRoomIconView() {
  return (ctx) => {
    ctx.save();
    ctx.strokeStyle = "rgba(0,0,0,0)";
    ctx.miterLimit = 4;
    ctx.fillStyle = "rgba(0,0,0,0)";
    ctx.save();
    ctx.fillStyle = "#e2e2e2";
    ctx.beginPath();
    ctx.moveTo(28, 0.5);
    ctx.lineTo(28, 0.5);
    ctx.bezierCurveTo(
      43.18783062034682,
      0.5,
      55.5,
      12.812169379653177,
      55.5,
      28
    );
    ctx.lineTo(55.5, 28);
    ctx.bezierCurveTo(
      55.5,
      43.18783062034682,
      43.18783062034682,
      55.5,
      28,
      55.5
    );
    ctx.lineTo(28, 55.5);
    ctx.bezierCurveTo(
      12.812169379653177,
      55.5,
      0.5,
      43.18783062034682,
      0.5,
      28
    );
    ctx.lineTo(0.5, 28);
    ctx.bezierCurveTo(
      0.5,
      12.812169379653177,
      12.812169379653177,
      0.5,
      28,
      0.5
    );
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "rgba(0,0,0,0)";
    ctx.strokeStyle = "#a0a0a0";
    ctx.beginPath();
    ctx.moveTo(28, 0.5);
    ctx.lineTo(28, 0.5);
    ctx.bezierCurveTo(
      43.18783062034682,
      0.5,
      55.5,
      12.812169379653177,
      55.5,
      28
    );
    ctx.lineTo(55.5, 28);
    ctx.bezierCurveTo(
      55.5,
      43.18783062034682,
      43.18783062034682,
      55.5,
      28,
      55.5
    );
    ctx.lineTo(28, 55.5);
    ctx.bezierCurveTo(
      12.812169379653177,
      55.5,
      0.5,
      43.18783062034682,
      0.5,
      28
    );
    ctx.lineTo(0.5, 28);
    ctx.bezierCurveTo(
      0.5,
      12.812169379653177,
      12.812169379653177,
      0.5,
      28,
      0.5
    );
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#a0a0a0";
    ctx.beginPath();
    ctx.moveTo(17.541, 41);
    ctx.bezierCurveTo(17.1996, 41, 16.9134, 40.8845, 16.6824, 40.6535);
    ctx.bezierCurveTo(16.4514, 40.4225, 16.3359, 40.1363, 16.3359, 39.7948);
    ctx.lineTo(16.3359, 28.7795);
    ctx.bezierCurveTo(16.3359, 28.6419, 16.3449, 28.5092, 16.3628, 28.3814);
    ctx.bezierCurveTo(16.3808, 28.2536, 16.412, 28.1273, 16.4564, 28.0026);
    ctx.lineTo(18.4231, 22.2692);
    ctx.bezierCurveTo(18.5752, 21.7684, 18.864, 21.3761, 19.2894, 21.0923);
    ctx.bezierCurveTo(19.7148, 20.8086, 20.1692, 20.6667, 20.6526, 20.6667);
    ctx.bezierCurveTo(20.8073, 20.6667, 20.9649, 20.6833, 21.1256, 20.7167);
    ctx.bezierCurveTo(21.2863, 20.75, 21.4427, 20.8026, 21.5949, 20.8744);
    ctx.lineTo(27.2051, 23.3333);
    ctx.lineTo(29.3359, 23.3333);
    ctx.bezierCurveTo(29.6192, 23.3333, 29.8567, 23.4292, 30.0484, 23.621);
    ctx.bezierCurveTo(30.2401, 23.8127, 30.3359, 24.0503, 30.3359, 24.3338);
    ctx.bezierCurveTo(30.3359, 24.6172, 30.2401, 24.8547, 30.0484, 25.0461);
    ctx.bezierCurveTo(29.8567, 25.2376, 29.6192, 25.3333, 29.3359, 25.3333);
    ctx.lineTo(27.2359, 25.3333);
    ctx.bezierCurveTo(27.0773, 25.3333, 26.9188, 25.3135, 26.7603, 25.274);
    ctx.bezierCurveTo(26.6017, 25.2344, 26.4432, 25.185, 26.2846, 25.1256);
    ctx.lineTo(22.8641, 23.6512);
    ctx.lineTo(21.0025, 29.282);
    ctx.lineTo(21.0025, 39.7948);
    ctx.bezierCurveTo(21.0025, 40.1363, 20.887, 40.4225, 20.6561, 40.6535);
    ctx.bezierCurveTo(20.4251, 40.8844, 20.1389, 40.9999, 19.7974, 40.9999);
    ctx.lineTo(17.541, 41);
    ctx.closePath();
    ctx.moveTo(25.3359, 35.9998);
    ctx.bezierCurveTo(25.0526, 35.9998, 24.8151, 35.904, 24.6234, 35.7122);
    ctx.bezierCurveTo(24.4318, 35.5205, 24.3359, 35.2829, 24.3359, 34.9994);
    ctx.bezierCurveTo(24.3359, 34.716, 24.4318, 34.4785, 24.6234, 34.2871);
    ctx.bezierCurveTo(24.8151, 34.0956, 25.0526, 33.9999, 25.3359, 33.9999);
    ctx.lineTo(38.6692, 33.9999);
    ctx.bezierCurveTo(38.9526, 33.9999, 39.1901, 34.0958, 39.3817, 34.2875);
    ctx.bezierCurveTo(39.5734, 34.4793, 39.6692, 34.7169, 39.6692, 35.0003);
    ctx.bezierCurveTo(39.6692, 35.2838, 39.5734, 35.5212, 39.3817, 35.7127);
    ctx.bezierCurveTo(39.1901, 35.9041, 38.9526, 35.9998, 38.6692, 35.9998);
    ctx.lineTo(25.3359, 35.9998);
    ctx.closePath();
    ctx.moveTo(37.4671, 32.6666);
    ctx.bezierCurveTo(36.9693, 32.6666, 36.5453, 32.4924, 36.1948, 32.1439);
    ctx.bezierCurveTo(35.8444, 31.7955, 35.6692, 31.3724, 35.6692, 30.8747);
    ctx.bezierCurveTo(35.6692, 30.377, 35.8434, 29.9529, 36.1918, 29.6025);
    ctx.bezierCurveTo(36.5402, 29.252, 36.9633, 29.0768, 37.461, 29.0768);
    ctx.bezierCurveTo(37.9588, 29.0768, 38.3829, 29.251, 38.7333, 29.5995);
    ctx.bezierCurveTo(39.0837, 29.9479, 39.2589, 30.3709, 39.2589, 30.8687);
    ctx.bezierCurveTo(39.2589, 31.3664, 39.0847, 31.7905, 38.7363, 32.1409);
    ctx.bezierCurveTo(38.3879, 32.4913, 37.9648, 32.6666, 37.4671, 32.6666);
    ctx.closePath();
    ctx.moveTo(29.4128, 32.6666);
    ctx.bezierCurveTo(28.75, 32.6666, 28.1826, 32.4306, 27.7106, 31.9585);
    ctx.bezierCurveTo(27.2386, 31.4865, 27.0026, 30.9191, 27.0026, 30.2563);
    ctx.lineTo(27.0026, 28.6666);
    ctx.lineTo(25.3359, 28.6666);
    ctx.bezierCurveTo(25.0526, 28.6666, 24.8151, 28.5707, 24.6234, 28.3789);
    ctx.bezierCurveTo(24.4318, 28.1872, 24.3359, 27.9496, 24.3359, 27.6661);
    ctx.bezierCurveTo(24.3359, 27.3827, 24.4318, 27.1452, 24.6234, 26.9538);
    ctx.bezierCurveTo(24.8151, 26.7623, 25.0526, 26.6666, 25.3359, 26.6666);
    ctx.lineTo(27.7911, 26.6666);
    ctx.bezierCurveTo(28.1389, 26.6666, 28.4278, 26.7821, 28.6577, 27.0131);
    ctx.bezierCurveTo(28.8876, 27.2441, 29.0025, 27.5303, 29.0025, 27.8718);
    ctx.lineTo(29.0025, 29.3333);
    ctx.lineTo(32.3359, 29.3333);
    ctx.lineTo(32.3359, 27.6666);
    ctx.bezierCurveTo(32.3359, 27.3833, 32.4318, 27.1458, 32.6235, 26.9541);
    ctx.bezierCurveTo(32.8153, 26.7625, 33.0529, 26.6666, 33.3363, 26.6666);
    ctx.bezierCurveTo(33.6198, 26.6666, 33.8573, 26.7625, 34.0487, 26.9541);
    ctx.bezierCurveTo(34.2401, 27.1458, 34.3359, 27.3833, 34.3359, 27.6666);
    ctx.lineTo(34.3359, 30.2563);
    ctx.bezierCurveTo(34.3359, 30.9192, 34.0999, 31.4866, 33.6279, 31.9586);
    ctx.bezierCurveTo(33.1559, 32.4306, 32.5885, 32.6666, 31.9256, 32.6666);
    ctx.lineTo(29.4128, 32.6666);
    ctx.lineTo(29.4128, 32.6666);
    ctx.closePath();
    ctx.moveTo(22.541, 18.9231);
    ctx.bezierCurveTo(21.8641, 18.9231, 21.2846, 18.682, 20.8026, 18.2);
    ctx.bezierCurveTo(20.3205, 17.718, 20.0795, 17.1385, 20.0795, 16.4615);
    ctx.bezierCurveTo(20.0795, 15.7846, 20.3205, 15.2051, 20.8026, 14.7231);
    ctx.bezierCurveTo(21.2846, 14.241, 21.8641, 14, 22.541, 14);
    ctx.bezierCurveTo(23.2179, 14, 23.7974, 14.241, 24.2795, 14.7231);
    ctx.bezierCurveTo(24.7615, 15.2051, 25.0025, 15.7846, 25.0025, 16.4615);
    ctx.bezierCurveTo(25.0025, 17.1385, 24.7615, 17.718, 24.2795, 18.2);
    ctx.bezierCurveTo(23.7974, 18.682, 23.2179, 18.9231, 22.541, 18.9231);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.restore();
  };
}

export function wcIcon() {
  return (ctx, shape) => {
    ctx.save();
    ctx.strokeStyle = "rgba(0,0,0,0)";
    ctx.miterLimit = 4;
    ctx.fillStyle = "rgba(0,0,0,0)";
    ctx.save();
    ctx.fillStyle = "#FEF6E8";
    ctx.beginPath();
    ctx.moveTo(28, 0.5);
    ctx.lineTo(28, 0.5);
    ctx.bezierCurveTo(
      43.18783062034682,
      0.5,
      55.5,
      12.812169379653177,
      55.5,
      28
    );
    ctx.lineTo(55.5, 28);
    ctx.bezierCurveTo(
      55.5,
      43.18783062034682,
      43.18783062034682,
      55.5,
      28,
      55.5
    );
    ctx.lineTo(28, 55.5);
    ctx.bezierCurveTo(
      12.812169379653177,
      55.5,
      0.5,
      43.18783062034682,
      0.5,
      28
    );
    ctx.lineTo(0.5, 28);
    ctx.bezierCurveTo(
      0.5,
      12.812169379653177,
      12.812169379653177,
      0.5,
      28,
      0.5
    );
    ctx.closePath();
    ctx.fillStrokeShape(shape);
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "rgba(0,0,0,0)";
    ctx.strokeStyle = "#F18E35";
    ctx.beginPath();
    ctx.moveTo(28, 0.5);
    ctx.lineTo(28, 0.5);
    ctx.bezierCurveTo(
      43.18783062034682,
      0.5,
      55.5,
      12.812169379653177,
      55.5,
      28
    );
    ctx.lineTo(55.5, 28);
    ctx.bezierCurveTo(
      55.5,
      43.18783062034682,
      43.18783062034682,
      55.5,
      28,
      55.5
    );
    ctx.lineTo(28, 55.5);
    ctx.bezierCurveTo(
      12.812169379653177,
      55.5,
      0.5,
      43.18783062034682,
      0.5,
      28
    );
    ctx.lineTo(0.5, 28);
    ctx.bezierCurveTo(
      0.5,
      12.812169379653177,
      12.812169379653177,
      0.5,
      28,
      0.5
    );
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#F18E35";
    ctx.beginPath();
    ctx.moveTo(37.839, 35.7543);
    ctx.bezierCurveTo(36.6728, 35.7543, 35.599, 35.4841, 34.6177, 34.9437);
    ctx.bezierCurveTo(33.6363, 34.4032, 32.847, 33.6068, 32.2497, 32.5543);
    ctx.bezierCurveTo(31.6666, 31.4877, 31.375, 30.1792, 31.375, 28.629);
    ctx.bezierCurveTo(31.375, 27.093, 31.6737, 25.7846, 32.271, 24.7037);
    ctx.bezierCurveTo(32.8826, 23.6086, 33.6861, 22.7766, 34.6817, 22.2077);
    ctx.bezierCurveTo(35.6914, 21.6246, 36.7794, 21.333, 37.9457, 21.333);
    ctx.bezierCurveTo(38.8417, 21.333, 39.6452, 21.5179, 40.3563, 21.8877);
    ctx.bezierCurveTo(41.0674, 22.2432, 41.6577, 22.6628, 42.127, 23.1463);
    ctx.lineTo(40.463, 25.1517);
    ctx.bezierCurveTo(40.1074, 24.8246, 39.7306, 24.5615, 39.3323, 24.3623);
    ctx.bezierCurveTo(38.9483, 24.149, 38.5074, 24.0423, 38.0097, 24.0423);
    ctx.bezierCurveTo(37.3839, 24.0423, 36.8079, 24.2201, 36.2817, 24.5757);
    ctx.bezierCurveTo(35.7697, 24.9312, 35.3572, 25.4432, 35.0443, 26.1117);
    ctx.bezierCurveTo(34.7457, 26.7801, 34.5963, 27.5837, 34.5963, 28.5223);
    ctx.bezierCurveTo(34.5963, 29.9446, 34.9021, 31.0539, 35.5137, 31.8503);
    ctx.bezierCurveTo(36.1252, 32.6468, 36.943, 33.045, 37.967, 33.045);
    ctx.bezierCurveTo(38.5359, 33.045, 39.0408, 32.917, 39.4817, 32.661);
    ctx.bezierCurveTo(39.9368, 32.405, 40.335, 32.0992, 40.6763, 31.7437);
    ctx.lineTo(42.3403, 33.7063);
    ctx.bezierCurveTo(41.1741, 35.0717, 39.6737, 35.7543, 37.839, 35.7543);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#F18E35";
    ctx.beginPath();
    ctx.moveTo(15.9173, 35.4982);
    ctx.lineTo(13.3359, 21.5889);
    ctx.lineTo(16.5573, 21.5889);
    ctx.lineTo(17.5173, 28.0955);
    ctx.bezierCurveTo(17.6026, 28.8209, 17.695, 29.5533, 17.7946, 30.2929);
    ctx.bezierCurveTo(17.8942, 31.0182, 17.9866, 31.7506, 18.0719, 32.4902);
    ctx.lineTo(18.1573, 32.4902);
    ctx.bezierCurveTo(18.2995, 31.7506, 18.4346, 31.0182, 18.5626, 30.2929);
    ctx.bezierCurveTo(18.7048, 29.5533, 18.847, 28.8209, 18.9893, 28.0955);
    ctx.lineTo(20.4826, 21.5889);
    ctx.lineTo(23.1493, 21.5889);
    ctx.lineTo(24.6426, 28.0955);
    ctx.bezierCurveTo(24.7848, 28.7924, 24.9199, 29.5106, 25.0479, 30.2502);
    ctx.bezierCurveTo(25.1902, 30.9898, 25.3324, 31.7364, 25.4746, 32.4902);
    ctx.lineTo(25.5599, 32.4902);
    ctx.bezierCurveTo(25.6595, 31.7364, 25.7519, 30.9969, 25.8373, 30.2715);
    ctx.bezierCurveTo(25.9368, 29.532, 26.0364, 28.8066, 26.1359, 28.0955);
    ctx.lineTo(27.0959, 21.5889);
    ctx.lineTo(30.1039, 21.5889);
    ctx.lineTo(27.6293, 35.4982);
    ctx.lineTo(23.6826, 35.4982);
    ctx.lineTo(22.3173, 29.1835);
    ctx.bezierCurveTo(22.2035, 28.6431, 22.0968, 28.1026, 21.9973, 27.5622);
    ctx.bezierCurveTo(21.9119, 27.0075, 21.8337, 26.4742, 21.7626, 25.9622);
    ctx.lineTo(21.6773, 25.9622);
    ctx.bezierCurveTo(21.5919, 26.4742, 21.4995, 27.0075, 21.3999, 27.5622);
    ctx.bezierCurveTo(21.3146, 28.1026, 21.215, 28.6431, 21.1013, 29.1835);
    ctx.lineTo(19.7999, 35.4982);
    ctx.lineTo(15.9173, 35.4982);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.restore();
  };
}
export function motherRoomIcon() {
  return (ctx, shape) => {
    ctx.save();
    ctx.strokeStyle = "rgba(0,0,0,0)";
    ctx.miterLimit = 4;
    ctx.fillStyle = "rgba(0,0,0,0)";
    ctx.save();
    ctx.fillStyle = "#FEF6E8";
    ctx.beginPath();
    ctx.moveTo(28, 0.5);
    ctx.lineTo(28, 0.5);
    ctx.bezierCurveTo(
      43.18783062034682,
      0.5,
      55.5,
      12.812169379653177,
      55.5,
      28
    );
    ctx.lineTo(55.5, 28);
    ctx.bezierCurveTo(
      55.5,
      43.18783062034682,
      43.18783062034682,
      55.5,
      28,
      55.5
    );
    ctx.lineTo(28, 55.5);
    ctx.bezierCurveTo(
      12.812169379653177,
      55.5,
      0.5,
      43.18783062034682,
      0.5,
      28
    );
    ctx.lineTo(0.5, 28);
    ctx.bezierCurveTo(
      0.5,
      12.812169379653177,
      12.812169379653177,
      0.5,
      28,
      0.5
    );
    ctx.closePath();
    ctx.fillStrokeShape(shape);
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "rgba(0,0,0,0)";
    ctx.strokeStyle = "#F18E35";
    ctx.beginPath();
    ctx.moveTo(28, 0.5);
    ctx.lineTo(28, 0.5);
    ctx.bezierCurveTo(
      43.18783062034682,
      0.5,
      55.5,
      12.812169379653177,
      55.5,
      28
    );
    ctx.lineTo(55.5, 28);
    ctx.bezierCurveTo(
      55.5,
      43.18783062034682,
      43.18783062034682,
      55.5,
      28,
      55.5
    );
    ctx.lineTo(28, 55.5);
    ctx.bezierCurveTo(
      12.812169379653177,
      55.5,
      0.5,
      43.18783062034682,
      0.5,
      28
    );
    ctx.lineTo(0.5, 28);
    ctx.bezierCurveTo(
      0.5,
      12.812169379653177,
      12.812169379653177,
      0.5,
      28,
      0.5
    );
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.save();
    ctx.fillStyle = "#F18E35";
    ctx.beginPath();
    ctx.moveTo(17.541, 41);
    ctx.bezierCurveTo(17.1996, 41, 16.9134, 40.8845, 16.6824, 40.6535);
    ctx.bezierCurveTo(16.4514, 40.4225, 16.3359, 40.1363, 16.3359, 39.7948);
    ctx.lineTo(16.3359, 28.7795);
    ctx.bezierCurveTo(16.3359, 28.6419, 16.3449, 28.5092, 16.3628, 28.3814);
    ctx.bezierCurveTo(16.3808, 28.2536, 16.412, 28.1273, 16.4564, 28.0026);
    ctx.lineTo(18.4231, 22.2692);
    ctx.bezierCurveTo(18.5752, 21.7684, 18.864, 21.3761, 19.2894, 21.0923);
    ctx.bezierCurveTo(19.7148, 20.8086, 20.1692, 20.6667, 20.6526, 20.6667);
    ctx.bezierCurveTo(20.8073, 20.6667, 20.9649, 20.6833, 21.1256, 20.7167);
    ctx.bezierCurveTo(21.2863, 20.75, 21.4427, 20.8026, 21.5949, 20.8744);
    ctx.lineTo(27.2051, 23.3333);
    ctx.lineTo(29.3359, 23.3333);
    ctx.bezierCurveTo(29.6192, 23.3333, 29.8567, 23.4292, 30.0484, 23.621);
    ctx.bezierCurveTo(30.2401, 23.8127, 30.3359, 24.0503, 30.3359, 24.3338);
    ctx.bezierCurveTo(30.3359, 24.6172, 30.2401, 24.8547, 30.0484, 25.0461);
    ctx.bezierCurveTo(29.8567, 25.2376, 29.6192, 25.3333, 29.3359, 25.3333);
    ctx.lineTo(27.2359, 25.3333);
    ctx.bezierCurveTo(27.0773, 25.3333, 26.9188, 25.3135, 26.7603, 25.274);
    ctx.bezierCurveTo(26.6017, 25.2344, 26.4432, 25.185, 26.2846, 25.1256);
    ctx.lineTo(22.8641, 23.6512);
    ctx.lineTo(21.0025, 29.282);
    ctx.lineTo(21.0025, 39.7948);
    ctx.bezierCurveTo(21.0025, 40.1363, 20.887, 40.4225, 20.6561, 40.6535);
    ctx.bezierCurveTo(20.4251, 40.8844, 20.1389, 40.9999, 19.7974, 40.9999);
    ctx.lineTo(17.541, 41);
    ctx.closePath();
    ctx.moveTo(25.3359, 35.9998);
    ctx.bezierCurveTo(25.0526, 35.9998, 24.8151, 35.904, 24.6234, 35.7122);
    ctx.bezierCurveTo(24.4318, 35.5205, 24.3359, 35.2829, 24.3359, 34.9994);
    ctx.bezierCurveTo(24.3359, 34.716, 24.4318, 34.4785, 24.6234, 34.2871);
    ctx.bezierCurveTo(24.8151, 34.0956, 25.0526, 33.9999, 25.3359, 33.9999);
    ctx.lineTo(38.6692, 33.9999);
    ctx.bezierCurveTo(38.9526, 33.9999, 39.1901, 34.0958, 39.3817, 34.2875);
    ctx.bezierCurveTo(39.5734, 34.4793, 39.6692, 34.7169, 39.6692, 35.0003);
    ctx.bezierCurveTo(39.6692, 35.2838, 39.5734, 35.5212, 39.3817, 35.7127);
    ctx.bezierCurveTo(39.1901, 35.9041, 38.9526, 35.9998, 38.6692, 35.9998);
    ctx.lineTo(25.3359, 35.9998);
    ctx.closePath();
    ctx.moveTo(37.4671, 32.6666);
    ctx.bezierCurveTo(36.9693, 32.6666, 36.5453, 32.4924, 36.1948, 32.1439);
    ctx.bezierCurveTo(35.8444, 31.7955, 35.6692, 31.3724, 35.6692, 30.8747);
    ctx.bezierCurveTo(35.6692, 30.377, 35.8434, 29.9529, 36.1918, 29.6025);
    ctx.bezierCurveTo(36.5402, 29.252, 36.9633, 29.0768, 37.461, 29.0768);
    ctx.bezierCurveTo(37.9588, 29.0768, 38.3829, 29.251, 38.7333, 29.5995);
    ctx.bezierCurveTo(39.0837, 29.9479, 39.2589, 30.3709, 39.2589, 30.8687);
    ctx.bezierCurveTo(39.2589, 31.3664, 39.0847, 31.7905, 38.7363, 32.1409);
    ctx.bezierCurveTo(38.3879, 32.4913, 37.9648, 32.6666, 37.4671, 32.6666);
    ctx.closePath();
    ctx.moveTo(29.4128, 32.6666);
    ctx.bezierCurveTo(28.75, 32.6666, 28.1826, 32.4306, 27.7106, 31.9585);
    ctx.bezierCurveTo(27.2386, 31.4865, 27.0026, 30.9191, 27.0026, 30.2563);
    ctx.lineTo(27.0026, 28.6666);
    ctx.lineTo(25.3359, 28.6666);
    ctx.bezierCurveTo(25.0526, 28.6666, 24.8151, 28.5707, 24.6234, 28.3789);
    ctx.bezierCurveTo(24.4318, 28.1872, 24.3359, 27.9496, 24.3359, 27.6661);
    ctx.bezierCurveTo(24.3359, 27.3827, 24.4318, 27.1452, 24.6234, 26.9538);
    ctx.bezierCurveTo(24.8151, 26.7623, 25.0526, 26.6666, 25.3359, 26.6666);
    ctx.lineTo(27.7911, 26.6666);
    ctx.bezierCurveTo(28.1389, 26.6666, 28.4278, 26.7821, 28.6577, 27.0131);
    ctx.bezierCurveTo(28.8876, 27.2441, 29.0025, 27.5303, 29.0025, 27.8718);
    ctx.lineTo(29.0025, 29.3333);
    ctx.lineTo(32.3359, 29.3333);
    ctx.lineTo(32.3359, 27.6666);
    ctx.bezierCurveTo(32.3359, 27.3833, 32.4318, 27.1458, 32.6235, 26.9541);
    ctx.bezierCurveTo(32.8153, 26.7625, 33.0529, 26.6666, 33.3363, 26.6666);
    ctx.bezierCurveTo(33.6198, 26.6666, 33.8573, 26.7625, 34.0487, 26.9541);
    ctx.bezierCurveTo(34.2401, 27.1458, 34.3359, 27.3833, 34.3359, 27.6666);
    ctx.lineTo(34.3359, 30.2563);
    ctx.bezierCurveTo(34.3359, 30.9192, 34.0999, 31.4866, 33.6279, 31.9586);
    ctx.bezierCurveTo(33.1559, 32.4306, 32.5885, 32.6666, 31.9256, 32.6666);
    ctx.lineTo(29.4128, 32.6666);
    ctx.lineTo(29.4128, 32.6666);
    ctx.closePath();
    ctx.moveTo(22.541, 18.9231);
    ctx.bezierCurveTo(21.8641, 18.9231, 21.2846, 18.682, 20.8026, 18.2);
    ctx.bezierCurveTo(20.3205, 17.718, 20.0795, 17.1385, 20.0795, 16.4615);
    ctx.bezierCurveTo(20.0795, 15.7846, 20.3205, 15.2051, 20.8026, 14.7231);
    ctx.bezierCurveTo(21.2846, 14.241, 21.8641, 14, 22.541, 14);
    ctx.bezierCurveTo(23.2179, 14, 23.7974, 14.241, 24.2795, 14.7231);
    ctx.bezierCurveTo(24.7615, 15.2051, 25.0025, 15.7846, 25.0025, 16.4615);
    ctx.bezierCurveTo(25.0025, 17.1385, 24.7615, 17.718, 24.2795, 18.2);
    ctx.bezierCurveTo(23.7974, 18.682, 23.2179, 18.9231, 22.541, 18.9231);
    ctx.closePath();
    ctx.fill();
    ctx.stroke();
    ctx.restore();
    ctx.restore();
  };
}

export function getChairSeatColor(status) {
  return status === "free"
    ? "#019600"
    : status === "reserved"
    ? "#D20A11"
    : status === "waiting"
    ? "#F18E35"
    : status === "selected"
    ? "#665191"
    : "#858585";
}

export function getChairSpineColor(status) {
  return status === "free"
    ? "#3CC13B"
    : status === "reserved"
    ? "#F03738"
    : status === "waiting"
    ? "#F6B566"
    : status === "selected"
    ? "#8970BE"
    : "#ADADAD";
}

export function checkVisitTime(timeSlot, nearBooking, visitStart) {
  if (!timeSlot) {
    return {};
  }

  let startTime;
  let endTime;

  if (nearBooking) {
    startTime = new Date();
    endTime = new Date(nearBooking);
  } else {
    startTime = new Date(timeSlot.Start);
    endTime = new Date(timeSlot.End);
  }

  const minutesDifference = (endTime - startTime) / (1000 * 60);

  const hours = Math.floor(minutesDifference / 60);
  const minutes = Math.round(minutesDifference % 60);
  const formattedEndTime = endTime.toLocaleTimeString("ru-RU", {
    timeZone: "Asia/Yekaterinburg",
    hour: "2-digit",
    minute: "2-digit",
  });

  if (visitStart) {
    return {
      isAlert: true,
      isBanVisit: true,
      busyTimeStart: formattedEndTime,
      message: "Выбранный стол уже занят, измените выбор",
    };
  } else if (minutesDifference < 30) {
    return {
      isAlert: true,
      isBanVisit: true,
      busyTimeStart: formattedEndTime,
      message:
        "До визита следующих гостей меньше 30 минут, выберите другой стол",
    };
  } else if (minutesDifference < 360) {
    return {
      isAlert: true,
      isBanVisit: false,
      busyTimeStart: formattedEndTime,
      message: `Стол свободен до ${formattedEndTime} на ${hours} ч ${minutes} мин`,
    };
  } else {
    return {
      isAlert: false,
      isBanVisit: false,
      busyTimeStart: formattedEndTime,
      message: null,
    };
  }
}

export function getTime(value) {
  if (value) {
    const date = new Date(value);
    return date.toLocaleTimeString("ru-RU", {
      timeZone: "Asia/Yekaterinburg",
      hour: "2-digit",
      minute: "2-digit",
    });
  } else {
    return "";
  }
}

export function getIsWaitingItem(startTime) {
  return startTime !== "0001-01-01T05:00:00+05:00";
}

export function getIsLateItem(startTime) {
  return getIsWaitingItem(startTime) && getMinutesElapsed(startTime) > 15;
}

export function getMinutesElapsed(isoTime) {
  const eventTime = new Date(isoTime);
  const currentTime = new Date();
  const differenceInMs = currentTime - eventTime;
  return Math.floor(differenceInMs / (1000 * 60));
}

export const FLOOR_LABELS = {
  1: "1 этаж",
  2: "2 этаж",
  3: "3 этаж",
  4: "4 этаж",
  5: "5 этаж",
  6: "6 этаж",
  7: "7 этаж",
  8: "8 этаж",
  9: "9 этаж",
  10: "10 этаж",
};
