<template>
  <div class="checkbox">
    <label :for="'option-' + optionName">
      <div v-if="value" class="checkbox_label _active">
        <svg
          width="8"
          height="7"
          viewBox="0 0 8 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.175571 2.65349C0.409665 2.38507 0.789207 2.38507 1.0233 2.65349L3.35456 5.32663C3.58866 5.59506 3.58866 6.03026 3.35456 6.29868C3.12047 6.56711 2.74093 6.56711 2.50683 6.29868L0.175571 3.62554C-0.0585237 3.35712 -0.0585235 2.92192 0.175571 2.65349Z"
            fill="#F18E35"
          />
          <path
            d="M2.52611 6.27663C2.29202 6.00821 2.29202 5.57301 2.52611 5.30458L6.9767 0.201318C7.21079 -0.0671059 7.59033 -0.0671062 7.82443 0.201318C8.05852 0.469742 8.05852 0.904944 7.82443 1.17337L3.37384 6.27663C3.13975 6.54506 2.76021 6.54506 2.52611 6.27663Z"
            fill="#F18E35"
          />
        </svg>
      </div>
      <div v-else class="checkbox_label" />
      <span> {{ label }} </span>
    </label>
    <input
      type="checkbox"
      :id="'option-' + optionName"
      v-model="selectedOptions"
      :value="value"
      @change="changeOption($event)"
    />
  </div>
</template>

<script>
export default {
  name: "Checkbox",

  emits: ["update:value"],

  props: {
    value: {
      type: Boolean,
      required: true,
    },

    label: {
      type: String,
      default: "",
    },

    optionName: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      selectedOptions: false,
    };
  },

  mounted() {
    this.selectedOptions = this.value;
  },

  watch: {
    value(value) {
      this.selectedOptions = !!value;
    },
  },

  methods: {
    changeOption() {
      this.$emit("update:value", this.selectedOptions);
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox {
  display: inline-block;
  width: 100%;
  padding: 9px 7px 9px 12px;
  transition: color 0.3s ease;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #222222;

  label {
    display: flex;
    align-items: center;

    span {
      word-break: normal;
    }
  }

  input {
    display: none;
  }

  &_label {
    flex: 0 0 auto;
    width: 14px;
    height: 14px;
    margin-right: 8px;
    border: 1px solid #858585;
    border-radius: 4px;

    &._active {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #f18e35;
    }
  }
}
</style>
