<template>
  <div class="inner">
    <h2>Восстановление пароля</h2>
    <form
      ref="form"
      class="form"
      @submit.prevent="handleSubmit"
      @keydown.enter="handleSubmit"
    >
      <VInput
        v-model="contacts"
        class="input"
        :placeholder="inputPlaceholder"
        @enter="handleSubmit"
        @input="error = ''"
      />

      <span v-if="error" class="error"> {{ error }}</span>

      <div class="buttons">
        <AsyncButton
          :disabled="contacts.length < 11"
          class="button"
          type="submit"
          :is-pending="isPending"
        >
          {{ buttonText }}
        </AsyncButton>
      </div>
    </form>
    <IconPopupCross
      v-if="screenWidth !== 'mobile' && !isEvisoBusinessAuth"
      class="modal-cross"
      @click="$store.dispatch('closeModal')"
    />
  </div>
  <IconBack
    v-if="screenWidth !== 'mobile' && isEvisoBusinessAuth"
    class="arrow"
    @click="onArrowClickHandler"
  />
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import IconBack from "@/components/icons/IconBack.vue";

const store = useStore();
const route = useRoute();

const screenWidth = computed(() => {
  return store.getters.screenType;
});

const isEvisoBusinessAuth = computed(() => {
  return route.name === "auth-biz" || route.name === "auth-eviso";
});
</script>

<script>
import AsyncButton from "@/components/ui/AsyncButton.vue";
import VInput from "@/components/ui/VInput.vue";
import { API_WITHOUT_GUARDS } from "@/assets/js/api/apiWithoutGuards";
import IconPopupCross from "@/components/icons/IconPopupCross.vue";
import AuthPhoneCode from "@/components/modals/auth/AuthPhoneCodeModal.vue";
import AuthLoginModal from "@/components/modals/auth/AuthLoginModal.vue";
import AuthBannedPhoneModal from "@/components/modals/auth/AuthBannedPhoneModal.vue";

export default {
  name: "AuthRestorationModal",

  props: {
    roleType: String,
  },

  components: {
    IconPopupCross,
    AsyncButton,
    VInput,
  },

  data() {
    return {
      contacts: "",
      error: "",
      isPending: false,
    };
  },

  computed: {
    buttonText() {
      return this.roleType === "client" || this.roleType === "biz"
        ? "Отправить код"
        : "Отправить письмо с кодом";
    },
    buttonDisabled() {
      if (this.roleType === "client") {
        return this.contacts.length < 11;
      } else {
        return false;
      }
    },
    inputPlaceholder() {
      switch (this.roleType) {
        case "client":
          return "+7";
        case "biz":
          return "Телефон или почта";
        case "eviso":
          return "Почта";
        default:
          return "";
      }
    },
  },

  methods: {
    onArrowClickHandler() {
      this.$store.dispatch("openModal", {
        value: {
          component: AuthLoginModal,
          props: null,
        },
      });
    },
    async handleSubmit() {
      try {
        this.isPending = true;
        if (this.roleType === "client") {
          await API_WITHOUT_GUARDS.client.resetPassword({
            phone: this.contacts,
          });
        } else if (this.roleType === "eviso") {
          await API_WITHOUT_GUARDS.eviso.recoverPassword({
            phoneOrEmail: this.contacts,
          });
        } else {
          await API_WITHOUT_GUARDS.business.recoverPassword({
            phoneOrEmail: this.contacts,
          });
        }

        this.$store.dispatch("openModal", {
          value: {
            component: AuthPhoneCode,
            props: {
              contacts: this.contacts,
              phoneCodeType: "restoration",
              roleType: this.roleType,
            },
          },
        });
      } catch (e) {
        if (e.response.status === 429) {
          this.$store.dispatch("openModal", {
            value: {
              component: AuthBannedPhoneModal,
              props: {
                contacts: this.contacts,
                message: e.response.data.message,
              },
            },
          });
        } else {
          switch (this.roleType) {
            case "client":
              this.error = "Пользователь с данным номером не зарегистрирован";
              return;
            case "biz":
              return "Телефон или почта";
            case "eviso":
              this.error = "Пользователь с такими данными не зарегистрирован";
              return "Почта";
            default:
              this.error = "Пользователь с данной почтой не зарегистрирован";
              return "";
          }
        }
      } finally {
        this.isPending = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.inner {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 448px;
  margin: auto;
  padding: 32px 56px 40px 56px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;

  @media screen and (max-width: 841px) {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    box-shadow: 0 -2px 10px 0 #0000000d;
    border-radius: 20px 20px 0 0;
    padding: 32px 24px 24px 24px;
  }
}

h2 {
  margin-bottom: 24px;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #f0811e;
}

p {
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #222222;
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 32px;
}

.button {
  width: 100%;
}

.modal-cross {
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
}

.icon {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.error {
  position: relative;
  margin-top: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #f03738;
}

.form {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.input {
  height: 40px;
  margin-top: 16px;

  &:first-child {
    margin-top: 0;
  }
}

.arrow {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 200003;
  cursor: pointer;
}

.arrow {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 200003;
  cursor: pointer;
}
</style>
