import { createStore } from "vuex";
import router from "@/router";
import { API } from "@/assets/js/api/api";
import { BUSINESS_ROLES_ENG, EVISO_ROLES } from "@/assets/js/utils/consts";
import { API_WITHOUT_GUARDS } from "@/assets/js/api/apiWithoutGuards";
import jwt_decode from "jwt-decode";
import FailModal from "@/components/modals/FailModal.vue";
import AcceptDiadockModal from "@/components/modals/AcceptDiadockModal.vue";
import { notificationsLongPolling } from "@/assets/js/api/longPollings/notificationsLongPolling";
import { seatsInfoLongPolling } from "@/assets/js/api/longPollings/seatsInfoLongPolling";
import {
  checkVisitTime,
  getIsLateItem,
  getIsWaitingItem,
  getTime,
} from "@/assets/js/utils/bookingUtils";
import BookingSeatsDurationModal from "@/components/modals/BookingSeatsDurationModal.vue";

const general = {
  state() {
    return {
      auth: {
        isAuth: false,
        phone: "",
      },
      user: {},
      userInfo: {},
      image: "",
      jwtAT: {},
      width: "",
      height: "",
      isMenu: false,
      filterTime: "",
    };
  },
  getters: {
    role(state) {
      const roles = state.jwtAT.realm_access?.roles || [];

      if (roles.includes("ROOT")) {
        return "ROOT";
      } else if (roles.includes("ADMIN")) {
        return "ADMIN";
      } else if (roles.includes("HEAD")) {
        return "HEAD";
      } else if (roles.includes("MANAGER")) {
        return "MANAGER";
      } else if (roles.includes("MODERATOR")) {
        return "MODERATOR";
      } else if (roles.includes("SECRETARY")) {
        return "SECRETARY";
      } else if (roles.includes("MAJOR")) {
        return "MAJOR";
      } else if (roles.includes("HOSTESS")) {
        return "HOSTESS";
      } else if (roles.includes("MARKETER")) {
        return "MARKETER";
      } else if (state.auth.isAuth) {
        return "CLIENT";
      } else {
        return null;
      }
    },
    roleGroup(state, getters) {
      if (EVISO_ROLES.includes(getters.role)) {
        return "org";
      } else if (BUSINESS_ROLES_ENG.includes(getters.role)) {
        return "biz";
      } else {
        return "client";
      }
    },
    screenType(state) {
      if (state.width < 842) {
        return "mobile";
      } else if (state.width < 1024) {
        return "tablet";
      } else {
        return "desktop";
      }
    },
  },
  mutations: {
    setFilterTime(state, payload) {
      state.filterTime = payload.value;
    },
    toggleMenu(state) {
      state.isMenu = !state.isMenu;
    },
    setWidth(state, payload) {
      state.width = payload.value;
    },
    setHeight(state, payload) {
      state.height = payload.value;
    },
    setPhone(state, payload) {
      state.auth.phone = payload.value;
    },
    setUserInfo(state, payload) {
      state.userInfo = payload.value;
    },
    setjwtAT(state, payload) {
      state.jwtAT = payload.value;
    },
    setImage(state, payload) {
      state.image = payload.value;
    },
    setAvatar(state, payload) {
      state.userInfo.avatar = payload.value;
    },
    setUser(state, payload) {
      state.user = payload.value;
    },
  },
  actions: {
    async deleteAccountDetails({ dispatch, state }) {
      try {
        await API.client.deleteUser({
          userID: state.userInfo.id,
        });
        dispatch("closeModal");
        dispatch("setLogin", { value: false });
      } catch (e) {
        console.log(e);
      }
    },
    async deleteAccount({ dispatch }, payload) {
      try {
        await API.client.deleteUser(payload.value);
        dispatch("closeModal");
      } catch (e) {
        console.log(e);
      }
    },
    async setBalance({ state, commit, getters }) {
      if (getters.roleGroup !== "biz") {
        return;
      }
      let balance = null;
      let entityBalances = [];
      try {
        const { data } = await API.general.getBalance({
          ownerId:
            getters.role === "MAJOR"
              ? state.userInfo.id
              : state.userInfo.ownerId,
        });
        balance = data.sum;
        entityBalances = data.entityBalances;
      } catch (e) {
        balance = null;
        console.log(e);
      }
      commit({
        type: "setUserInfo",
        value: {
          ...state.userInfo,
          balance,
          entityBalances,
        },
      });
    },
  },
};

const auth = {
  actions: {
    async setLogin({ getters, rootState, commit }, payload) {
      rootState.general.auth.isAuth = payload.value;
      if (!payload.value) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        if (getters.roleGroup === "org") {
          router.push("/org");
        } else if (getters.roleGroup === "biz") {
          router.push("/biz");
        } else {
          router.push("/");
        }
      }
      if (!payload.value) {
        notificationsLongPolling.stop();
        commit({ type: "setUserInfo", value: {} });
      }
    },
    async logout({ dispatch, commit, getters, rootState }) {
      try {
        if (getters.roleGroup === "org") {
          await API.eviso.auth.logout({
            sessionID: rootState.general.jwtAT.sid,
          });
        } else if (getters.roleGroup === "biz") {
          await API.business.auth.logout({
            sessionID: rootState.general.jwtAT.sid,
          });
        } else {
          await API.client.logout({
            sessionID: rootState.general.jwtAT.sid,
          });
        }
        dispatch("setLogin", { value: false });
        commit("reset");
        commit("setUnreadItems");
      } catch (e) {
        console.log(e);
      }
    },
    async logoutWidget({ commit, rootState }) {
      try {
        await API.client.logout({
          sessionID: rootState.general.jwtAT.sid,
        });
        notificationsLongPolling.stop();
        commit({ type: "setUserInfo", value: {} });
        commit("reset");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
      } catch (e) {
        console.log(e);
      }
    },
    async logoutAll({ dispatch, getters, rootState, commit }) {
      try {
        if (getters.roleGroup === "org") {
          await API.eviso.auth.logoutAll({
            userID: rootState.general.userInfo.id,
          });
        } else if (getters.roleGroup === "biz") {
          await API.business.auth.logoutAll({
            userID: rootState.general.userInfo.id,
          });
        } else {
          await API.client.logoutAll({
            userID: rootState.general.userInfo.id,
          });
        }
        dispatch("setLogin", { value: false });
        commit("reset");
        commit("setUnreadItems");
      } catch (e) {
        console.log(e);
      }
    },
    async checkAuth({ dispatch, commit, getters }) {
      const token = localStorage.getItem("accessToken");
      if (token) {
        let res;
        dispatch("setLogin", { value: true });
        const decoded = jwt_decode(token);
        commit({ type: "setjwtAT", value: decoded });
        try {
          if (
            decoded.realm_access.roles.includes("ROOT") ||
            decoded.realm_access.roles.includes("ADMIN")
          ) {
            res = await API.eviso.admins.getAdmin(decoded.preferred_username);
          } else if (
            decoded.realm_access.roles.includes("HEAD") ||
            decoded.realm_access.roles.includes("MANAGER")
          ) {
            res = await API.eviso.employee.managers.getEmployee(
              decoded.preferred_username
            );
          } else if (decoded.realm_access.roles.includes("MODERATOR")) {
            res = await API.eviso.moderator.getModerator(
              decoded.preferred_username
            );
          } else if (decoded.realm_access.roles.includes("SECRETARY")) {
            res = await API.eviso.secretaries.getSecretary(
              decoded.preferred_username
            );
          } else if (decoded.realm_access.roles.includes("MAJOR")) {
            res = await API.business.auth.getMajor(decoded.preferred_username);
          } else if (decoded.realm_access.roles.includes("HOSTESS")) {
            res = await API.business.auth.getHostess(
              decoded.preferred_username
            );
          } else if (decoded.realm_access.roles.includes("MARKETER")) {
            res = await API.business.auth.getMarketer(
              decoded.preferred_username
            );
          } else {
            res = await API.client.getUser(decoded.preferred_username);
          }
          let avatar;
          try {
            avatar = await API_WITHOUT_GUARDS.user.getAvatar(
              res.data.id,
              getters.roleGroup
            );
          } catch (e) {
            avatar = null;
            console.log(e);
          }

          commit({
            type: "setUserInfo",
            value: {
              ...res.data,
              avatar: avatar?.data?.url,
            },
          });
          if (
            decoded.realm_access.roles.includes("MARKETER") ||
            decoded.realm_access.roles.includes("MAJOR")
          ) {
            dispatch("setBalance");
          }
          dispatch("initNotices");
        } catch (e) {
          dispatch("setLogin", { value: false });
          console.log(e);
        }
      }
    },
  },
};

const settingsManagers = {
  state: () => ({
    mode: null,
    manager: null,
    team: null,
    managers: [],
    teams: [],
    deletedRequests: [],
    deletedManager: {},
    selectedManager: "",
    selectedB2BAccs: [],
  }),
  mutations: {
    updateMode(state, payload) {
      state.mode = payload.value;
    },
    addManager(state, payload) {
      state.managers.push(payload.value);
    },
    setManager(state, payload) {
      state.manager = payload.value;
    },
    setTeam(state, payload) {
      state.team = payload.value;
    },
    setManagers(state, payload) {
      state.managers = payload.value;
    },
    setDeleteRequests(state, payload) {
      state.deletedRequests = payload.value;
    },
    setTeams(state, payload) {
      state.teams = payload.value;
    },
    setSelectedManager(state, payload) {
      state.selectedManager = payload.value;
    },
    setSelectedB2BAccs(state, payload) {
      state.selectedB2BAccs = payload.value;
    },
  },
  actions: {
    async createManagerTeam({ commit, dispatch }, payload) {
      try {
        const res = await API.eviso.employee.teams.createTeam(payload.value);
        commit({ type: "addTeam", value: res.data.obj.updated });
        commit({ type: "updateMode", value: null });
        commit({ type: "setTeam", value: null });
      } catch (e) {
        dispatch("openModal", {
          value: {
            component: FailModal,
            props: {
              error: e.response.data.message,
            },
          },
        });
      }
    },
    async updateManagerTeam({ commit, dispatch }, payload) {
      try {
        await API.eviso.employee.teams.updateTeam(payload.value);
        commit({ type: "updateMode", value: null });
        commit({ type: "setTeam", value: null });
      } catch (e) {
        dispatch("openModal", {
          value: {
            component: FailModal,
            props: {
              error: e.response.data.message,
            },
          },
        });
      }
    },
    async createManager({ commit, dispatch }, payload) {
      try {
        const res = await API.eviso.employee.managers.createEmployee(
          payload.value
        );
        commit({ type: "addManager", value: res.data.obj.updated });
        commit({ type: "updateMode", value: null });
        commit({ type: "setManager", value: null });
      } catch (e) {
        dispatch("openModal", {
          value: {
            component: FailModal,
            props: {
              error: e.response.data.message,
            },
          },
        });
      }
    },
    async updateManager({ commit, dispatch }, payload) {
      try {
        await API.eviso.employee.managers.updateEmployee(payload.value);
        commit({ type: "updateMode", value: null });
        commit({ type: "setManager", value: null });
      } catch (e) {
        dispatch("openModal", {
          value: {
            component: FailModal,
            props: {
              error: e.response.data.message,
            },
          },
        });
      }
    },
    async loadManagerTeams({ commit }) {
      try {
        let items = await API.eviso.employee.teams.getTeams();
        commit({
          type: "setTeams",
          value: items.data,
        });
      } catch (e) {
        console.log(e);
      }
    },
    async loadManagers({ commit }) {
      try {
        let items = await API.eviso.employee.managers.getEmployees();
        commit({
          type: "setManagers",
          value: items.data || [],
        });
      } catch (e) {
        console.log(e);
      }
    },
    async loadManagersByHead({ commit }) {
      try {
        let items = await API.eviso.employee.managers.getEmployees();
        let filteredIds = await API.eviso.employee.teams.getTeams();

        filteredIds = filteredIds.data[0].members
          .map((el) => el)
          .filter((el) => el.roleId === 4)
          .map((el) => el.userId);

        commit({
          type: "setManagers",
          value: items.data.filter((el) => filteredIds.includes(el.id)),
        });
      } catch (e) {
        console.log(e);
      }
    },
    async loadDeleteRequests({ commit }) {
      try {
        let items = await API.eviso.employee.exclude.getDeleteRequests();
        commit({
          type: "setDeleteRequests",
          value: items.data,
        });
      } catch (e) {
        console.log(e);
      }
    },
    async deleteManager({ state, dispatch }, payload) {
      state.managers = state.managers.filter(
        (el) => el.id !== payload.value.userID
      );
      try {
        await API.eviso.employee.managers.deleteEmployee(payload.value);
      } catch (e) {
        dispatch("openModal", {
          value: {
            component: FailModal,
            props: {
              error: e.response.data.message,
            },
          },
        });
      }
    },
    async deleteManagerTeam({ state, dispatch }, payload) {
      try {
        await API.eviso.employee.teams.deleteManagerTeam(payload.value);
        state.teams = state.teams.filter(
          (el) => el.id !== payload.value.teamId
        );
      } catch (e) {
        dispatch("openModal", {
          value: {
            component: FailModal,
            props: {
              error: e.response.data.message,
            },
          },
        });
      }
    },
  },
};

const settingsModeration = {
  state: () => ({
    mode: null,
    moderator: null,
    moderators: [],
  }),
  mutations: {
    updateModerationMode(state, payload) {
      state.mode = payload.value;
    },
    addModerator(state, payload) {
      state.moderators.push(payload.value);
    },
    setModerator(state, payload) {
      state.moderator = payload.value;
    },
    setModerators(state, payload) {
      state.moderators = payload.value;
    },
  },
  actions: {
    async createModerator({ commit, dispatch }, payload) {
      try {
        const res = await API.eviso.moderator.createModerator(payload.value);
        commit({ type: "addModerator", value: res.data.obj.updated });
        commit({ type: "updateModerationMode", value: null });
        commit({ type: "setModerator", value: null });
      } catch (e) {
        dispatch("openModal", {
          value: {
            component: FailModal,
            props: {
              error: e.response.data.message,
            },
          },
        });
      }
    },
    async updateModerator({ commit, dispatch }, payload) {
      try {
        await API.eviso.moderator.updateModerator(payload.value);
        commit({ type: "updateModerationMode", value: null });
        commit({ type: "setModerator", value: null });
      } catch (e) {
        dispatch("openModal", {
          value: {
            component: FailModal,
            props: {
              error: e.response.data.message,
            },
          },
        });
      }
    },
    async loadModerator({ commit }, payload) {
      try {
        let items = await API.eviso.moderator.getModerator(payload.value);
        commit({
          type: "setModerator",
          value: items.data,
        });
      } catch (e) {
        console.log(e);
      }
    },
    async loadModerators({ commit }) {
      try {
        let items = await API.eviso.moderator.getModerators();
        commit({
          type: "setModerators",
          value: items.data,
        });
      } catch (e) {
        console.log(e);
      }
    },
    async deleteModerator({ state, dispatch }, payload) {
      state.moderators = state.moderators.filter(
        (el) => el.id !== payload.value.userID
      );
      try {
        await API.eviso.moderator.deleteModerator(payload.value);
      } catch (e) {
        dispatch("openModal", {
          value: {
            component: FailModal,
            props: {
              error: e.response.data.message,
            },
          },
        });
      }
    },
  },
};

const settingsSecretary = {
  state: () => ({
    mode: null,
    secretary: null,
    secretaries: [],
  }),
  mutations: {
    updateSecretaryMode(state, payload) {
      state.mode = payload.value;
    },
    addSecretary(state, payload) {
      state.secretaries.push(payload.value);
    },
    setSecretary(state, payload) {
      state.secretary = payload.value;
    },
    setSecretaries(state, payload) {
      state.secretaries = payload.value;
    },
  },
  actions: {
    async createSecretary({ commit, dispatch }, payload) {
      try {
        const res = await API.eviso.secretaries.createSecretary(payload.value);
        commit({ type: "addSecretary", value: res.data.obj.updated });
        commit({ type: "updateSecretaryMode", value: null });
        commit({ type: "setSecretary", value: null });
      } catch (e) {
        dispatch("openModal", {
          value: {
            component: FailModal,
            props: {
              error: e.response.data.message,
            },
          },
        });
      }
    },
    async updateSecretary({ commit, dispatch }, payload) {
      try {
        await API.eviso.secretaries.updateSecretary(payload.value);
        commit({ type: "updateSecretaryMode", value: null });
        commit({ type: "setSecretary", value: null });
      } catch (e) {
        dispatch("openModal", {
          value: {
            component: FailModal,
            props: {
              error: e.response.data.message,
            },
          },
        });
      }
    },
    async loadSecretary({ commit }, payload) {
      try {
        let items = await API.eviso.secretaries.getSecretary(payload.value);
        commit({
          type: "setSecretary",
          value: items.data,
        });
      } catch (e) {
        console.log(e);
      }
    },
    async loadSecretaries({ commit }) {
      try {
        let items = await API.eviso.secretaries.getSecretaries();
        commit({
          type: "setSecretaries",
          value: items.data,
        });
      } catch (e) {
        console.log(e);
      }
    },
    async deleteSecretary({ state, dispatch }, payload) {
      state.secretaries = state.secretaries.filter(
        (el) => el.id !== payload.value.userID
      );
      try {
        await API.eviso.secretaries.deleteSecretary(payload.value);
      } catch (e) {
        dispatch("openModal", {
          value: {
            component: FailModal,
            props: {
              error: e.response.data.message,
            },
          },
        });
      }
    },
  },
};

const journal = {
  state: () => ({ items: [] }),
  mutations: {
    setJournalItems(state, payload) {
      state.items = payload.value;
    },
  },
  actions: {
    async getJournalItems({ getters, commit, state }, payload) {
      try {
        let items;
        if (getters.role === "ROOT") {
          items = await API.eviso.journal.getRootJournal(payload.value);
        } else {
          items = await API.eviso.journal.getAdminJournal(payload.value);
        }
        if (state.items.length) {
          commit({
            type: "setJournalItems",
            value: [...state.items, ...items.data],
          });
        } else {
          commit({ type: "setJournalItems", value: items.data });
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
};

const clients = {
  state: () => ({
    clientItems: [],
    businessItems: [],
    client: {},
    cateringClient: {},
    error: "",
    clientUsername: "",
  }),
  mutations: {
    updateClientStatus(state, payload) {
      const itemsType =
        payload.value.type === "b2b" ? "businessItems" : "clientItems";
      let client = state[itemsType].find((el) => el.id === payload.value.data);
      client.isActive = !client.isActive;
    },
    setClients(state, payload) {
      const itemsType = "businessItems";
      state[itemsType] = payload.value;
    },
    setB2CClients(state, payload) {
      const itemsType = "clientItems";
      state[itemsType] = payload.value;
    },
    setClient(state, payload) {
      state.client = payload.value;
    },
    setCateringClient(state, payload) {
      state.cateringClient = payload.value;
    },
    setClientUsername(state, payload) {
      state.clientUsername = payload.value;
    },
    setClientErrorModal(state, payload) {
      state.error = payload.value;
    },
  },
  actions: {
    async blockClient({ commit, dispatch }, payload) {
      try {
        await API.eviso.clients.blockClient(payload.value);
        dispatch("closeModal");
        commit({
          type: "setClient",
          value: {},
        });
        commit({
          type: "updateClientStatus",
          value: { data: payload.value.username, type: "" },
        });
      } catch (e) {
        dispatch("openModal", {
          value: {
            component: FailModal,
            props: {
              error: e.response.data.message,
            },
          },
        });
      }
    },
    async createBusinessClient({ commit, dispatch }, payload) {
      try {
        commit({
          type: "setClientErrorModal",
          value: "",
        });
        const res = await API.eviso.businessClients.createClient(payload.value);
        commit({
          type: "setCateringClient",
          value: res.data.obj.updated.orgAcc,
        });
        dispatch("closeModal");
      } catch (e) {
        if (e.response.status === 400) {
          commit({
            type: "setClientErrorModal",
            value: "Такой пользователь уже существует.",
          });
        }
      }
    },
    async deleteClient({ commit, state, dispatch }, payload) {
      try {
        if (payload.value.roleId === 1) {
          await API.eviso.businessClients.deleteClient({
            userID: payload.value.userId,
            username: payload.value.username,
          });
        } else if (payload.value.roleId === 2) {
          await API.business.employees.deleteHostessByAdmin({
            id: payload.value.userId,
            owner: payload.value.ownerId,
          });
        } else {
          await API.business.employees.deleteMarketerByAdmin({
            id: payload.value.userId,
            owner: payload.value.ownerId,
          });
        }
        const filteredItems = state.businessItems.filter(
          (el) => el.id !== payload.value.userId
        );
        commit({ type: "setClients", value: filteredItems });
        dispatch("closeModal");
      } catch (e) {
        dispatch("openModal", {
          value: {
            component: FailModal,
            props: {
              error: e.response.data.message,
            },
          },
        });
      }
    },
    async updateBusinessClient({ commit, dispatch }, payload) {
      try {
        await API.eviso.businessClients.updateClient(payload.value);
        dispatch("closeModal");
        commit({
          type: "setClient",
          value: {},
        });
      } catch (e) {
        console.log(e);
      }
    },
    async updateBusinessClientByEviso({ commit, state }, payload) {
      try {
        await API.eviso.businessClients.updateClientByEviso(payload.value);
        commit({
          type: "setClient",
          value: {
            ...state.client,
            email: payload.value.email,
            username: payload.value.username,
            name: payload.value.name,
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
    async getClients({ state, getters }, payload) {
      const itemsType = "businessItems";
      let items;
      try {
        if (getters.roleGroup === "org") {
          items = await API.eviso.businessClients.getClients(payload.value);
        } else {
          items = await API.eviso.clients.getClients(payload.value);
        }
        if (state[itemsType].length) {
          state[itemsType] = [...state[itemsType], ...items.data];
        } else {
          state[itemsType] = items.data;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getB2CClients({ state }, payload) {
      const itemsType = "clientItems";
      let items;
      try {
        items = await API.eviso.clients.getClients(payload.value);

        if (state[itemsType].length) {
          state[itemsType] = [...state[itemsType], ...items.data];
        } else {
          state[itemsType] = items.data;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async loadClient({ commit, getters, rootState, dispatch }, payload) {
      try {
        if (getters.roleGroup === "org") {
          let { data: ClientData } = await API.eviso.businessClients.getClient(
            payload.value
          );
          const { data: entities } =
            await API.eviso.businessClients.getEntitiesByEviso({
              id: ClientData.id,
            });
          const { data: caterings } =
            await API.eviso.businessClients.getCateringsByEviso({
              id: ClientData.id,
            });
          const result = {
            ...ClientData,
            caterings,
            entities,
            employees: [
              ...ClientData.hostesses.map((el) => ({ ...el, roleId: 2 })),
              ...ClientData.marketers.map((el) => ({ ...el, roleId: 3 })),
            ],
          };
          commit({
            type: "setClient",
            value: result,
          });
        } else {
          if (getters.role === "MAJOR") {
            const { data: entities } =
              await API.eviso.businessClients.getEntitiesByBusinessClient({
                id: rootState.general.userInfo.id,
              });
            const { data: caterings } =
              await API.eviso.businessClients.getCateringsByBusinessClient({
                id: rootState.general.userInfo.id,
              });
            await dispatch({
              type: "getEmployees",
              value: {
                owner:
                  getters.role === "MARKETER"
                    ? rootState.general.userInfo.owner
                    : rootState.general.userInfo.id,
              },
            });
            const result = {
              ...rootState.general.userInfo,
              caterings,
              entities,
              employees: rootState.employees.items,
            };
            commit({
              type: "setClient",
              value: result,
            });
          } else {
            const { data: ClientData } =
              await API.eviso.businessClients.getClientByBiz(payload.value);
            const { data: entities } =
              await API.eviso.businessClients.getEntitiesByBusinessClient({
                id: rootState.general.userInfo.owner,
              });
            const { data: caterings } =
              await API.business.employees.getCateringsByMarketer({
                cfTypeId: [],
                scheme: [],
                items: 100,
                name: "",
                address: "",
                statusId: [],
                cuisineId: [],
                specificities: [],
                tariffName: "",
                tariffProperties: [false, false, false, false, false, false],
                workingTime: [],
                pretty_id: "",
                capacity: [],
                averageCheck: [],
              });
            await dispatch({
              type: "getEmployees",
              value: {
                owner:
                  getters.role === "MARKETER"
                    ? rootState.general.userInfo.owner
                    : rootState.general.userInfo.id,
              },
            });
            const result = {
              ...ClientData,
              caterings,
              entities,
              employees: rootState.employees.items,
            };
            commit({
              type: "setClient",
              value: result,
            });
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
};

const restaurants = {
  state: () => ({
    coords: [],
    address: null,
    newCoords: null,
    restaurants: [],
    restaurant: null,
    selectedKitchens: [],
    selectedFeatures: [],
    restaurantName: "",
    restaurantTempId: "",
    isOrgAccEdit: true,
    isHide: false,
    favRestaurants: [],
  }),
  mutations: {
    setCoords(state, payload) {
      state.coords = payload.value;
    },
    setSelectedKitchens(state, payload) {
      state.selectedKitchens = payload.value;
    },
    setSelectedFeatures(state, payload) {
      state.selectedFeatures = payload.value;
    },
    setNewCoords(state, payload) {
      state.newCoords = payload.value;
    },
    setAddress(state, payload) {
      state.address = payload.value;
    },
    setRestaurants(state, payload) {
      state.restaurants = payload.value;
    },
    setRestaurant(state, payload) {
      state.restaurant = payload.value;
    },
    setRestaurantName(state, payload) {
      state.restaurantName = payload.value;
    },
    setRestaurantTempId(state, payload) {
      state.restaurantTempId = payload.value;
    },
    setIsOrgAccEdit(state, payload) {
      state.isOrgAccEdit = payload.value;
    },
    setIsHideToggle(state) {
      state.isHide = !state.isHide;
    },
    setIsHide(state, payload) {
      state.isHide = payload.value;
    },
    setFavRestaurants(state, payload) {
      state.favRestaurants = payload.value;
    },
  },
  actions: {
    async loadRestaurants({ commit, state, getters }, payload) {
      try {
        let items;
        if (getters.role === "MAJOR") {
          items = await API.business.catering.getAllCateringByBiz(
            payload.value
          );
        } else if (getters.role === "MARKETER") {
          items = await API.business.employees.getCateringsByMarketer(
            payload.value
          );
        } else {
          items = await API.business.catering.getAllCatering(payload.value);
        }
        if (state.restaurants.length) {
          commit({
            type: "setRestaurants",
            value: [...state.restaurants, ...items.data],
          });
        } else {
          commit({ type: "setRestaurants", value: items.data });
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getRestaurantTempId({ commit, dispatch }) {
      try {
        const res = await API.business.catering.getCateringTempId();
        commit({ type: "setRestaurantTempId", value: res.data });
      } catch (e) {
        dispatch("openModal", {
          value: {
            component: FailModal,
            props: {
              error: e.response.data.message,
            },
          },
        });
      }
    },
  },
};

const clientRestaurants = {
  state: () => ({
    items: [],
    hoveredCatering: "",
    clickedCluster: null,
    editMode: "",
    filter: {
      visitors: null,
      date: null,
      duration: null,
      time: null,
    },
  }),
  getters: {
    isMapFilterFilled(state) {
      return (
        state.filter.visitors &&
        state.filter.date &&
        state.filter.duration &&
        state.filter.time
      );
    },
  },
  mutations: {
    resetClientRestaurants(state) {
      router.push("/");
      state.hoveredCatering = "";
      state.editMode = "";
    },
    setClientRestaurants(state, payload) {
      state.items = payload.value;
    },
    setClickedCluster(state, payload) {
      if (state.clickedCluster === payload.value) {
        state.clickedCluster = null;
      } else {
        state.clickedCluster = payload.value;
      }
    },
    setHoveredCatering(state, payload) {
      state.hoveredCatering = payload.value;
    },
    setMapEditMode(state, payload) {
      state.editMode = payload.value;
    },
    setFilterTime(state, payload) {
      state.filter.time = payload.value;
    },
    setFilterDate(state, payload) {
      state.filter.date = payload.value;
    },
    setFilterVisitors(state, payload) {
      state.filter.visitors = payload.value;
    },
    setFilterDuration(state, payload) {
      state.filter.duration = payload.value;
    },
    resetCatFilter(state) {
      state.filter.duration = null;
      state.filter.visitors = null;
      state.filter.date = null;
      state.filter.time = null;
    },
  },
  actions: {
    async getClientRestaurants({ commit, rootState, getters }, payload) {
      try {
        let items = await API_WITHOUT_GUARDS.client.getMapCatering(
          payload.value
        );
        commit({ type: "setClientRestaurants", value: items.data });

        if (rootState.general.auth.isAuth && getters.role === "CLIENT") {
          const { data } = await API.business.catering.getFavorites();

          commit({
            type: "setFavRestaurants",
            value: data,
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getClientRestaurantsDate({ commit, rootState, getters }, payload) {
      try {
        let items = await API_WITHOUT_GUARDS.client.getMapCateringWithFilter(
          payload.value
        );

        commit({
          type: "setClientRestaurants",
          value: items.data,
        });

        if (rootState.general.auth.isAuth && getters.role === "CLIENT") {
          const { data } = await API.business.catering.getFavorites();

          commit({
            type: "setFavRestaurants",
            value: data,
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
};

const reviews = {
  state: () => ({
    item: null,
    items: [],
    currentItems: [],
    page: 0,
    pagesCount: 0,
    tab: 0,
    cat: "",
  }),
  mutations: {
    setReview(state, payload) {
      state.item = payload.value;
    },
    setReviews(state, payload) {
      state.items = payload.value;
    },
    setReviewTab(state, payload) {
      state.tab = payload.value;
    },
    setReviewCat(state, payload) {
      state.cat = payload.value;
    },
    resetCateringReview(state) {
      state.currentItems = [];
      state.page = 0;
      state.pagesCount = 0;
    },
  },
  actions: {
    async loadItemsByModerator({ commit, state }, payload) {
      try {
        let items = await API.reviews.getItemsByModerator(payload.value);
        if (state.items.length) {
          commit({
            type: "setReviews",
            value: [...state.items, ...items.data],
          });
        } else {
          commit({ type: "setReviews", value: items.data });
        }
      } catch (e) {
        console.log(e);
      }
    },
    async loadAuthorItems({ commit }, payload) {
      try {
        let items = await API.reviews.getAuthorItems(payload.value);
        commit({
          type: "setReviews",
          value: items.data,
        });
      } catch (e) {
        console.log(e);
      }
    },
    async loadCatItems({ commit }, payload) {
      try {
        let items = await API.reviews.getCatItems(payload.value);
        commit({
          type: "setReviews",
          value: items.data,
        });
      } catch (e) {
        console.log(e);
      }
    },
    async loadCateringItems({ commit, state, rootState }, payload) {
      try {
        let items;
        if (rootState.general.auth.isAuth) {
          items = await API.reviews.getCateringItems(payload.value);
        } else {
          items = await API_WITHOUT_GUARDS.reviews.getCateringItems(
            payload.value
          );
        }
        commit({
          type: "setReviews",
          value: items.data,
        });
        state.currentItems.push(...state.items.slice(0, 3));
        state.currentItems.forEach(async (el) => {
          try {
            let res;
            if (rootState.general.auth.isAuth) {
              res = await API.reviews.getImages(el.Id);
            } else {
              res = await API_WITHOUT_GUARDS.reviews.getImages(el.Id);
            }
            this.images = { ...this.images, [el.Id]: res.data };
          } catch (e) {
            console.log(e);
          }
        });
        state.pagesCount = Math.ceil((state.items.length - 3) / 10);
      } catch (e) {
        console.log(e);
      }
    },
    async loadCateringItemsMap({ commit, state, rootState }, payload) {
      try {
        let items;
        if (rootState.general.auth.isAuth) {
          items = await API.reviews.getCateringItemsMap(payload.value);
        } else {
          items = await API_WITHOUT_GUARDS.reviews.getCateringItemsMap(
            payload.value
          );
        }
        commit({
          type: "setReviews",
          value: items.data,
        });
        state.currentItems.push(...state.items.slice(0, 3));
        state.currentItems.forEach(async (el) => {
          try {
            let res;
            if (rootState.general.auth.isAuth) {
              res = await API.reviews.getImages(el.Id);
            } else {
              res = await API_WITHOUT_GUARDS.reviews.getImages(el.Id);
            }
            this.images = { ...this.images, [el.Id]: res.data };
          } catch (e) {
            console.log(e);
          }
        });
        state.pagesCount = Math.ceil((state.items.length - 3) / 10);
      } catch (e) {
        console.log(e);
      }
    },
    addCurrentItems({ state, rootState }) {
      const newPortion = state.items.slice(
        state.page * 10 + 3,
        10 * (state.page + 1) + 3
      );
      newPortion.forEach(async (el) => {
        try {
          let res;
          if (rootState.general.auth.isAuth) {
            res = await API.reviews.getImages(el.Id);
          } else {
            res = await API_WITHOUT_GUARDS.reviews.getImages(el.Id);
          }
          this.images = { ...this.images, [el.Id]: res.data };
        } catch (e) {
          console.log(e);
        }
      });
      state.currentItems.push(...newPortion);
      state.page++;
    },
    async loadItem({ commit }, payload) {
      try {
        let items = await API.eviso.moderator.getModerators(payload.value);
        commit({
          type: "setItems",
          value: items.data,
        });
      } catch (e) {
        console.log(e);
      }
    },
    async deleteItem({ state, dispatch }, payload) {
      try {
        await API.reviews.deleteItem(payload.value);
        state.items = state.items.filter((el) => el.Id !== payload.value.Id);
      } catch (e) {
        dispatch("openModal", {
          value: {
            component: FailModal,
            props: {
              error: e.response.data.message,
            },
          },
        });
      }
    },
    async deleteItemComment({ state, dispatch }, payload) {
      try {
        await API.reviews.deleteCommentItem(payload.value);
        const review = state.items.find(
          (el) => el.Id === payload.value.parentID
        );
        review.comments = review.comments.filter(
          (el) => el.Id !== payload.value.Id
        );
      } catch (e) {
        dispatch("openModal", {
          value: {
            component: FailModal,
            props: {
              error: e.response.data.message,
            },
          },
        });
      }
    },
    async reviewToggleLike({ state, dispatch }, payload) {
      try {
        const likes = await API.reviews.toggleLike(payload.value);
        const review = state.items.find((el) => el.Id === payload.value.Id);
        review.isLiked = !review.isLiked;
        review.likes = likes.data;
      } catch (e) {
        dispatch("openModal", {
          value: {
            component: FailModal,
            props: {
              error: e.response.data.message,
            },
          },
        });
      }
    },

    async reviewToggleLikeComment({ state, dispatch }, payload) {
      try {
        const likes = await API.reviews.toggleLikeComment(payload.value);
        const review = state.items.find(
          (el) => el.Id === payload.value.parentID
        );
        const comment = review.comments.find(
          (el) => el.Id === payload.value.Id
        );
        comment.isLiked = !comment.isLiked;
        comment.likes = likes.data;
      } catch (e) {
        dispatch("openModal", {
          value: {
            component: FailModal,
            props: {
              error: e.response.data.message,
            },
          },
        });
      }
    },
    async updateItemStatus({ state, dispatch }, payload) {
      try {
        await API.reviews.updateItemStatus(payload.value);
        state.items = state.items.map((el) =>
          el.Id === payload.value.Id
            ? { ...el, statusID: payload.value.statusID }
            : el
        );
      } catch (e) {
        dispatch("openModal", {
          value: {
            component: FailModal,
            props: {
              error: e.response.data.message,
            },
          },
        });
      }
    },
    async createItem({ dispatch }, payload) {
      try {
        await API.reviews.createItem(payload.value);
      } catch (e) {
        dispatch("openModal", {
          value: {
            component: FailModal,
            props: {
              error: e.response.data.message,
            },
          },
        });
      }
    },
    async createComment({ dispatch }, payload) {
      try {
        await API.reviews.createComment(payload.value);
      } catch (e) {
        dispatch("openModal", {
          value: {
            component: FailModal,
            props: {
              error: e.response.data.message,
            },
          },
        });
      }
    },
  },
};

const counterparties = {
  state: () => ({
    entityItems: [],
    ipItems: [],
    item: null,
    tab: 0,
    formType: 0,
  }),
  mutations: {
    setIpItems(state, payload) {
      state.ipItems = payload.value;
    },
    setEntityItems(state, payload) {
      state.entityItems = payload.value;
    },
    setCounterparties(state, payload) {
      const itemsType = "entityItems";
      state[itemsType] = payload.value;
    },
    setIPCounterparties(state, payload) {
      const itemsType = "ipItems";
      state[itemsType] = payload.value;
    },
    setCounterparty(state, payload) {
      state.item = payload.value;
    },
    setCounterpartyTab(state, payload) {
      state.tab = payload.value;
    },
  },
  actions: {
    async inviteEDM({ getters, dispatch }, payload) {
      try {
        await API.counterparties.inviteEdm(
          getters.roleGroup === "biz" ? "biz" : "eviso",
          payload.value
        );
        dispatch("openModal", {
          value: {
            component: AcceptDiadockModal,
            props: {
              inn: payload.value.inn,
              ownerId: payload.value.orgAccId,
            },
          },
        });
      } catch (e) {
        console.log(e);
      } finally {
        router.go(-1);
      }
    },
    async createCounterparty({ getters, dispatch }, payload) {
      try {
        if (payload.value.type === 0) {
          if (getters.roleGroup === "org") {
            await API.counterparties.createJurByEviso(payload.value.data);
          } else {
            await API.counterparties.createJurByBiz(payload.value.data);
          }
        } else {
          if (getters.roleGroup === "org") {
            await API.counterparties.createIpByEviso(payload.value.data);
          } else {
            await API.counterparties.createIpByBiz(payload.value.data);
          }
        }
        if (payload.value.isDiadock) {
          console.log(payload.value.data);
          dispatch("inviteEDM", {
            value: {
              inn: payload.value.data.inn,
              ownerId: payload.value.data.orgAccId,
            },
          });
        } else {
          router.go(-1);
        }
      } catch (e) {
        dispatch("openModal", {
          value: {
            component: FailModal,
            props: {
              error: e.response.data.message,
            },
          },
        });
      }
    },
    async updateCounterpartyEntity({ getters, dispatch }, payload) {
      try {
        if (payload.value.type === 0) {
          if (getters.roleGroup === "org") {
            await API.counterparties.updateJurByEviso(payload.value.data);
          } else {
            await API.counterparties.updateJurByBiz(payload.value.data);
          }
        } else {
          if (getters.roleGroup === "org") {
            await API.counterparties.updateIpByEviso(payload.value.data);
          } else {
            await API.counterparties.updateIpByBiz(payload.value.data);
          }
        }
        if (payload.value.isDiadock) {
          dispatch("inviteEDM", {
            value: {
              inn: payload.value.data.inn,
              ownerId: payload.value.data.orgAccId,
            },
          });
        } else {
          router.go(-1);
        }
      } catch (e) {
        dispatch("openModal", {
          value: {
            component: FailModal,
            props: {
              error: e.response.data.message,
            },
          },
        });
      }
    },
    async deleteCounterparty({ state, getters, dispatch }, payload) {
      try {
        if (payload.value.type === 0) {
          if (getters.roleGroup === "org") {
            await API.counterparties.deleteEntityJurByEviso(payload.value.data);
          } else {
            await API.counterparties.deleteEntityJurByBiz(payload.value.data);
          }
          state.entityItems = state.entityItems.filter(
            (el) => el.id !== payload.value.data.ID
          );
        } else {
          if (getters.roleGroup === "org") {
            await API.counterparties.deleteEntityIpByEviso(payload.value.data);
          } else {
            await API.counterparties.deleteEntityIpByBiz(payload.value.data);
          }
          state.ipItems = state.ipItems.filter(
            (el) => el.id !== payload.value.data.ID
          );
        }
      } catch (e) {
        dispatch("openModal", {
          value: {
            component: FailModal,
            props: {
              error: e.response.data.message,
            },
          },
        });
      }
    },
    async addCatering(_, payload) {
      try {
        await API.eviso.businessClients.addCaterings(payload.value);
      } catch (e) {
        console.log(e);
      }
    },
    async getCounterparties({ commit, state }, payload) {
      const itemsType = "entityItems";
      let items;
      try {
        items = await API.counterparties.getLegalEntities(payload.value[0]);

        if (!payload.value[1]) {
          commit({
            type: "setCounterparties",
            value: [...state[itemsType], ...items.data],
          });
        } else {
          commit({ type: "setCounterparties", value: items.data });
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getIPCounterparties({ commit, state }, payload) {
      const itemsType = "ipItems";
      let items;
      try {
        items = await API.counterparties.getIps(payload.value[0]);
        if (!payload.value[1]) {
          commit({
            type: "setIPCounterparties",
            value: [...state[itemsType], ...items.data],
          });
        } else {
          commit({ type: "setIPCounterparties", value: items.data });
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
};

const documents = {
  state: () => ({
    documentItems: [],
    documentsUpdateDate: "",
    bills: [],
    problematicBills: [],
    contracts: [],
    isSidebar: false,
  }),
  mutations: {
    setIsSidebar(state, payload) {
      state.isSidebar = payload.value;
    },
    setDocumentItems(state, payload) {
      state.documentItems = payload.value;
    },
    setBillsItems(state, payload) {
      state.bills = payload.value;
    },
    setContractsItems(state, payload) {
      state.contracts = payload.value;
    },
    setProblematicBillsItems(state, payload) {
      state.problematicBills = payload.value;
    },
    setDocumentsUpdateDate(state, payload) {
      state.documentsUpdateDate = payload.value;
    },
  },
  actions: {
    async getDocuments({ commit, state, getters }, payload) {
      let items;
      try {
        if (getters.roleGroup === "org") {
          if (getters.role === "SECRETARY") {
            items = await API.documents.getItems(payload.value);
          } else {
            items = await API.documents.getItemsByOrg(payload.value);
          }
        } else {
          items = await API.documents.getItemsByBiz(payload.value);
        }
        commit({
          type: "setDocumentsUpdateDate",
          value: items.data.updateTime,
        });
        if (state.length) {
          commit({
            type: "setDocumentItems",
            value: [...state, ...items.data.docs],
          });
        } else {
          commit({ type: "setDocumentItems", value: items.data.docs });
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getBills({ commit, state }, payload) {
      let items;
      try {
        items = await API.documents.getBillItems(payload.value);
        if (state.length) {
          commit({
            type: "setBillsItems",
            value: [...state, ...items.data],
          });
        } else {
          commit({ type: "setBillsItems", value: items.data });
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getContracts({ commit, state }, payload) {
      let items;
      try {
        items = await API.documents.getContractItems(payload.value);
        if (state.length) {
          commit({
            type: "setContractsItems",
            value: [...state, ...items.data],
          });
        } else {
          commit({ type: "setContractsItems", value: items.data });
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getProblematicBills({ commit, state }, payload) {
      let items;
      try {
        if (payload.value.isRejected) {
          items = await API.documents.getRejectedItems(payload.value.data);
        } else {
          items = await API.documents.getProcessedItems(payload.value.data);
        }
        if (state.length) {
          commit({
            type: "setProblematicBillsItems",
            value: [...state, ...items.data],
          });
        } else {
          commit({ type: "setProblematicBillsItems", value: items.data });
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
};

const statistics = {
  state: () => ({
    tab: 0,
  }),
  mutations: {
    statisticsTab(state, payload) {
      state.tab = payload.value;
    },
  },
};

const booking = {
  state: () => ({
    bookingCatering: "",
    new: false,
    floor: 0,
    bookingDetails: {},
    floors: [],
    cateringName: "",
    tables: [],
    schemeFloors: [],
  }),
  mutations: {
    resetScheme(state) {
      state.floors = [[]];
    },
    setNew(state, payload) {
      state.new = payload.value;
    },
    setBookingCatering(state, payload) {
      state.bookingCatering = payload.value;
    },
    setFloor(state, payload) {
      state.floor = payload.value;
    },
    addFloor(state) {
      state.floors.push([]);
    },
    setFloors(state, payload) {
      state.floors = payload.value;
    },
    setTables(state, payload) {
      state.tables = payload.value;
    },
    setSchemeFloors(state, payload) {
      state.schemeFloors = payload.value;
    },
    deleteFloor(state, payload) {
      state.floors.splice(payload.value, 1);
      state.floor = 0;
    },
  },
};

const booking_master = {
  state: () => ({
    step: 0,
    isNextButtonDisabled: false,
    clientDetails: {},
    isDetailsSent: false,
  }),
  mutations: {
    setStep(state, payload) {
      state.step = payload.value;
    },
    setClientDetails(state, payload) {
      state.clientDetails = payload.value;
    },
    setNextButtonStatusDisabled(state, payload) {
      state.isNextButtonDisabled = payload.value;
    },
    setIsDetailsSent(state, payload) {
      state.isDetailsSent = payload.value;
    },
  },
};

const notices = {
  state: () => ({
    unreadItems: [],
    unreadPushItems: [],
    items: [],
    isNotices: false,
    isInit: true,
  }),
  mutations: {
    setNotices(state, payload) {
      state.items = payload.value;
      if (state.isInit) {
        state.unreadItems.push(
          ...payload.value.filter((el) => el.status === "Send")
        );
        state.isInit = false;
      }
    },
    setIntervalNotices(state, payload) {
      state.items.unshift(...payload.value);
      state.unreadItems.unshift(...payload.value);
      state.unreadPushItems.unshift(...payload.value);
    },
    toggleNotices(state) {
      state.isNotices = !state.isNotices;
      if (state.isNotices) {
        state.unreadPushItems = state.unreadPushItems.filter(
          (el) => el.deliveryType === 2
        );
      }
    },
    clearNotification(state, payload) {
      state.unreadItems = state.unreadItems.filter(
        (el) => el.id !== payload.value
      );
      state.unreadPushItems = state.unreadPushItems.filter(
        (el) => el.id !== payload.value
      );
      state.items = state.items.filter((el) => el.id !== payload.value);
    },
    clearPushNotification(state, payload) {
      state.unreadPushItems = state.unreadPushItems.filter(
        (el) => el.id !== payload.value
      );
      const item = state.items.find((el) => el.id === payload.value);
      item.status = "Read";
    },
    setUnreadItems(state) {
      state.unreadItems = state.unreadItems.filter(
        (el) => el.deliveryType === 2
      );
      state.items = state.items.map((el) =>
        el.deliveryType !== 1 ? { ...el } : { ...el, status: "Read" }
      );
      state.unreadPushItems = [];
    },
  },
  actions: {
    async initNotices({ getters, rootState, commit }) {
      try {
        const { data } = await API.notices.getNotifications(
          rootState.general.userInfo.id,
          getters.roleGroup
        );
        commit({
          type: "setNotices",
          value: data,
        });
      } catch (e) {
        console.log(e);
      } finally {
        notificationsLongPolling.reset();
        notificationsLongPolling.start(
          rootState.general.userInfo.id,
          getters.roleGroup,
          (data) => {
            commit({
              type: "setIntervalNotices",
              value: data,
            });
          }
        );
      }
    },
  },
};

const employees = {
  state: () => ({
    items: [],
    item: null,
    tab: 0,
    formType: 0,
  }),
  mutations: {
    setEmployees(state, payload) {
      state.items = payload.value;
    },
    setEmployee(state, payload) {
      state.item = payload.value;
    },
    setEmployeeTab(state, payload) {
      state.tab = payload.value;
    },
    setEmployeeFormType(state, payload) {
      state.formType = payload.value;
    },
  },
  actions: {
    async getEmployees({ commit, state, getters }, payload) {
      commit({
        type: "setEmployees",
        value: [],
      });

      try {
        let marketers = [];
        let hostesses = await API.business.employees.getHostesses(
          payload.value
        );
        if (getters.role === "MAJOR" || getters.roleGroup === "org") {
          const marketersRes = await API.business.employees.getMarketers(
            payload.value
          );
          marketers = marketersRes.data;
        }

        const newHostesses = hostesses.data.map((el) => ({ ...el, roleId: 2 }));
        const newMarketers = marketers.length
          ? marketers.map((el) => ({ ...el, roleId: 3 }))
          : [];

        commit({
          type: "setEmployees",
          value: [...state.items, ...[...newHostesses, ...newMarketers]],
        });
      } catch (e) {
        console.log(e);
      }
    },
    async createEmployee({ state, commit, dispatch }, payload) {
      try {
        if (state.formType === 0) {
          await API.business.employees.createMarketer(payload.value);
        } else {
          await API.business.employees.createHostess(payload.value);
        }
        commit({ type: "setEmployeeTab", value: 0 });
        commit({ type: "setEmployee", value: null });
      } catch (e) {
        dispatch("openModal", {
          value: {
            component: FailModal,
            props: {
              error: e.response.data.message,
            },
          },
        });
      }
    },
    async updateEmployee({ state, commit, dispatch }, payload) {
      try {
        if (state.formType === 0) {
          await API.business.employees.updateMarketer(payload.value);
        } else {
          await API.business.employees.updateHostess(payload.value);
        }
        commit({ type: "setEmployeeTab", value: 0 });
        commit({ type: "setEmployee", value: null });
      } catch (e) {
        dispatch("openModal", {
          value: {
            component: FailModal,
            props: {
              error: e.response.data.message,
            },
          },
        });
      }
    },
    async deleteEmployee({ state, dispatch }, payload) {
      try {
        if (payload.value.roleId === 3) {
          await API.business.employees.deleteMarketer(payload.value.data);
          state.items = state.items.filter(
            (el) => el.id !== payload.value.data.id
          );
        } else {
          await API.business.employees.deleteHostess(payload.value.data);
          state.items = state.items.filter(
            (el) => el.id !== payload.value.data.id
          );
        }
      } catch (e) {
        dispatch("openModal", {
          value: {
            component: FailModal,
            props: {
              error: e.response.data.message,
            },
          },
        });
      }
    },
  },
};

const biz_booking = {
  state: () => ({
    items: [],
    item: null,
    itemInit: null,
    cat: "",
    mode: "",
    bookingModal: {},
    timers: {},
    isNewTimers: false,
    seats: [],
    waitings: [],
    waitingTables: [],
    tables: {},
  }),
  mutations: {
    setBookingModal(state, payload) {
      state.bookingModal = payload.value;
    },
    setBizBookingItems(state, payload) {
      state.items = payload.value;
    },
    setBizBookingItem(state, payload) {
      state.item = payload.value;
    },
    setBizBookingItemInit(state, payload) {
      state.itemInit = payload.value;
    },
    setBizBookingMode(state, payload) {
      state.mode = payload.value;
    },
    setBizBookingTimers(state, payload) {
      state.timers = payload.value;
    },
    setBizBookingIsNewTimers(state, payload) {
      state.isNewTimers = payload.value;
    },
    reset(state) {
      function initialState() {
        return {
          items: [],
          item: null,
          itemInit: null,
          cat: "",
          mode: "",
          bookingModal: {},
          timers: {},
          seats: [],
          waitings: [],
          waitingTables: [],
          initBookingInfo: {},
          tables: {},
        };
      }

      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
  },
  actions: {
    async createWaitingItem({ dispatch }, payload) {
      try {
        await API.booking.waitingNew({
          catId: payload.value.catId,
          name: payload.value.name,
          phone: payload.value.phone,
          visitors: payload.value.visitors,
        });
      } catch (e) {
        dispatch("openModal", {
          value: {
            component: FailModal,
            props: {
              error: e.response.data.message,
            },
          },
        });
      }
    },

    async returnWaitingItem({ dispatch }, payload) {
      try {
        await API.booking.waitingReturn({
          Id: payload.value.Id,
        });
      } catch (e) {
        dispatch("openModal", {
          value: {
            component: FailModal,
            props: {
              error: e.response.data.message,
            },
          },
        });
      }
    },

    async sitWaitingGuest({ dispatch }, payload) {
      try {
        await API.booking.waitingEnd({
          Id: payload.value.Id,
          startVisit: payload.value.startVisit,
        });
      } catch (e) {
        dispatch("openModal", {
          value: {
            component: FailModal,
            props: {
              error: e.response.data.message,
            },
          },
        });
      }
    },

    async saveEditingWaitingItem({ dispatch }, payload) {
      try {
        await API.booking.waitingEdit({
          Id: payload.value.Id,
          name: payload.value.name,
          phone: payload.value.phone,
        });
      } catch (e) {
        dispatch("openModal", {
          value: {
            component: FailModal,
            props: {
              error: e.response.data.message,
            },
          },
        });
      }
    },

    async deleteWaitingItem({ dispatch }, payload) {
      try {
        await API.booking.waitingEnd({
          Id: payload.value.Id,
          startVisit: payload.value.startVisit,
        });
      } catch (e) {
        dispatch("openModal", {
          value: {
            component: FailModal,
            props: {
              error: e.response.data.message,
            },
          },
        });
      }
    },

    async visitStart({ dispatch }, payload) {
      dispatch("openModal", {
        value: {
          component: BookingSeatsDurationModal,
          props: {
            catId: payload.value.catId,
            tableNumber: payload.value.tableNumber,
            tableId: payload.value.tableId,
            busyTimeStart: payload.value.busyTimeStart,
          },
        },
      });
      this.selectedTables = [];
    },
    async visitChange({ dispatch }, payload) {
      try {
        await API.booking.visitChange({
          catId: payload.value.catId,
          tableIdFrom: payload.value.tableIdFrom,
          tableIdTo: payload.value.tableIdTo,
        });
      } catch (e) {
        dispatch("openModal", {
          value: {
            component: FailModal,
            props: {
              error: e.response.data.message,
            },
          },
        });
      }
    },
    async visitEnd({ dispatch }, payload) {
      try {
        await API.booking.visitEnd({
          catId: payload.value.catId,
          tableIds: payload.value.tableIds,
        });
      } catch (e) {
        dispatch("openModal", {
          value: {
            component: FailModal,
            props: {
              error: e.response.data.message,
            },
          },
        });
      }
    },
    async getBizBookings({ commit }, payload) {
      try {
        let items = await API.booking.getBookings(payload.value);
        commit({
          type: "setBizBookingItems",
          value: items.data,
        });
      } catch (e) {
        console.log(e);
      }
    },
    async getBizSeatTimers({ commit, state, rootState }, payload) {
      const oldTimers = state.timers;

      try {
        const obj = {};
        for (const key in payload.value.BusyTables) {
          if (
            payload.value.BusyTables[key]["visitEnd"] &&
            payload.value.BusyTables[key]["nearBooking"]
          ) {
            const time = Math.round(
              (new Date(
                payload.value.BusyTables[key]["nearBooking"].replace(
                  "Z",
                  "+05:00"
                )
              ).getTime() -
                new Date()) /
                60000
            );
            if (time < 60) {
              obj[key] = time;
            }
          }
        }
        const tables = rootState.booking.tables;
        tables.forEach((el) => {
          if (obj[el.id] && obj[el.id] < 60) {
            const time = obj[el.id];
            obj[el.id] = { time, number: el.number };
          }
        });

        if (Object.keys(oldTimers).length !== Object.keys(obj).length) {
          commit({ type: "setBizBookingIsNewTimers", value: true });
        }

        commit({ type: "setBizBookingTimers", value: obj });
      } catch (e) {
        console.log(e);
      }
    },
    async getBizSeats({ state, dispatch, commit }, payload) {
      try {
        const seats = await API.booking.getSeats({
          catId: payload.value.catId,
        });
        state.seats = seats.data.BusyTables;
        state.waitings = seats.data.WaitingList;
        state.waitingTables = seats.data.WaitingList.filter(
          (item) =>
            getIsWaitingItem(item.startTime) && !getIsLateItem(item.startTime)
        )
          .map((item) => item.tableId)
          .filter((item) => item !== "00000000-0000-0000-0000-000000000000");

        const res = await API_WITHOUT_GUARDS.catering.getScheme(
          payload.value.catId
        );

        commit({ type: "setTables", value: res.data.tables });

        dispatch("getBizSeatTimers", {
          value: {
            BusyTables: state.seats,
            catId: payload.value.catId,
          },
        });
      } catch (e) {
        console.log(e);
      }

      seatsInfoLongPolling.reset();
      seatsInfoLongPolling.start(
        { catId: payload.value.catId },
        async (data) => {
          state.seats = data.BusyTables;
          state.waitings = data.WaitingList;
          state.waitingTables = data.WaitingList.filter(
            (item) =>
              getIsWaitingItem(item.startTime) && !getIsLateItem(item.startTime)
          )
            .map((item) => item.tableId)
            .filter((item) => item !== "00000000-0000-0000-0000-000000000000");

          if (Object.keys(state.initBookingInfo.data.tables).length) {
            for (const key in state.initBookingInfo.data.tables) {
              state.tables[key] = {
                tableInfo: checkVisitTime(
                  state.initBookingInfo.data.tables[key][0],
                  state.seats[key]?.nearBooking,
                  state.seats[key]?.visitStart
                ),
                prevBooking: getTime(state.seats[key]?.prevBooking),
                nearBooking: getTime(state.seats[key]?.nearBooking),
                visitEnd: getTime(state.seats[key]?.visitEnd),
                isAlert: checkVisitTime(
                  state.initBookingInfo.data.tables[key][0]
                ).isAlert,
                isBanVisit: checkVisitTime(
                  state.initBookingInfo.data.tables[key][0]
                ).isBanVisit,
                busyTimeStart: checkVisitTime(
                  state.initBookingInfo.data.tables[key][0]
                ).busyTimeStart,
                message: checkVisitTime(
                  state.initBookingInfo.data.tables[key][0]
                ).message,
              };
            }
          }

          dispatch("getBizSeatTimers", {
            value: { BusyTables: data.BusyTables, catId: payload.value.catId },
          });
        }
      );

      try {
        const date = new Date();

        state.initBookingInfo = await API.booking.bookTable({
          visitTime: new Date(
            date.getTime() - (date.getTimezoneOffset() - 120) * 60000
          ),
          catId: payload.value.catId,
          visitors: 1,
          visitDuration: 60,
        });

        for (const key in state.initBookingInfo.data.tables) {
          state.tables[key] = {
            tableInfo: checkVisitTime(
              state.initBookingInfo.data.tables[key][0],
              state.seats[key]?.nearBooking
            ),
            prevBooking: getTime(state.seats[key]?.prevBooking),
            nearBooking: getTime(state.seats[key]?.nearBooking),
            visitEnd: getTime(state.seats[key]?.visitEnd),
            isAlert: checkVisitTime(state.initBookingInfo.data.tables[key][0])
              .isAlert,
            isBanVisit: checkVisitTime(
              state.initBookingInfo.data.tables[key][0]
            ).isBanVisit,
            busyTimeStart: checkVisitTime(
              state.initBookingInfo.data.tables[key][0]
            ).busyTimeStart,
            message: checkVisitTime(state.initBookingInfo.data.tables[key][0])
              .message,
          };
        }
      } catch (e) {
        console.log(e);
      }
    },
    async updateBizBookingByHostess({ state, commit, dispatch }, payload) {
      try {
        const { data } = await API.booking.bookEdit(payload.value);

        if (state.items.length) {
          let item = state.items.find((el) => el.id === data.id);
          // eslint-disable-next-line no-unused-vars
          item.statusId = data.statusId;
        }

        commit({
          type: "setBizBookingItem",
          value: null,
        });
        commit({
          type: "setBizBookingMode",
          value: "",
        });
      } catch (e) {
        dispatch("openModal", {
          value: {
            component: FailModal,
            props: {
              error: e.response.data.message,
            },
          },
        });
      }
    },
  },
};

const modal = {
  state: () => ({
    modalState: {
      component: null,
      props: {},
    },
  }),
  mutations: {
    setModalState(state, payload) {
      state.modalState = payload.value;
    },
  },
  actions: {
    openModal({ commit }, payload) {
      const { props, component } = payload.value;

      const body = document.body;

      if (body) body.style.overflow = "hidden";

      commit({
        type: "setModalState",
        value: { component, props: props || {} },
      });
    },

    closeModal({ commit }) {
      commit({
        type: "setModalState",
        value: { component: null, props: {} },
      });

      const body = document.body;

      if (body) body.style.overflow = "auto";
    },
  },
};

export default createStore({
  modules: {
    general,
    settingsManagers,
    settingsModeration,
    settingsSecretary,
    restaurants,
    auth,
    journal,
    clients,
    clientRestaurants,
    reviews,
    counterparties,
    statistics,
    booking,
    notices,
    employees,
    biz_booking,
    documents,
    modal,
    booking_master,
  },
});
