<template>
  <div
    class="table"
    :class="{
      _busy:
        table.tableInfo?.prevBooking ||
        table.tableInfo?.visitEnd ||
        table.tableInfo?.busyTimeEnd,
    }"
  >
    <div class="column">
      <span class="number">Стол №{{ table.number }}</span>
    </div>
    <div
      v-if="
        table.tableInfo?.prevBooking ||
        table.tableInfo?.visitEnd ||
        table.tableInfo?.busyTimeEnd
      "
      class="column"
    >
      <span class="title">Занят до</span>
      <span class="info">{{
        table.tableInfo?.prevBooking ||
        table.tableInfo?.visitEnd ||
        table.tableInfo?.busyTimeEnd
      }}</span>
    </div>
    <div v-else class="column">
      <span class="title">Свободен до</span>
      <div class="info">
        <IconWatchBookingItem />
        <span>{{
          table.tableInfo?.visitEnd ||
          table.tableInfo?.nearBooking ||
          table.tableInfo?.busyTimeStart
        }}</span>
      </div>
    </div>
    <div v-if="!isModal" class="column">
      <span class="title">Мест</span>
      <div class="info">
        <IconPersonSidebar />
        <span> {{ table.capacity }}</span>
      </div>
    </div>
    <IconCross v-if="!isModal" class="cross" @click="onCrossClickHandler" />
  </div>
</template>

<script>
import IconCross from "@/components/icons/IconCross.vue";
import IconPersonSidebar from "@/components/icons/IconPersonSidebar.vue";
import IconWatchBookingItem from "@/components/icons/IconWatchBookingItem.vue";
export default {
  name: "BookingSchemeTable",

  props: {
    table: Object,
    isModal: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    IconWatchBookingItem,
    IconPersonSidebar,
    IconCross,
  },

  methods: {
    onCrossClickHandler() {
      if (this.table?.busyTimeEnd) {
        return;
      }
      this.$emit("close", this.table.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  flex: 0 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 8px 56px 8px 24px;
  border-left: 4px solid #f18e35;
  border-radius: 8px;
  background-color: #fef6e8;
  color: #222222;

  &._busy {
    cursor: not-allowed;
    filter: grayscale(1);

    .cross {
      cursor: not-allowed;
    }
  }
}

.column {
  height: 50px;
  display: flex;
  flex-direction: column;

  &:first-child {
    align-items: center;
    flex-direction: row;
  }

  svg {
    width: 20px;
    height: 20px;
  }
}

.number {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

.title {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.info {
  display: flex;
  align-items: center;
  padding: 2px 0;
  gap: 4px;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: #f18e35;
}

.cross {
  position: absolute;
  top: 13px;
  right: 13px;
  cursor: pointer;
}
</style>
