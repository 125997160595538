<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.1878 13.3535C12.3406 13.5063 12.5281 13.5827 12.7503 13.5827C12.9725 13.5827 13.167 13.4993 13.3337 13.3327C13.4864 13.1799 13.5628 12.9855 13.5628 12.7493C13.5628 12.5132 13.4864 12.3188 13.3337 12.166L10.8337 9.66602V6.64518C10.8337 6.40907 10.7539 6.21463 10.5945 6.06185C10.4345 5.90907 10.2364 5.83268 10.0003 5.83268C9.76421 5.83268 9.56644 5.91241 9.40699 6.07185C9.24699 6.23185 9.16699 6.4299 9.16699 6.66602V9.97852C9.16699 10.0896 9.18783 10.1971 9.22949 10.301C9.27116 10.4055 9.33366 10.4993 9.41699 10.5827L12.1878 13.3535ZM10.0003 18.3327C8.84755 18.3327 7.76421 18.1138 6.75033 17.676C5.73644 17.2388 4.85449 16.6452 4.10449 15.8952C3.35449 15.1452 2.76088 14.2632 2.32366 13.2493C1.88588 12.2355 1.66699 11.1521 1.66699 9.99935C1.66699 8.84657 1.88588 7.76324 2.32366 6.74935C2.76088 5.73546 3.35449 4.85352 4.10449 4.10352C4.85449 3.35352 5.73644 2.75963 6.75033 2.32185C7.76421 1.88463 8.84755 1.66602 10.0003 1.66602C11.1531 1.66602 12.2364 1.88463 13.2503 2.32185C14.2642 2.75963 15.1462 3.35352 15.8962 4.10352C16.6462 4.85352 17.2398 5.73546 17.677 6.74935C18.1148 7.76324 18.3337 8.84657 18.3337 9.99935C18.3337 11.1521 18.1148 12.2355 17.677 13.2493C17.2398 14.2632 16.6462 15.1452 15.8962 15.8952C15.1462 16.6452 14.2642 17.2388 13.2503 17.676C12.2364 18.1138 11.1531 18.3327 10.0003 18.3327ZM10.0003 16.666C11.8475 16.666 13.4206 16.0168 14.7195 14.7185C16.0178 13.4196 16.667 11.8466 16.667 9.99935C16.667 8.15213 16.0178 6.57907 14.7195 5.28018C13.4206 3.98185 11.8475 3.33268 10.0003 3.33268C8.1531 3.33268 6.58033 3.98185 5.28199 5.28018C3.9831 6.57907 3.33366 8.15213 3.33366 9.99935C3.33366 11.8466 3.9831 13.4196 5.28199 14.7185C6.58033 16.0168 8.1531 16.666 10.0003 16.666Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "IconWatchBookingItem",
  props: {
    color: {
      default: "#F18E35",
    },
  },
};
</script>

<style scoped></style>
