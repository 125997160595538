<template>
  <div class="inner">
    <h2>Изменения сохранены</h2>
    <p>
      Вам отправлено приглашение в ЭДО Контур.Диадок. Примите его для пополнения
      счета и активации услуг.
    </p>
    <div class="buttons">
      <VButton @click="accept" class="button">Хорошо</VButton>
    </div>
    <IconPopupCross
      v-if="screenWidth !== 'mobile'"
      class="modal-cross"
      @click="store.dispatch('closeModal')"
    />
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import IconPopupCross from "@/components/icons/IconPopupCross.vue";
import VButton from "@/components/ui/VButton.vue";

const store = useStore();
const router = useRouter();

const accept = () => {
  store.dispatch("closeModal");
  router.go(-1);
};

const screenWidth = computed(() => {
  return store.getters.screenType;
});
</script>

<style lang="scss" scoped>
.inner {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 448px;
  margin: auto;
  padding: 32px 56px 40px 56px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;

  @media screen and (max-width: 841px) {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    box-shadow: 0 -2px 10px 0 #0000000d;
    border-radius: 20px 20px 0 0;
    padding: 32px 24px 24px 24px;
  }
}

h2 {
  margin-bottom: 24px;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #f0811e;
}

p {
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #222222;
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 32px;
}

.button {
  width: 100%;

  &:last-child {
    margin-top: 16px;
  }
}

.modal-cross {
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
}
</style>
