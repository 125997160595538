<template>
  <div class="container">
    <div class="stars">
      <IconBigStar :is-filled="getFilledValue(1)" @click="value = 1" />
      <IconBigStar :is-filled="getFilledValue(2)" @click="value = 2" />
      <IconBigStar :is-filled="getFilledValue(3)" @click="value = 3" />
      <IconBigStar :is-filled="getFilledValue(4)" @click="value = 4" />
      <IconBigStar :is-filled="getFilledValue(5)" @click="value = 5" />
    </div>
  </div>
</template>

<script>
import IconBigStar from "@/components/icons/IconBigStar";

export default {
  name: "InputStar",
  components: {
    IconBigStar,
  },
  data() {
    return {
      value: 0,
    };
  },
  methods: {
    getFilledValue(value) {
      return this.value >= value;
    },
  },
  watch: {
    value(value) {
      this.$emit("update:value", value);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;

  svg {
    cursor: pointer;
  }

  svg:not(:last-child) {
    margin-right: 6px;
  }
}
</style>
