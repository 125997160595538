<template>
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      v-if="isFilled"
      d="M8.00018 12.2292L4.54185 14.3125C4.38907 14.4097 4.22935 14.4514 4.06268 14.4375C3.89601 14.4236 3.75018 14.3681 3.62518 14.2708C3.50018 14.1736 3.40296 14.0522 3.33351 13.9067C3.26407 13.7606 3.25018 13.5972 3.29185 13.4167L4.20851 9.47917L1.14601 6.83333C1.00713 6.70833 0.920459 6.56583 0.886014 6.40583C0.851014 6.24639 0.861292 6.09028 0.916847 5.9375C0.972403 5.78472 1.05574 5.65972 1.16685 5.5625C1.27796 5.46528 1.43074 5.40278 1.62518 5.375L5.66685 5.02083L7.22935 1.3125C7.29879 1.14583 7.40657 1.02083 7.55268 0.9375C7.69824 0.854167 7.8474 0.8125 8.00018 0.8125C8.15296 0.8125 8.3024 0.854167 8.44851 0.9375C8.59407 1.02083 8.70157 1.14583 8.77101 1.3125L10.3335 5.02083L14.3752 5.375C14.5696 5.40278 14.7224 5.46528 14.8335 5.5625C14.9446 5.65972 15.028 5.78472 15.0835 5.9375C15.1391 6.09028 15.1496 6.24639 15.1152 6.40583C15.0802 6.56583 14.9932 6.70833 14.8543 6.83333L11.7918 9.47917L12.7085 13.4167C12.7502 13.5972 12.7363 13.7606 12.6668 13.9067C12.5974 14.0522 12.5002 14.1736 12.3752 14.2708C12.2502 14.3681 12.1043 14.4236 11.9377 14.4375C11.771 14.4514 11.6113 14.4097 11.4585 14.3125L8.00018 12.2292Z"
      fill="#FFED00"
    />
    <path
      v-else
      d="M5.37518 11.8542L8.00018 10.2708L10.6252 11.875L9.93768 8.875L12.2502 6.875L9.20851 6.60417L8.00018 3.77083L6.79185 6.58333L3.75018 6.85417L6.06268 8.875L5.37518 11.8542ZM8.00018 12.2292L4.54185 14.3125C4.38907 14.4097 4.22935 14.4514 4.06268 14.4375C3.89601 14.4236 3.75018 14.3681 3.62518 14.2708C3.50018 14.1736 3.40296 14.0522 3.33351 13.9067C3.26407 13.7606 3.25018 13.5972 3.29185 13.4167L4.20851 9.47917L1.14601 6.83333C1.00713 6.70833 0.920459 6.56583 0.886014 6.40583C0.851014 6.24639 0.861292 6.09028 0.916847 5.9375C0.972403 5.78472 1.05574 5.65972 1.16685 5.5625C1.27796 5.46528 1.43074 5.40278 1.62518 5.375L5.66685 5.02083L7.22935 1.3125C7.29879 1.14583 7.40657 1.02083 7.55268 0.9375C7.69824 0.854167 7.8474 0.8125 8.00018 0.8125C8.15296 0.8125 8.3024 0.854167 8.44851 0.9375C8.59407 1.02083 8.70157 1.14583 8.77101 1.3125L10.3335 5.02083L14.3752 5.375C14.5696 5.40278 14.7224 5.46528 14.8335 5.5625C14.9446 5.65972 15.028 5.78472 15.0835 5.9375C15.1391 6.09028 15.1496 6.24639 15.1152 6.40583C15.0802 6.56583 14.9932 6.70833 14.8543 6.83333L11.7918 9.47917L12.7085 13.4167C12.7502 13.5972 12.7363 13.7606 12.6668 13.9067C12.5974 14.0522 12.5002 14.1736 12.3752 14.2708C12.2502 14.3681 12.1043 14.4236 11.9377 14.4375C11.771 14.4514 11.6113 14.4097 11.4585 14.3125L8.00018 12.2292Z"
      fill="#FFED00"
    />
  </svg>
</template>

<script>
export default {
  name: "IconStar",

  props: {
    isFilled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
