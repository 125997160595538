<template>
  <svg
    width="160"
    height="160"
    viewBox="0 0 160 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M66.8749 104.271L79.9999 96.3542L93.1249 104.375L89.6874 89.375L101.25 79.375L86.0416 78.0208L79.9999 63.8542L73.9583 77.9167L58.7499 79.2708L70.3124 89.375L66.8749 104.271ZM79.9999 106.146L62.7083 116.562C61.9444 117.049 61.1458 117.257 60.3124 117.187C59.4791 117.118 58.7499 116.84 58.1249 116.354C57.4999 115.868 57.0138 115.261 56.6666 114.533C56.3194 113.803 56.2499 112.986 56.4583 112.083L61.0416 92.3958L45.7291 79.1667C45.0346 78.5417 44.6013 77.8292 44.4291 77.0292C44.2541 76.2319 44.3055 75.4514 44.5833 74.6875C44.861 73.9236 45.2777 73.2986 45.8333 72.8125C46.3888 72.3264 47.1527 72.0139 48.1249 71.875L68.3333 70.1042L76.1458 51.5625C76.493 50.7292 77.0319 50.1042 77.7624 49.6875C78.4902 49.2708 79.236 49.0625 79.9999 49.0625C80.7638 49.0625 81.511 49.2708 82.2416 49.6875C82.9694 50.1042 83.5069 50.7292 83.8541 51.5625L91.6666 70.1042L111.875 71.875C112.847 72.0139 113.611 72.3264 114.167 72.8125C114.722 73.2986 115.139 73.9236 115.417 74.6875C115.694 75.4514 115.747 76.2319 115.575 77.0292C115.4 77.8292 114.965 78.5417 114.271 79.1667L98.9583 92.3958L103.542 112.083C103.75 112.986 103.68 113.803 103.333 114.533C102.986 115.261 102.5 115.868 101.875 116.354C101.25 116.84 100.521 117.118 99.6874 117.187C98.8541 117.257 98.0555 117.049 97.2916 116.562L79.9999 106.146Z"
      fill="#F18E35"
    />
    <path
      d="M24.7499 126.708L29.9999 123.542L35.2499 126.75L33.8749 120.75L38.4999 116.75L32.4165 116.208L29.9999 110.542L27.5832 116.167L21.4999 116.708L26.1249 120.75L24.7499 126.708ZM29.9999 127.458L23.0832 131.625C22.7777 131.819 22.4582 131.903 22.1249 131.875C21.7915 131.847 21.4999 131.736 21.2499 131.542C20.9999 131.347 20.8054 131.104 20.6665 130.813C20.5277 130.521 20.4999 130.194 20.5832 129.833L22.4165 121.958L16.2915 116.667C16.0138 116.417 15.8404 116.132 15.7715 115.812C15.7015 115.493 15.7221 115.181 15.8332 114.875C15.9443 114.569 16.111 114.319 16.3332 114.125C16.5554 113.931 16.861 113.806 17.2499 113.75L25.3332 113.042L28.4582 105.625C28.5971 105.292 28.8127 105.042 29.1049 104.875C29.396 104.708 29.6943 104.625 29.9999 104.625C30.3054 104.625 30.6043 104.708 30.8965 104.875C31.1877 105.042 31.4027 105.292 31.5415 105.625L34.6665 113.042L42.7499 113.75C43.1388 113.806 43.4443 113.931 43.6665 114.125C43.8888 114.319 44.0554 114.569 44.1665 114.875C44.2777 115.181 44.2988 115.493 44.2299 115.812C44.1599 116.132 43.986 116.417 43.7082 116.667L37.5832 121.958L39.4165 129.833C39.4999 130.194 39.4721 130.521 39.3332 130.813C39.1943 131.104 38.9999 131.347 38.7499 131.542C38.4999 131.736 38.2082 131.847 37.8749 131.875C37.5415 131.903 37.2221 131.819 36.9165 131.625L29.9999 127.458Z"
      fill="#F18E35"
    />
    <path
      d="M114.438 46.1354L121 42.1771L127.563 46.1875L125.844 38.6875L131.625 33.6875L124.021 33.0104L121 25.9271L117.979 32.9583L110.375 33.6354L116.156 38.6875L114.438 46.1354ZM121 47.0729L112.354 52.2812C111.972 52.5243 111.573 52.6285 111.156 52.5937C110.74 52.559 110.375 52.4201 110.063 52.1771C109.75 51.934 109.507 51.6306 109.334 51.2667C109.16 50.9014 109.125 50.4931 109.229 50.0417L111.521 40.1979L103.865 33.5833C103.518 33.2708 103.301 32.9146 103.215 32.5146C103.127 32.116 103.153 31.7257 103.292 31.3438C103.431 30.9618 103.639 30.6493 103.917 30.4062C104.195 30.1632 104.577 30.0069 105.063 29.9375L115.167 29.0521L119.073 19.7813C119.247 19.3646 119.516 19.0521 119.881 18.8438C120.245 18.6354 120.618 18.5312 121 18.5312C121.382 18.5312 121.756 18.6354 122.121 18.8438C122.485 19.0521 122.754 19.3646 122.927 19.7813L126.834 29.0521L136.938 29.9375C137.424 30.0069 137.806 30.1632 138.084 30.4062C138.361 30.6493 138.57 30.9618 138.709 31.3438C138.847 31.7257 138.874 32.116 138.788 32.5146C138.7 32.9146 138.483 33.2708 138.136 33.5833L130.479 40.1979L132.771 50.0417C132.875 50.4931 132.84 50.9014 132.667 51.2667C132.493 51.6306 132.25 51.934 131.938 52.1771C131.625 52.4201 131.261 52.559 130.844 52.5937C130.427 52.6285 130.028 52.5243 129.646 52.2812L121 47.0729Z"
      fill="#F18E35"
    />
    <path
      d="M41.3752 43.8542L44.0002 42.2708L46.6252 43.875L45.9377 40.875L48.2502 38.875L45.2085 38.6042L44.0002 35.7708L42.7918 38.5833L39.7502 38.8542L42.0627 40.875L41.3752 43.8542ZM44.0002 44.2292L40.5418 46.3125C40.3891 46.4097 40.2293 46.4514 40.0627 46.4375C39.896 46.4236 39.7502 46.3681 39.6252 46.2708C39.5002 46.1736 39.403 46.0522 39.3335 45.9067C39.2641 45.7606 39.2502 45.5972 39.2918 45.4167L40.2085 41.4792L37.146 38.8333C37.0071 38.7083 36.9205 38.5658 36.886 38.4058C36.851 38.2464 36.8613 38.0903 36.9168 37.9375C36.9724 37.7847 37.0557 37.6597 37.1668 37.5625C37.278 37.4653 37.4307 37.4028 37.6252 37.375L41.6668 37.0208L43.2293 33.3125C43.2988 33.1458 43.4066 33.0208 43.5527 32.9375C43.6982 32.8542 43.8474 32.8125 44.0002 32.8125C44.153 32.8125 44.3024 32.8542 44.4485 32.9375C44.5941 33.0208 44.7016 33.1458 44.771 33.3125L46.3335 37.0208L50.3752 37.375C50.5696 37.4028 50.7224 37.4653 50.8335 37.5625C50.9446 37.6597 51.028 37.7847 51.0835 37.9375C51.1391 38.0903 51.1496 38.2464 51.1152 38.4058C51.0802 38.5658 50.9932 38.7083 50.8543 38.8333L47.7918 41.4792L48.7085 45.4167C48.7502 45.5972 48.7363 45.7606 48.6668 45.9067C48.5974 46.0522 48.5002 46.1736 48.3752 46.2708C48.2502 46.3681 48.1043 46.4236 47.9377 46.4375C47.771 46.4514 47.6113 46.4097 47.4585 46.3125L44.0002 44.2292Z"
      fill="#F18E35"
    />
    <path
      d="M127.063 105.281L131 102.906L134.938 105.312L133.906 100.812L137.375 97.8125L132.813 97.4062L131 93.1562L129.188 97.375L124.625 97.7812L128.094 100.812L127.063 105.281ZM131 105.844L125.813 108.969C125.583 109.115 125.344 109.177 125.094 109.156C124.844 109.135 124.625 109.052 124.438 108.906C124.25 108.76 124.104 108.578 124 108.36C123.896 108.141 123.875 107.896 123.938 107.625L125.313 101.719L120.719 97.75C120.51 97.5625 120.38 97.3488 120.329 97.1087C120.276 96.8696 120.292 96.6354 120.375 96.4063C120.458 96.1771 120.583 95.9896 120.75 95.8438C120.917 95.6979 121.146 95.6042 121.438 95.5625L127.5 95.0312L129.844 89.4688C129.948 89.2188 130.11 89.0313 130.329 88.9063C130.547 88.7813 130.771 88.7188 131 88.7188C131.229 88.7188 131.453 88.7813 131.673 88.9063C131.891 89.0313 132.052 89.2188 132.156 89.4688L134.5 95.0312L140.563 95.5625C140.854 95.6042 141.083 95.6979 141.25 95.8438C141.417 95.9896 141.542 96.1771 141.625 96.4063C141.708 96.6354 141.724 96.8696 141.673 97.1087C141.62 97.3488 141.49 97.5625 141.281 97.75L136.688 101.719L138.063 107.625C138.125 107.896 138.104 108.141 138 108.36C137.896 108.578 137.75 108.76 137.563 108.906C137.375 109.052 137.156 109.135 136.906 109.156C136.656 109.177 136.417 109.115 136.188 108.969L131 105.844Z"
      fill="#F18E35"
    />
    <path
      d="M80.3752 142.854L83.0002 141.271L85.6252 142.875L84.9377 139.875L87.2502 137.875L84.2085 137.604L83.0002 134.771L81.7918 137.583L78.7502 137.854L81.0627 139.875L80.3752 142.854ZM83.0002 143.229L79.5418 145.312C79.3891 145.41 79.2293 145.451 79.0627 145.437C78.896 145.424 78.7502 145.368 78.6252 145.271C78.5002 145.174 78.403 145.052 78.3335 144.907C78.2641 144.761 78.2502 144.597 78.2918 144.417L79.2085 140.479L76.146 137.833C76.0071 137.708 75.9205 137.566 75.886 137.406C75.851 137.246 75.8613 137.09 75.9168 136.938C75.9724 136.785 76.0557 136.66 76.1668 136.562C76.278 136.465 76.4307 136.403 76.6252 136.375L80.6668 136.021L82.2293 132.313C82.2988 132.146 82.4066 132.021 82.5527 131.938C82.6982 131.854 82.8474 131.812 83.0002 131.812C83.153 131.812 83.3024 131.854 83.4485 131.938C83.5941 132.021 83.7016 132.146 83.771 132.313L85.3335 136.021L89.3752 136.375C89.5696 136.403 89.7224 136.465 89.8335 136.562C89.9446 136.66 90.028 136.785 90.0835 136.938C90.1391 137.09 90.1496 137.246 90.1152 137.406C90.0802 137.566 89.9932 137.708 89.8543 137.833L86.7918 140.479L87.7085 144.417C87.7502 144.597 87.7363 144.761 87.6668 144.907C87.5974 145.052 87.5002 145.174 87.3752 145.271C87.2502 145.368 87.1043 145.424 86.9377 145.437C86.771 145.451 86.6113 145.41 86.4585 145.312L83.0002 143.229Z"
      fill="#F18E35"
    />
  </svg>
</template>

<script>
export default {
  name: "IconSuccess",
};
</script>

<style scoped></style>
