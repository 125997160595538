<template>
  <div class="inner">
    <h2>Регистрация</h2>
    <form
      class="form"
      @submit.prevent="handleSubmit"
      @keydown.enter="handleSubmit"
    >
      <VInput
        v-model:value="name"
        class="input"
        placeholder="Имя"
        @enter="handleSubmit"
      />

      <VInput
        v-model:value="phone"
        class="input"
        placeholder="+7"
        mask="+7(###) ###-##-##"
        inputmode="numeric"
        @enter="handleSubmit"
      />

      <VInput
        v-model:value="password"
        class="input"
        type="password"
        placeholder="Пароль"
        @enter="handleSubmit"
        @input="clearError"
      />

      <span v-if="errors.password" class="error">
        {{ errors.password }}
      </span>
      <div class="buttons">
        <AsyncButton
          class="button"
          type="submit"
          :is-pending="isPending"
          :disabled="disabledButton"
        >
          Зарегистрироваться
        </AsyncButton>
      </div>
    </form>
    <IconPopupCross
      v-if="screenWidth !== 'mobile' && !isEvisoBusinessAuth"
      class="modal-cross"
      @click="$store.dispatch('closeModal')"
    />
  </div>
  <IconBack
    v-if="screenWidth !== 'mobile' && isEvisoBusinessAuth"
    class="arrow"
    @click="onArrowClickHandler"
  />
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import IconPopupCross from "@/components/icons/IconPopupCross.vue";
import { useRoute } from "vue-router";
import IconBack from "@/components/icons/IconBack.vue";
import AuthLoginModal from "@/components/modals/auth/AuthLoginModal.vue";

const store = useStore();
const route = useRoute();

const screenWidth = computed(() => {
  return store.getters.screenType;
});

const isEvisoBusinessAuth = computed(() => {
  return route.name === "auth-biz" || route.name === "auth-eviso";
});

const onArrowClickHandler = () => {
  store.dispatch("openModal", {
    value: {
      component: AuthLoginModal,
      props: null,
    },
  });
};
</script>

<script>
import AsyncButton from "@/components/ui/AsyncButton.vue";
import VInput from "@/components/ui/VInput.vue";
import { API_WITHOUT_GUARDS } from "@/assets/js/api/apiWithoutGuards";
import AuthBannedPhoneModal from "@/components/modals/auth/AuthBannedPhoneModal.vue";
import AuthPhoneCodeModal from "@/components/modals/auth/AuthPhoneCodeModal.vue";
import FailModal from "@/components/modals/FailModal.vue";

export default {
  name: "AuthRegistrationModal",

  components: {
    AsyncButton,
    VInput,
  },

  data() {
    return {
      name: "",
      password: "",
      phone: "",
      errors: {},
      isPending: false,
    };
  },

  computed: {
    disabledButton() {
      return !(this.name && this.password && this.phone);
    },
  },

  methods: {
    async handleSubmit() {
      if (this.password.length < 6) {
        this.errors.password = "Длина пароля должна быть не менее 6 символов";
        return;
      }
      try {
        this.isPending = true;
        await API_WITHOUT_GUARDS.client.register({
          name: this.name,
          password: this.password,
          phone: this.phone,
        });

        this.$store.dispatch("openModal", {
          value: {
            component: AuthPhoneCodeModal,
            props: {
              contacts: this.phone,
              phoneCodeType: "registration",
              roleType: "client",
            },
          },
        });
      } catch (e) {
        if (e.response.status === 429) {
          this.$store.dispatch("openModal", {
            value: {
              component: AuthBannedPhoneModal,
              props: {
                contacts: this.phone,
                message: e.response.data.message,
              },
            },
          });
        } else {
          this.$store.dispatch("openModal", {
            value: {
              component: FailModal,
              props: {
                error: e.response.data.message,
              },
            },
          });
        }
      } finally {
        this.isPending = false;
      }
    },
    clearError() {
      this.errors.password = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.inner {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 448px;
  margin: auto;
  padding: 32px 56px 40px 56px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;

  @media screen and (max-width: 841px) {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    box-shadow: 0 -2px 10px 0 #0000000d;
    border-radius: 20px 20px 0 0;
    padding: 32px 24px 24px 24px;
  }
}

h2 {
  margin-bottom: 24px;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #f0811e;
}

p {
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #222222;
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 32px;
}

.button {
  width: 100%;
}

.modal-cross {
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
}

.form {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.input {
  height: 40px;
  margin-top: 16px;

  &:first-child {
    margin-top: 0;
  }
}

.error {
  position: relative;
  margin-top: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #f03738;
}
</style>
