<template>
  <svg
    width="20"
    height="18"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 9.0122C0 8.45554 0.451574 8.00427 1.00862 8.00427H18.952C19.509 8.00427 19.9606 8.45554 19.9606 9.0122C19.9606 9.56887 19.509 10.0201 18.952 10.0201H1.00862C0.451574 10.0201 0 9.56887 0 9.0122Z"
      fill="#222222"
    />
    <path
      d="M10.2951 17.7048C9.90118 17.3112 9.90118 16.673 10.2951 16.2793L18.2782 8.30165C18.6721 7.90802 19.3107 7.90802 19.7046 8.30165C20.0985 8.69527 20.0985 9.33346 19.7046 9.72708L11.7215 17.7048C11.3276 18.0984 10.689 18.0984 10.2951 17.7048Z"
      fill="#222222"
    />
    <path
      d="M10.2807 0.295217C10.6746 -0.0984057 11.3132 -0.0984057 11.7071 0.295217L19.6902 8.27292C20.0841 8.66654 20.0841 9.30473 19.6902 9.69835C19.2964 10.092 18.6577 10.092 18.2638 9.69835L10.2807 1.72065C9.88684 1.32703 9.88684 0.68884 10.2807 0.295217Z"
      fill="#222222"
    />
  </svg>
</template>

<script>
export default {
  name: "IconArrowSwiper",
};
</script>

<style scoped></style>
