<template>
  <div class="inner" @click.stop>
    <div
      style="--swiper-navigation-color: #fff; --swiper-pagination-color: #fff"
      class="swiper"
      ref="swiper"
    >
      <div v-if="images.urls" class="swiper-wrapper">
        <div
          v-for="(image, index) in images.urls"
          :key="index"
          class="swiper-slide"
          :class="{ _mobile: screenWidth === 'mobile' }"
        >
          <img :src="image" loading="lazy" />
          <div class="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
        </div>
      </div>
      <div v-if="screenWidth !== 'mobile'" ref="next" class="button-next">
        <IconArrowSwiper />
      </div>
      <div v-if="screenWidth !== 'mobile'" ref="prev" class="button-prev">
        <IconArrowSwiper />
      </div>
    </div>
  </div>
  <div
    v-if="screenWidth !== 'mobile'"
    class="cross"
    @click.stop="onCrossClickHandler"
  >
    <IconPopupCross />
  </div>
  <IconPopupCross
    v-else
    color="#fff"
    class="cross-mobile"
    @click.stop="onCrossClickHandler"
  />
</template>

<script>
import Swiper, { Navigation } from "swiper";
import IconArrowSwiper from "@/components/icons/IconArrowSwiper.vue";
import "swiper/css/bundle";
import IconPopupCross from "@/components/icons/IconPopupCross.vue";

export default {
  name: "SwiperModal",
  components: { IconPopupCross, IconArrowSwiper },

  props: {
    images: {
      type: Object,
      default: () => ({}),
    },
    prevModal: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      swiper: null,
    };
  },

  computed: {
    screenWidth() {
      return this.$store.getters.screenType;
    },
  },

  methods: {
    onCrossClickHandler() {
      if (this.prevModal) {
        this.$store.dispatch("openModal", {
          value: {
            component: this.prevModal.type,
            props: {
              review: this.prevModal.value,
            },
          },
        });
      } else {
        this.$store.dispatch("closeModal");
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.swiper = new Swiper(this.$refs.swiper, {
        modules: [Navigation],
        lazy: true,
        loop: true,
        initialSlide: this.images.initIndex,
        navigation: {
          nextEl: this.$refs.next,
          prevEl: this.$refs.prev,
        },
      });
    });
  },
};
</script>

<style lang="scss" scoped>
.inner {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;

  @media screen and (max-width: 841px) {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    box-shadow: 0 -2px 10px 0 #0000000d;
  }
}

.swiper {
  width: 100%;
  height: 100vh;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: rgba(34, 34, 34, 0.7);

  &._mobile {
    background-color: #000000;
  }
}

.swiper-slide img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  left: 50%;
  top: 50%;
  user-select: none;
}

.button-next {
  position: absolute;
  top: 50%;
  right: 60px;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.button-prev {
  position: absolute;
  top: 50%;
  left: 60px;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  transform: rotate(180deg);
}

.swiper-button-lock {
  display: none;
}

.arrow {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 200003;
  cursor: pointer;
}

.cross {
  position: absolute;
  top: 32px;
  right: 32px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  cursor: pointer;
  z-index: 200003;
}

.cross-mobile {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}
</style>
