<template>
  <svg
    width="21"
    height="19"
    viewBox="0 0 21 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.8281 9.8462C20.8281 10.4029 20.3766 10.8541 19.8195 10.8541L1.87615 10.8541C1.31911 10.8541 0.867537 10.4029 0.867537 9.8462C0.867537 9.28953 1.31911 8.83826 1.87615 8.83826L19.8195 8.83826C20.3766 8.83826 20.8281 9.28953 20.8281 9.8462Z"
      :fill="color"
    />
    <path
      d="M10.5331 1.15362C10.9269 1.54724 10.9269 2.18543 10.5331 2.57905L2.54994 10.5568C2.15606 10.9504 1.51743 10.9504 1.12354 10.5568C0.729654 10.1631 0.729654 9.52494 1.12354 9.13132L9.10665 1.15362C9.50054 0.759993 10.1392 0.759993 10.5331 1.15362Z"
      :fill="color"
    />
    <path
      d="M10.5474 18.5632C10.1535 18.9568 9.51488 18.9568 9.12099 18.5632L1.13788 10.5855C0.74399 10.1919 0.74399 9.55367 1.13788 9.16005C1.53177 8.76642 2.17039 8.76642 2.56428 9.16005L10.5474 17.1377C10.9413 17.5314 10.9413 18.1696 10.5474 18.5632Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "IconArrowBack",
  props: {
    color: {
      default: "#222222",
    },
  },
};
</script>

<style scoped></style>
