<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.1878 12.354C11.3406 12.5068 11.5281 12.5832 11.7503 12.5832C11.9725 12.5832 12.167 12.4998 12.3337 12.3332C12.4864 12.1804 12.5628 11.9859 12.5628 11.7498C12.5628 11.5137 12.4864 11.3193 12.3337 11.1665L9.83366 8.6665V5.64567C9.83366 5.40956 9.75394 5.21512 9.59449 5.06234C9.43449 4.90956 9.23644 4.83317 9.00033 4.83317C8.76421 4.83317 8.56644 4.91289 8.40699 5.07234C8.24699 5.23234 8.16699 5.43039 8.16699 5.6665V8.979C8.16699 9.09012 8.18783 9.19762 8.22949 9.30151C8.27116 9.40595 8.33366 9.49984 8.41699 9.58317L11.1878 12.354ZM9.00033 17.3332C7.84755 17.3332 6.76421 17.1143 5.75033 16.6765C4.73644 16.2393 3.85449 15.6457 3.10449 14.8957C2.35449 14.1457 1.76088 13.2637 1.32366 12.2498C0.885881 11.2359 0.666992 10.1526 0.666992 8.99984C0.666992 7.84706 0.885881 6.76373 1.32366 5.74984C1.76088 4.73595 2.35449 3.854 3.10449 3.104C3.85449 2.354 4.73644 1.76012 5.75033 1.32234C6.76421 0.885115 7.84755 0.666504 9.00033 0.666504C10.1531 0.666504 11.2364 0.885115 12.2503 1.32234C13.2642 1.76012 14.1462 2.354 14.8962 3.104C15.6462 3.854 16.2398 4.73595 16.677 5.74984C17.1148 6.76373 17.3337 7.84706 17.3337 8.99984C17.3337 10.1526 17.1148 11.2359 16.677 12.2498C16.2398 13.2637 15.6462 14.1457 14.8962 14.8957C14.1462 15.6457 13.2642 16.2393 12.2503 16.6765C11.2364 17.1143 10.1531 17.3332 9.00033 17.3332ZM9.00033 15.6665C10.8475 15.6665 12.4206 15.0173 13.7195 13.719C15.0178 12.4201 15.667 10.8471 15.667 8.99984C15.667 7.15262 15.0178 5.57956 13.7195 4.28067C12.4206 2.98234 10.8475 2.33317 9.00033 2.33317C7.1531 2.33317 5.58033 2.98234 4.28199 4.28067C2.9831 5.57956 2.33366 7.15262 2.33366 8.99984C2.33366 10.8471 2.9831 12.4201 4.28199 13.719C5.58033 15.0173 7.1531 15.6665 9.00033 15.6665Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "IconWatch",
  props: {
    color: {
      type: String,
      default: "#F18E35",
    },
  },
};
</script>

<style scoped></style>
