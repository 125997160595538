<template>
  <svg
    width="96"
    height="96"
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_1155_35450)">
      <rect
        x="16"
        y="12"
        width="64"
        height="64"
        rx="32"
        fill="#222222"
        fill-opacity="0.2"
        shape-rendering="crispEdges"
      />
      <path
        d="M57.8291 43.8461C57.8291 44.4028 57.3775 44.8541 56.8205 44.8541H38.8771C38.3201 44.8541 37.8685 44.4028 37.8685 43.8461C37.8685 43.2895 38.3201 42.8382 38.8771 42.8382H56.8205C57.3775 42.8382 57.8291 43.2895 57.8291 43.8461Z"
        fill="white"
      />
      <path
        d="M47.534 35.1536C47.9279 35.5472 47.9279 36.1854 47.534 36.579L39.5509 44.5567C39.157 44.9503 38.5184 44.9503 38.1245 44.5567C37.7306 44.1631 37.7306 43.5249 38.1245 43.1313L46.1076 35.1536C46.5015 34.7599 47.1401 34.7599 47.534 35.1536Z"
        fill="white"
      />
      <path
        d="M47.5484 52.5631C47.1545 52.9567 46.5159 52.9567 46.122 52.5631L38.1389 44.5854C37.745 44.1918 37.745 43.5536 38.1389 43.16C38.5327 42.7664 39.1714 42.7664 39.5653 43.16L47.5484 51.1377C47.9423 51.5313 47.9423 52.1695 47.5484 52.5631Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1155_35450"
        x="0"
        y="0"
        width="96"
        height="96"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="8" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_1155_35450"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1155_35450"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "IconBack",
};
</script>

<style scoped></style>
