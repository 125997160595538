<template>
  <header>
    <div
      v-if="isMenu && isAuth"
      class="hamburger-lines"
      @click="onMenuClickHandler"
    >
      <span class="line line1"></span>
      <span class="line line2"></span>
      <span class="line line3"></span>
    </div>
    <LogoIcon @click="onLogoClickHandler" class="logo" />

    <div class="time">
      <IconBigWatch />
      <span> {{ time }} </span>
    </div>

    <div class="arrow">
      <IconArrowBack @click="arrowClickHandler" color="#F18E35" />
      <span>{{ cateringName }}</span>
    </div>

    <div class="toggle">
      <div class="wrapper">
        <div
          :class="{
            _active: page === 'catering/business-booking/:catId/seating-map',
          }"
          @click="
            $router.push(
              `/account/catering/business-booking/${$route.params.catId}/seating-map`
            )
          "
        >
          Карта посадки
        </div>
        <div
          :class="{
            _active: page === 'catering/business-booking/:catId/reservations',
          }"
          @click="
            $router.push(
              `/account/catering/business-booking/${$route.params.catId}/reservations`
            )
          "
        >
          <span>Список броней</span>
          <div class="circle">{{ bookingItemsCount }}</div>
        </div>
      </div>
    </div>

    <div v-if="!isAuth" class="auth-buttons">
      <VButton small @click="onLoginClickHandler"> Войти </VButton>
      <VButton small @click="onRegistrationClickHandler" outline>
        Регистрация
      </VButton>
    </div>
    <div v-else class="account">
      <VButton class="button-booking" @click="createBooking" large>
        <template #leftAddon><IconPlus /></template>
        <template #default>Новое бронирование</template>
      </VButton>
      <div
        v-if="role === 'CLIENT' && screenWidth !== 'mobile'"
        class="location"
      >
        <IconLocation />
        <span>Екатеринбург</span>
      </div>
      <div class="ring">
        <IconRing class="svg-ring" @click="onRingClickHandler" />
        <div class="ring_hasItems" v-if="unreadItems">
          <span v-if="unreadItems < 10">{{ unreadItems }}</span>
        </div>
      </div>
      <span v-if="screenWidth === 'desktop'" class="name">{{ name }}</span>
      <VImage
        :src="avatar || defaultAvatar"
        ref="avatar"
        class="avatar"
        @click="onAvatarClickHandler"
      />
      <HeaderTooltip
        v-if="isAccountTooltip"
        @close="isAccountTooltip = false"
      />
    </div>
    <SingleNoticeList class="notices" />
  </header>
</template>

<script>
import defaultAvatar from "@/assets/images/defaultAvatar.png";
import SingleNoticeList from "@/components/notice/SingleNoticeList.vue";
import HeaderTooltip from "@/components/ui/header/HeaderTooltip.vue";
import VImage from "@/components/ui/VImage.vue";
import IconRing from "@/components/icons/IconRing.vue";
import IconLocation from "@/components/icons/IconLocation.vue";
import VButton from "@/components/ui/VButton.vue";
import AuthLoginModal from "@/components/modals/auth/AuthLoginModal.vue";
import AuthRegistrationModal from "@/components/modals/auth/AuthRegistrationModal.vue";
import LogoIcon from "@/components/icons/LogoIcon.vue";
import IconBigWatch from "@/components/icons/IconBigWatch.vue";
import IconArrowBack from "@/components/icons/IconArrowBack.vue";
import IconPlus from "@/components/icons/IconPlus.vue";
import router from "@/router";
import { API_WITHOUT_GUARDS } from "@/assets/js/api/apiWithoutGuards";
import { API } from "@/assets/js/api/api";

export default {
  name: "HeaderReservations",
  components: {
    IconPlus,
    IconArrowBack,
    IconBigWatch,
    LogoIcon,
    VButton,
    IconLocation,
    IconRing,
    VImage,
    HeaderTooltip,
    SingleNoticeList,
  },

  data() {
    return {
      time: "",
      cateringName: "",
      isAccountTooltip: false,
      defaultAvatar,
      timerInterval: null,
      refreshBookings: null,
      items: [],
    };
  },

  methods: {
    arrowClickHandler() {
      router.push(`/account/catering/business-booking`);
    },
    createBooking() {
      router.push(`/booking/${this.$route.params.catId}`);
    },
    onRingClickHandler() {
      this.$store.commit("toggleNotices");
    },
    onMenuClickHandler() {
      this.$store.commit("toggleMenu");
    },
    onLoginClickHandler() {
      this.$store.dispatch("openModal", {
        value: {
          component: AuthLoginModal,
        },
      });
    },
    onRegistrationClickHandler() {
      this.$store.dispatch("openModal", {
        value: {
          component: AuthRegistrationModal,
        },
      });
    },
    onLogoClickHandler() {
      this.$router.push("/");
    },
    onAvatarClickHandler() {
      if (!this.isAccountTooltip) {
        this.isAccountTooltip = true;
      }
    },
  },
  async mounted() {
    this.time = new Date().toLocaleString("ru", {
      timeZone: "Asia/Yekaterinburg",
      hour: "2-digit",
      minute: "2-digit",
    });
    const { data } = await API_WITHOUT_GUARDS.client.getCateringByID(
      this.$route.params.catId
    );
    this.cateringName = data.name;
    this.timerInterval = setInterval(async () => {
      this.time = new Date().toLocaleString("ru", {
        timeZone: "Asia/Yekaterinburg",
        hour: "2-digit",
        minute: "2-digit",
      });
    }, 30000);

    try {
      const res = await API.booking.getBookings({
        catId: this.$route.params.catId,
        all: false,
      });
      this.items = res.data;
    } catch (e) {
      console.log(e);
    }

    this.refreshBookings = setInterval(async () => {
      try {
        const res = await API.booking.getBookings({
          catId: this.$route.params.catId,
          all: false,
        });
        this.items = res.data;
      } catch (e) {
        console.log(e);
      }
    }, 10000);
  },
  beforeUnmount() {
    clearInterval(this.refreshBookings);
  },
  computed: {
    isMenu() {
      return this.screenWidth !== "desktop";
    },
    screenWidth() {
      return this.$store.getters.screenType;
    },
    isAuth() {
      return this.$store.state.general.auth.isAuth;
    },
    name() {
      return this.$store.state.general.userInfo?.name;
    },
    role() {
      return this.$store.getters.role;
    },
    page() {
      return this.$route.name;
    },
    roleGroup() {
      return this.$store.getters.roleGroup;
    },
    avatar() {
      return this.$store.state.general.userInfo?.avatar;
    },
    unreadItems() {
      return this.$store.state.notices.unreadItems.length;
    },
    bookingItemsCount() {
      let result = this.items || [];

      let currentRecentDate = new Date();

      currentRecentDate = new Date(
        currentRecentDate.setMinutes(currentRecentDate.getMinutes() - 1440)
      );

      let passedResult = [];

      passedResult = result
        ?.filter((el) => {
          const visitDate = new Date(el.visitDate);
          const hours = Math.floor(el.visitTime / 100);
          const minutes = el.visitTime % 100;
          visitDate.setMinutes(minutes);
          visitDate.setHours(hours - 5 + -new Date().getTimezoneOffset() / 60);
          return visitDate >= currentRecentDate && visitDate < new Date();
        })
        .map((el) => ({ ...el, passed: true }));

      result = result.filter((el) => {
        const visitDate = new Date(el.visitDate);
        const hours = Math.floor(el.visitTime / 100);
        const minutes = el.visitTime % 100;
        visitDate.setMinutes(minutes);
        visitDate.setHours(hours - 5 + -new Date().getTimezoneOffset() / 60);
        return visitDate >= new Date();
      });

      return [...passedResult, ...result].filter((el) => el.statusId === 1)
        .length;
    },
  },
};
</script>

<style scoped lang="scss">
header {
  display: flex;
  align-items: center;
  position: relative;
  height: 68px;
  padding: 10px 24px;
  background: #ffffff;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
  z-index: 11111111;

  @include mobile {
    padding-right: 16px;
  }
}

.account {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: auto;
  user-select: none;
  z-index: 111111;
}

.ring {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 24px;
  cursor: pointer;

  @include tablet {
    margin-right: 16px;
  }

  &_hasItems {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    transform: translate(50%, -50%);
    border-radius: 50%;
    background-color: #f18e35;

    span {
      color: #ffffff;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
  }
}

.avatar {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  cursor: pointer;
}

.location {
  display: flex;
  align-items: center;
  margin-right: 24px;

  @include tablet {
    margin-right: 16px;
  }

  svg {
    margin-right: 4px;
  }

  span {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-decoration-line: underline;
  }
}

.name {
  display: flex;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  margin-right: 16px;
}

.avatar {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  cursor: pointer;
}

.logo {
  display: flex;
  width: 70px;
  height: 40px;
  cursor: pointer;
}

.auth-buttons {
  margin-left: auto;
  z-index: 1;

  button:first-child {
    margin-right: 8px;
  }
}

.hamburger-lines {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 12px;
  margin: 0 12px 0 4px;
}

.hamburger-lines .line {
  display: block;
  height: 2px;
  width: 18px;
  border-radius: 10px;
  background: #0e2431;
}

.rubble {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-bottom: 16px;
  background-color: #f18e35;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background: linear-gradient(
      147.26deg,
      #ffffff 39.95%,
      rgba(255, 255, 255, 0) 115.22%
    );
  }

  svg {
    height: 16px;
    width: 16px;
  }
}

.sum {
  padding: 9px 24px 9px 16px;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  color: #f18e35;
}

.time {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 148px;
  height: 48px;
  margin-left: 24px;
  background-color: #fef6e8;
  gap: 10px;
  border-radius: 16px;

  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  color: #f18e35;

  svg {
    height: 26px;
    width: 26px;
  }
}

.arrow {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;

  margin-left: 24px;

  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  color: #222222;

  svg {
    cursor: pointer;
  }
}

.button-booking {
  height: 48px;
  width: 256px;
  margin-right: 24px;
}

.toggle {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 68px;
  padding: 6px 0;

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fef6e8;
    gap: 8px;
    padding: 8px;
    border-radius: 100px;

    width: 312px;
    height: 56px;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      height: 38px;
      width: 164px;
      background-color: #fef6e8;

      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      color: #f18e35;
      border-radius: 100px;
      cursor: pointer;

      &:first-child {
        width: 124px;
      }

      .circle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        border-radius: 50px;
        background-color: #f18e35;
        color: #fff;

        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
      }

      &._active {
        background-color: #f18e35;
        color: #fff;

        .circle {
          background-color: #fff;
          color: #f18e35;
        }
      }
    }

    //div:first-child {
    //  width: 124px;
    //}
  }
}
</style>
