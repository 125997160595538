<template>
  <header>
    <LogoIcon @click="onLogoClickHandler" class="logo" />

    <div class="buttons-wrapper">
      <div class="buttons">
        <BookingDropdown
          class="dropdown"
          :value="floor + 1"
          :options="floorOptions"
          @updated="changeOptions($event)"
          :floorsLength="floors.length"
          large
        />
        <AcceptModalButton
          acceptButtonText="Да"
          cancelButtonText="Отменить"
          title="Очистить всё?"
          @accept="resetScheme"
          v-slot="scope"
        >
          <VButton @click="scope.open" outline large gray>Очистить всё</VButton>
        </AcceptModalButton>
      </div>
      <div class="buttons">
        <VButton @click="saveScheme" large>Сохранить</VButton>
      </div>
    </div>
    <div class="title">{{ cateringName }}</div>
  </header>
  <SingleNoticeList />
</template>

<script>
import SingleNoticeList from "@/components/notice/SingleNoticeList.vue";
import VButton from "@/components/ui/VButton.vue";
import BookingDropdown from "@/components/pages/booking/BookingDropdown.vue";
import { API } from "@/assets/js/api/api";
import {
  createTablesForBooking,
  FLOOR_LABELS,
} from "@/assets/js/utils/bookingUtils";
import { API_WITHOUT_GUARDS } from "@/assets/js/api/apiWithoutGuards";
import AcceptModalButton from "@/components/modals/AcceptModalButton.vue";
import LogoIcon from "@/components/icons/LogoIcon.vue";

export default {
  name: "HeaderSchemeEditor",
  components: {
    LogoIcon,
    AcceptModalButton,
    BookingDropdown,
    VButton,
    SingleNoticeList,
  },

  data() {
    return {
      cateringName: "",
    };
  },

  methods: {
    resetScheme() {
      this.$store.commit({ type: "resetScheme" });
    },
    async saveScheme() {
      if (this.$store.state.booking.new) {
        try {
          if (this.roleGroup === "org") {
            await API.scheme.createSchemeByEviso({
              catId: this.cateringId,
              floors: this.floors,
              tables: createTablesForBooking(this.floors),
            });
          } else {
            await API.scheme.createSchemeByMajor({
              catId: this.cateringId,
              floors: this.floors,
              tables: createTablesForBooking(this.floors),
            });
          }
          this.$router.push("/account/catering");
        } catch (e) {
          console.log(e);
        }
      } else {
        try {
          if (this.roleGroup === "org") {
            await API.scheme.updateSchemeByEviso({
              catId: this.cateringId,
              floors: this.floors,
              tables: createTablesForBooking(this.floors),
            });
          } else {
            await API.scheme.updateSchemeByMajor({
              catId: this.cateringId,
              floors: this.floors,
              tables: createTablesForBooking(this.floors),
            });
          }
          this.$router.push("/account/catering");
        } catch (e) {
          console.log(e);
        }
      }
    },
    changeOptions(changedOption) {
      this.$store.commit({
        type: "setFloor",
        value: changedOption - 1,
      });
    },
    onLogoClickHandler() {
      this.$router.push("/");
    },
  },

  computed: {
    cateringId() {
      return this.$route.params.catId;
    },
    role() {
      return this.$store.getters.role;
    },
    roleGroup() {
      return this.$store.getters.roleGroup;
    },
    floor() {
      return this.$store.state.booking.floor;
    },
    floors() {
      return this.$store.state.booking.floors;
    },
    floorOptions() {
      const arr = Array(this.floors.length).fill({ label: "", value: "" });
      return arr.map((el, index) => ({
        label: FLOOR_LABELS[index + 1],
        value: index + 1,
      }));
    },
  },

  async mounted() {
    try {
      const catering = await API_WITHOUT_GUARDS.client.getCateringByID(
        this.cateringId
      );
      this.cateringName = catering.data.name;
    } catch (e) {
      console.log(e);
    }
  },
};
</script>

<style scoped lang="scss">
header {
  display: flex;
  align-items: center;
  position: relative;
  height: 68px;
  padding: 8px 24px;
  background: #ffffff;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);

  @include mobile {
    padding-right: 16px;
  }
}

.buttons {
  display: flex;
  div:first-child {
    margin-right: 16px;
  }
}

.buttons-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.dropdown {
  width: 182px;
}

.logo {
  width: 70px;
  height: 40px;
  margin-right: 24px;
  cursor: pointer;
}

.title {
  position: absolute;
  top: 18px;
  left: 50%;
  transform: translateX(-50%);

  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}
</style>
