import mem from "mem";
import axios from "axios";
import store from "@/store";
import jwt_decode from "jwt-decode";

const axiosPublic = axios.create({
  baseURL: "/api/v1/",
  headers: {
    "Content-Type": "application/json",
  },
});

const refreshTokenFn = async () => {
  if (!localStorage.getItem("refreshToken")) {
    return;
  }

  const refreshToken = localStorage.getItem("refreshToken");
  const accessToken = localStorage.getItem("accessToken");
  const decoded = jwt_decode(accessToken);
  let url;

  if (
    decoded.realm_access.roles.includes("ROOT") ||
    decoded.realm_access.roles.includes("ADMIN") ||
    decoded.realm_access.roles.includes("HEAD") ||
    decoded.realm_access.roles.includes("MANAGER") ||
    decoded.realm_access.roles.includes("MODERATOR") ||
    decoded.realm_access.roles.includes("SECRETARY")
  ) {
    url = "eviso/refresh";
  } else if (
    decoded.realm_access.roles.includes("MAJOR") ||
    decoded.realm_access.roles.includes("HOSTESS") ||
    decoded.realm_access.roles.includes("MARKETER")
  ) {
    url = "biz/refresh";
  } else {
    url = "client/refresh";
  }

  try {
    const response = await axiosPublic.post(url, {
      refreshToken,
    });

    const session = response.data;

    if (!session.accessToken || !session.refreshToken) {
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("accessToken");
    }

    localStorage.setItem("refreshToken", session.refreshToken);
    localStorage.setItem("accessToken", session.accessToken);

    return session;
  } catch (error) {
    store.dispatch("setLogin", { value: false });
  }
};

export const memoizedRefreshToken = mem(refreshTokenFn, {
  maxAge: 10000,
});
