<template>
  <svg
    width="10"
    height="10"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.278438 1.62274C-0.0927838 1.25151 -0.092784 0.649641 0.278438 0.278418C0.64966 -0.0928043 1.25153 -0.0928043 1.62275 0.278418L13.7216 12.3773C14.0928 12.7485 14.0928 13.3504 13.7216 13.7216C13.3504 14.0928 12.7485 14.0928 12.3773 13.7216L0.278438 1.62274Z"
      fill="#ADADAD"
    />
    <path
      d="M12.3772 0.278417C12.7485 -0.0928056 13.3503 -0.0928058 13.7216 0.278417C14.0928 0.64964 14.0928 1.25151 13.7216 1.62273L1.62273 13.7216C1.25151 14.0928 0.649639 14.0928 0.278417 13.7216C-0.0928055 13.3504 -0.0928055 12.7485 0.278417 12.3773L12.3772 0.278417Z"
      fill="#ADADAD"
    />
  </svg>
</template>

<script>
export default {
  name: "IconCloseInput",
};
</script>

<style scoped></style>
