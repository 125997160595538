<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.00033 17.3332C7.84755 17.3332 6.76421 17.1143 5.75033 16.6765C4.73644 16.2393 3.85449 15.6457 3.10449 14.8957C2.35449 14.1457 1.76088 13.2637 1.32366 12.2498C0.885881 11.2359 0.666992 10.1526 0.666992 8.99984C0.666992 7.84706 0.885881 6.76373 1.32366 5.74984C1.76088 4.73595 2.35449 3.854 3.10449 3.104C3.85449 2.354 4.73644 1.76012 5.75033 1.32234C6.76421 0.885115 7.84755 0.666504 9.00033 0.666504C10.1531 0.666504 11.2364 0.885115 12.2503 1.32234C13.2642 1.76012 14.1462 2.354 14.8962 3.104C15.6462 3.854 16.2398 4.73595 16.677 5.74984C17.1148 6.76373 17.3337 7.84706 17.3337 8.99984C17.3337 10.1526 17.1148 11.2359 16.677 12.2498C16.2398 13.2637 15.6462 14.1457 14.8962 14.8957C14.1462 15.6457 13.2642 16.2393 12.2503 16.6765C11.2364 17.1143 10.1531 17.3332 9.00033 17.3332ZM8.16699 15.6248V13.9998C7.70866 13.9998 7.31644 13.8368 6.99033 13.5107C6.66366 13.184 6.50033 12.7915 6.50033 12.3332V11.4998L2.50033 7.49984C2.45866 7.74984 2.42033 7.99984 2.38533 8.24984C2.35088 8.49984 2.33366 8.74984 2.33366 8.99984C2.33366 10.6804 2.88588 12.1526 3.99033 13.4165C5.09421 14.6804 6.48644 15.4165 8.16699 15.6248ZM13.917 13.4998C14.1948 13.1943 14.4448 12.8643 14.667 12.5098C14.8892 12.1559 15.0734 11.7879 15.2195 11.4057C15.365 11.024 15.4762 10.6318 15.5528 10.229C15.6289 9.82623 15.667 9.4165 15.667 8.99984C15.667 7.63873 15.2887 6.39567 14.532 5.27067C13.7748 4.14567 12.7642 3.33317 11.5003 2.83317V3.1665C11.5003 3.62484 11.3373 4.01706 11.0112 4.34317C10.6845 4.66984 10.292 4.83317 9.83366 4.83317H8.16699V6.49984C8.16699 6.73595 8.08727 6.93373 7.92783 7.09317C7.76783 7.25317 7.56977 7.33317 7.33366 7.33317H5.66699V8.99984H10.667C10.9031 8.99984 11.1012 9.07956 11.2612 9.239C11.4206 9.399 11.5003 9.59706 11.5003 9.83317V12.3332H12.3337C12.6948 12.3332 13.0212 12.4407 13.3128 12.6557C13.6045 12.8712 13.8059 13.1526 13.917 13.4998Z"
      fill="#F18E35"
    />
  </svg>
</template>

<script>
export default {
  name: "IconWeb",
};
</script>

<style scoped></style>
