<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 18C0.716667 18 0.479 17.904 0.287 17.712C0.0956668 17.5207 0 17.2833 0 17C0 16.7167 0.0956668 16.4793 0.287 16.288C0.479 16.096 0.716667 16 1 16H15C15.2833 16 15.5207 16.096 15.712 16.288C15.904 16.4793 16 16.7167 16 17C16 17.2833 15.904 17.5207 15.712 17.712C15.5207 17.904 15.2833 18 15 18H1ZM4 14C2.9 14 1.95833 13.6083 1.175 12.825C0.391667 12.0417 0 11.1 0 10V2C0 1.45 0.196 0.979 0.588 0.587C0.979333 0.195667 1.45 0 2 0H16C16.55 0 17.021 0.195667 17.413 0.587C17.8043 0.979 18 1.45 18 2V5C18 5.55 17.8043 6.02067 17.413 6.412C17.021 6.804 16.55 7 16 7H14V10C14 11.1 13.6083 12.0417 12.825 12.825C12.0417 13.6083 11.1 14 10 14H4ZM4 12H10C10.55 12 11.021 11.804 11.413 11.412C11.8043 11.0207 12 10.55 12 10V2H2V10C2 10.55 2.196 11.0207 2.588 11.412C2.97933 11.804 3.45 12 4 12ZM14 5H16V2H14V5Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "IconBooking",

  props: {
    color: {
      type: String,
      default: "#F18E35",
    },
  },
};
</script>

<style scoped></style>
