export function getDate(value, isHHMM) {
  let date = new Date(value);

  if (isHHMM) {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${date.toLocaleDateString("ru")}, ${
      hours < 10 ? "0" + hours : hours
    }:${minutes < 10 ? "0" + minutes : minutes} `;
  } else {
    return date.toLocaleDateString("ru");
  }
}

export function getSuccessDate(value) {
  let date = new Date(value);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return `${date.toLocaleDateString("ru")} в ${
    hours < 10 ? "0" + hours : hours
  }:${minutes < 10 ? "0" + minutes : minutes} `;
}

export function getTime(value) {
  let date = new Date(value);
  let time = date.toLocaleTimeString("ru");
  return time.slice(0, 5);
}

export function getDateForRequest(start, end) {
  return [
    start
      ? new Date(start).toISOString() || new Date()
      : "1970-04-01T08:37:21Z",
    end ? new Date(end).toISOString() || new Date() : new Date(),
  ];
}

export function getBrowser() {
  let userAgent = navigator.userAgent;
  let browser = "Unknown";

  if (/Chrome/.test(userAgent) && !/Chromium/.test(userAgent)) {
    browser = "Google Chrome";
  } else if (/Edg/.test(userAgent)) {
    browser = "Microsoft Edge";
  } else if (/Firefox/.test(userAgent)) {
    browser = "Mozilla Firefox";
  } else if (/Safari/.test(userAgent)) {
    browser = "Apple Safari";
  } else if (/Trident/.test(userAgent)) {
    browser = "Internet Explorer";
  }

  return browser;
}

export function convertFromNumberToTime(num) {
  const hours = Math.floor(num / 100);
  const minutes = num % 100;

  const formattedHours = hours < 10 ? "0" + hours : hours;
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

  return [formattedHours, formattedMinutes];
}

export const printPdf = (function () {
  const isFirefox = /Gecko\/\d/.test(navigator.userAgent);

  const firefoxDelay = 1000;

  let iframe;

  return function (url) {
    if (iframe) {
      iframe.parentNode.removeChild(iframe);
    }

    iframe = document.createElement("iframe");
    iframe.style.cssText =
      "width: 1px; height: 100px; position: fixed; left: 0; top: 0; opacity: 0; border-width: 0; margin: 0; padding: 0";

    const xhr = new XMLHttpRequest();
    try {
      xhr.responseType = "arraybuffer";
    } catch (e) {
      window.open(url, "_blank");
      return;
    }

    xhr.addEventListener("load", function () {
      if (xhr.status === 200 || xhr.status === 201) {
        const pdfBlob = new Blob([xhr.response], {
          type: "application/pdf",
        });
        const iframeUrl = URL.createObjectURL(pdfBlob);
        iframe.src = iframeUrl;

        iframe.addEventListener("load", function () {
          function printIframe() {
            try {
              iframe.focus();
              try {
                iframe.contentWindow.document.execCommand("print", false, null);
              } catch (e) {
                iframe.contentWindow.print();
              }
            } catch (error) {
              console.error("Print failed: " + error, error);
            } finally {
              iframe.style.visibility = "hidden";
              iframe.style.left = "-1px";
              URL.revokeObjectURL(iframeUrl);
            }
          }

          if (isFirefox) {
            window.setTimeout(printIframe, firefoxDelay);
          } else {
            printIframe();
          }
        });

        document.body.appendChild(iframe);
      }
    });

    xhr.open("GET", url, true);
    xhr.send();
  };
})();

export function formatLink(link) {
  if (link.startsWith("http")) {
    return link;
  } else {
    return "//" + link;
  }
}

export function formatPhoneNumber(phone) {
  if (!phone) {
    return "";
  }
  const digits = phone.replace(/\D/g, "");

  const normalized = digits.startsWith("8")
    ? "7" + digits.slice(1)
    : digits.startsWith("7")
    ? digits
    : "7" + digits;

  return normalized.replace(
    /^(\d)(\d{3})(\d{3})(\d{2})(\d{2})$/,
    "+$1($2) $3-$4-$5"
  );
}

export function getTodayCateringScheduleInfo(schedule, timezone) {
  if (!schedule?.workShiftStart?.weekday) {
    return [null, false];
  }

  let currentTime = +new Date()
    .toLocaleString("ru", {
      timeZone: timezone,
      hour: "2-digit",
      minute: "2-digit",
    })
    .replace(":", "");

  const isOpen =
    currentTime > schedule.workShiftStart.time &&
    currentTime < schedule.workShiftStart.weekday ===
      schedule.workShiftEnd.weekday
      ? schedule.workShiftEnd.time
      : schedule.workShiftEnd.time + 2400;

  return [schedule, isOpen];
}

export function getISOString(date) {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = "" + d.getFullYear(),
    hours = "" + d.getHours(),
    minutes = "" + d.getMinutes(),
    seconds = "00";
  month = month.length < 2 ? "0" + month : month;
  day = day.length < 2 ? "0" + day : day;
  hours = hours.length < 2 ? "0" + hours : hours;
  minutes = minutes.length < 2 ? "0" + minutes : minutes;
  return (
    [year, month, day].join("-") +
    "T" +
    [hours, minutes, seconds].join(":") +
    ".000Z"
  );
}

export function formatTime(timeInt) {
  const timeStr = String(timeInt).padStart(4, "0");
  const hours = timeStr.slice(0, 2);
  const minutes = timeStr.slice(2);
  return `${hours}:${minutes}`;
}

export function formatMinutesIntoString(val) {
  const hour = Math.floor(val / 60);
  const hourText = hour < 1 ? "" : hour + " ч ";

  const minutes = val % 60;
  const minutesText = minutes <= 0 ? "" : minutes + " мин";

  const result = `${hourText}${minutesText}`;

  return result.trim();
}
