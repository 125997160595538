<template>
  <div class="wrapper">
    <span class="notice" v-html="formattedText" /><VButton @click="close"
      >Принять</VButton
    >
  </div>
</template>

<script>
import VButton from "@/components/ui/VButton.vue";

export default {
  name: "SingleBusinessNotice",
  components: { VButton },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      timeout: null,
      formattedText: "",
    };
  },
  methods: {
    close() {
      this.$emit("close", this.data.ID);
    },
  },
  mounted() {
    this.formattedText = this.data.text.split("\n").join("<br>");
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 14px 16px 14px 24px;
  border-radius: 8px;
  background-color: #fef6e8;
  box-shadow: 0 4px 16px 0 #00000014;

  @include mobile {
    width: calc(100% - 32px);
  }
}

.notice {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #f0811e;

  & :deep(.bold) {
    font-weight: 600;
    color: #f0811e;
  }

  & :deep(.color) {
    font-weight: 600;
    color: #f0811e;
  }
}

.cross {
  position: absolute;
  top: 14px;
  right: 16px;
  transform: translateX(-50%);
}
</style>
