<template>
  <div class="inner">
    <h2>Выйти из аккаунта?</h2>
    <p>Вы уверены, что хотите выйти?</p>
    <div class="buttons">
      <VButton
        class="button"
        @click="onClickHandler"
        @keydown.enter="onClickHandler"
      >
        Выйти
      </VButton>
      <VButton class="button" @click="$store.dispatch('closeModal')" outline>
        Отменить
      </VButton>
    </div>
    <IconPopupCross
      v-if="screenWidth !== 'mobile'"
      class="modal-cross"
      @click="$store.dispatch('closeModal')"
    />
  </div>
</template>

<script>
import VButton from "@/components/ui/VButton.vue";
import IconPopupCross from "@/components/icons/IconPopupCross.vue";

export default {
  name: "LogoutModal",
  components: { IconPopupCross, VButton },

  methods: {
    onClickHandler() {
      this.$store.dispatch("logout");
      this.$store.dispatch("closeModal");
    },
  },

  computed: {
    screenWidth() {
      return this.$store.getters.screenType;
    },
  },
};
</script>

<style lang="scss" scoped>
.inner {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 448px;
  margin: auto;
  padding: 32px 56px 40px 56px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;

  @media screen and (max-width: 841px) {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    box-shadow: 0 -2px 10px 0 #0000000d;
    border-radius: 20px 20px 0 0;
    padding: 32px 24px 24px 24px;
  }
}

h2 {
  margin-bottom: 24px;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #f0811e;
}

p {
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #222222;
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 32px;
  gap: 16px;

  @include mobile {
    width: 100%;
  }
}

.button {
  width: 336px;

  @include mobile {
    width: 100%;
  }
}

.modal-cross {
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
}
</style>
