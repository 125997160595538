<template>
  <div class="container" :class="{ _big: big, _map: map }">
    <div class="icon" :class="{ _big: big, _map: map, _filled: filled }">
      <component
        :is="iconType"
        :color="map ? (filled ? '#F18E35' : '#858585') : '#F18E35'"
      />
    </div>
    <span class="text" :class="{ _big: big, _map: map }">{{ text }}</span>
  </div>
</template>

<script>
import IconBookingCalendar from "@/components/icons/booking/IconBookingCalendar.vue";
import IconBookingDuration from "@/components/icons/booking/IconBookingDuration.vue";
import IconBookingPerson from "@/components/icons/booking/IconBookingPerson.vue";
import IconBookingTime from "@/components/icons/booking/IconBookingTimeBig.vue";
import IconBookingTimeBig from "@/components/icons/booking/IconBookingTimeBig.vue";
import IconBookingPersonBig from "@/components/icons/booking/IconBookingPersonBig.vue";
import IconBookingDurationBig from "@/components/icons/booking/IconBookingDurationBig.vue";
import IconBookingCalendarBig from "@/components/icons/booking/IconBookingCalendarBig.vue";

export default {
  name: "BookingStepInfo",

  components: {
    IconBookingCalendar,
    IconBookingDuration,
    IconBookingPerson,
    IconBookingTime,
    IconBookingCalendarBig,
    IconBookingDurationBig,
    IconBookingPersonBig,
    IconBookingTimeBig,
  },

  props: {
    text: String,
    big: {
      type: Boolean,
      default: false,
    },
    map: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    iconType: {
      type: String,
      default: "IconBookingPerson",
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: #ebebeb;

  &._big {
    overflow: hidden;
    padding: 4px;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
  }

  .icon {
    display: flex;
    flex: 0 0 auto;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;

    border-radius: 8px;
    border: 2px solid #f18e35;
    background: #fff;

    &._map {
      border-color: #858585;
    }

    &._filled {
      border-color: #f18e35;
    }
  }
}

.text {
  width: 100%;
  margin: 0 20px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  color: #222;
  user-select: none;

  &._big {
    font-size: 24px;
    line-height: 32px;

    @include mobile {
      font-size: 18px;
      line-height: 26px;
    }
  }

  &._map {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    color: #858585;
  }
}
</style>
