<template>
  <div class="tooltip" ref="tooltip">
    <div class="row" v-for="entity in balanceEntities" :key="entity.id">
      <span> {{ entity.name }}</span>
      <span class="color"> {{ entity.balance }} ₽</span>
    </div>
    <div class="button">
      <VButton @click="onButtonClickHandler" small> Пополнить баланс </VButton>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits, defineProps, onMounted } from "vue";
import { onClickOutside } from "@vueuse/core";
import VButton from "@/components/ui/VButton.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

defineProps({
  balanceEntities: Array,
});

const emit = defineEmits(["close"]);
const tooltip = ref(null);
const modal = ref(false);
const store = useStore();
const router = useRouter();

const ownerId =
  store.getters.role === "MARKETER"
    ? store.state.general.userInfo.ownerId
    : store.state.general.userInfo.id;

onMounted(() => {
  modal.value = true;
});

onClickOutside(tooltip, (event) => {
  event.stopPropagation();
  if (modal.value) {
    emit("close");
  }
});

const onButtonClickHandler = () => {
  emit("close");
  router.push(`/account/clients/form/${ownerId}/invoices`);
};
</script>

<style scoped lang="scss">
.tooltip {
  position: absolute;
  right: 110px;
  top: 64px;
  display: flex;
  flex-direction: column;
  width: 320px;
  background: #ffffff;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  z-index: 10000000;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #222222;
  overflow: hidden;
}

.row {
  padding: 8px 12px 8px 20px;
  display: flex;
  justify-content: space-between;

  font-size: 16px;
  font-weight: 400;
  line-height: 22px;

  .color {
    color: #f18e35;
  }
}

.button {
  padding: 8px;

  button {
    width: 100%;
  }
}
</style>
