<template>
  <div class="container-button">
    <div class="button _prev" :class="{ _disabled: isMin }" @click="decrement">
      <IconBookingCounterArrow :color="isMin ? '#F18E35' : '#ffffff'" />
    </div>
    <span class="value">{{ time }}</span>
    <div class="button _next" :class="{ _disabled: isMax }" @click="increment">
      <IconBookingCounterArrow :color="isMax ? '#F18E35' : '#ffffff'" />
    </div>
  </div>
</template>

<script>
import IconBookingCounterArrow from "@/components/icons/booking/IconBookingCounterArrow.vue";

export default {
  name: "TimeCounter",
  components: { IconBookingCounterArrow },

  data() {
    return {
      currentValue: new Date(),
      isTimeReset: 0,
      time: "",
      isMax: false,
      isMin: true,
    };
  },

  props: {
    modelValue: {
      type: Number,
      default: 5,
    },
    minValue: {
      type: Number,
      default: 30,
    },
    maxValue: {
      type: Number,
      default: 10,
    },
    stepValue: {
      type: Number,
      default: 1,
    },
    durationTime: {
      type: String,
    },
  },

  methods: {
    decrement() {
      if (this.isMin) {
        return;
      }

      let value = this.currentValue;

      if (value.getHours() === 0) {
        this.currentValue.setHours(0);
        value = this.currentValue.setMinutes(30);
      } else {
        this.currentValue.setMinutes(this.currentValue.getMinutes() - 30);
        this.time = this.currentValue.getMinutes();
      }
      this.isTimeReset++;

      this.$emit("update:modelValue", value);
    },
    increment() {
      let value = this.currentValue;

      if (value.getHours() === 9 && value.getHours() === 9) {
        this.currentValue.setHours(9);
        value = this.currentValue.setMinutes(30);
      } else {
        this.time = this.currentValue.setMinutes(
          this.currentValue.getMinutes() + 30
        );
      }
      this.isTimeReset++;
      this.$emit("update:modelValue", value);
    },

    getTime() {},
  },

  watch: {
    isTimeReset() {
      this.time =
        this.currentValue.getHours() +
        " ч" +
        " : " +
        this.currentValue.getMinutes() +
        " м";
      this.$emit("time", {
        hours: this.currentValue.getHours(),
        minutes: this.currentValue.getMinutes(),
      });

      const mins =
        this.currentValue.getMinutes() + this.currentValue.getHours() * 60;

      this.isMin = mins <= this.minValue;
      this.isMax = mins >= 570;
    },
  },

  mounted() {
    if (this.durationTime) {
      this.currentValue = this.durationTime;
    } else {
      this.currentValue.setHours(0, 0, 0, 0);
    }
    this.isTimeReset++;
  },
};
</script>

<style lang="scss" scoped>
.container-button {
  display: flex;
  align-items: center;
  width: 300px;
  padding: 4px;
  margin: 0 auto;
  border-radius: 100px;
  background: #fef6e8;
  user-select: none;
}

.value {
  color: #222222;
  font-size: 36px;
  font-weight: 400;
  line-height: 56px;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background: #f18e35;
  cursor: pointer;

  &._prev {
    margin-right: auto;
  }
  &._next {
    margin-left: auto;
    transform: rotate(180deg);
  }

  &._disabled {
    background: #ffffff;
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
  }
}
</style>
