<template>
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.4997 13.125L14.833 11.4583V2.16667H5.54134L3.87467 0.5H14.833C15.2913 0.5 15.6837 0.663194 16.0101 0.989583C16.3365 1.31597 16.4997 1.70833 16.4997 2.16667V13.125ZM3.16634 15.5C2.70801 15.5 2.31565 15.3368 1.98926 15.0104C1.66287 14.684 1.49967 14.2917 1.49967 13.8333V2.83333L0.749674 2.08333C0.596897 1.93056 0.520508 1.73611 0.520508 1.5C0.520508 1.26389 0.596897 1.06944 0.749674 0.916667C0.902452 0.763889 1.0969 0.6875 1.33301 0.6875C1.56912 0.6875 1.76356 0.763889 1.91634 0.916667L16.083 15.0833C16.2358 15.2361 16.3122 15.4306 16.3122 15.6667C16.3122 15.9028 16.2358 16.0972 16.083 16.25C15.9302 16.4028 15.7358 16.4792 15.4997 16.4792C15.2636 16.4792 15.0691 16.4028 14.9163 16.25L14.1663 15.5H3.16634ZM10.8122 12.1667H4.83301C4.66634 12.1667 4.54134 12.0903 4.45801 11.9375C4.37467 11.7847 4.38856 11.6389 4.49967 11.5L6.16634 9.27083C6.24967 9.15972 6.36079 9.10417 6.49967 9.10417C6.63856 9.10417 6.74967 9.15972 6.83301 9.27083L8.37467 11.3333L9.06217 10.4167L3.16634 4.52083V13.8333H12.4788L10.8122 12.1667Z"
      fill="#F18E35"
    />
  </svg>
</template>

<script>
export default {
  name: "IconInvalidType",
};
</script>
