<template>
  <svg
    width="58"
    height="55"
    viewBox="0 0 58 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.4997 44.4167L28.9997 38.0833L39.4997 44.5L36.7497 32.5L45.9998 24.5L33.8331 23.4167L28.9997 12.0833L24.1664 23.3333L11.9997 24.4167L21.2497 32.5L18.4997 44.4167ZM28.9997 45.9167L15.1664 54.25C14.5553 54.6389 13.9164 54.8055 13.2497 54.75C12.5831 54.6944 11.9997 54.4722 11.4997 54.0833C10.9997 53.6944 10.6109 53.2089 10.3331 52.6267C10.0553 52.0422 9.99975 51.3889 10.1664 50.6667L13.8331 34.9167L1.58308 24.3333C1.02752 23.8333 0.680858 23.2633 0.54308 22.6233C0.40308 21.9856 0.444191 21.3611 0.666413 20.75C0.888635 20.1389 1.22197 19.6389 1.66641 19.25C2.11086 18.8611 2.72197 18.6111 3.49975 18.5L19.6664 17.0833L25.9164 2.25C26.1942 1.58333 26.6253 1.08333 27.2097 0.75C27.792 0.416667 28.3886 0.25 28.9997 0.25C29.6109 0.25 30.2086 0.416667 30.7931 0.75C31.3753 1.08333 31.8053 1.58333 32.0831 2.25L38.3331 17.0833L54.4997 18.5C55.2775 18.6111 55.8886 18.8611 56.3331 19.25C56.7775 19.6389 57.1109 20.1389 57.3331 20.75C57.5553 21.3611 57.5975 21.9856 57.4598 22.6233C57.3198 23.2633 56.972 23.8333 56.4164 24.3333L44.1664 34.9167L47.8331 50.6667C47.9997 51.3889 47.9442 52.0422 47.6664 52.6267C47.3886 53.2089 46.9997 53.6944 46.4997 54.0833C45.9997 54.4722 45.4164 54.6944 44.7498 54.75C44.0831 54.8055 43.4442 54.6389 42.8331 54.25L28.9997 45.9167Z"
      fill="#D6D6D6"
    />
  </svg>
</template>

<script>
export default {
  name: "IconEmptyStar",

  props: {
    isFilled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
