<template>
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_19246_165761)">
      <g filter="url(#filter0_d_19246_165761)">
        <path
          d="M14.3967 36.0406C15.811 34.468 18.104 34.468 19.5183 36.0406L34.8833 53.1249C36.2976 54.6974 36.2976 57.2471 34.8833 58.8196C33.469 60.3922 31.1759 60.3922 29.7616 58.8196L14.3967 41.7353C12.9824 40.1628 12.9824 37.6131 14.3967 36.0406Z"
          fill="#F18E35"
        />
        <path
          d="M29.757 58.8201C28.3427 57.2476 28.3427 54.6979 29.757 53.1254L60.4869 18.9568C61.9012 17.3842 64.1942 17.3842 65.6085 18.9568C67.0228 20.5293 67.0228 23.079 65.6085 24.6515L34.8786 58.8201C33.4643 60.3927 31.1713 60.3927 29.757 58.8201Z"
          fill="#F18E35"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_19246_165761"
        x="-6.66406"
        y="1.77734"
        width="93.3359"
        height="82.2227"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="10" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.945098 0 0 0 0 0.556863 0 0 0 0 0.207843 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_19246_165761"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_19246_165761"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_19246_165761">
        <rect width="80" height="80" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "IconSwooshModal",
};
</script>

<style scoped></style>
