<template>
  <div class="inner">
    <BookingSchemeTable
      :table="{
        number: tableNumber,
        tableInfo: { busyTimeStart: busyTimeStart },
      }"
      is-modal
    />

    <div class="main-block">
      <h2>Длительность мероприятия</h2>
      <TimeCounter
        v-if="isLoaded"
        :durationTime="durationTime"
        @time="timeHandler"
      />
    </div>
    <div class="error" v-if="error">
      <IconInfo :style="{ width: '46px', height: '46px' }" color="#fff" />
      <div>Выбранный стол уже занят, измените выбор</div>
    </div>
    <VButton v-else @click="onNextClickHandler" class="button" large>
      {{ Id ? "Ожидать гостя" : "Посадить гостя" }}
    </VButton>
    <IconPopupCross
      v-if="screenWidth !== 'mobile'"
      class="modal-cross"
      @click="$store.dispatch('closeModal')"
    />
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import IconPopupCross from "@/components/icons/IconPopupCross.vue";
import TimeCounter from "@/components/pages/client-booking/TimeCounter.vue";
import IconInfo from "@/components/icons/IconInfo.vue";
import BookingSchemeTable from "@/components/pages/client-booking/BookingSchemeTable.vue";

const store = useStore();

const screenWidth = computed(() => {
  return store.getters.screenType;
});
</script>

<script>
import VButton from "@/components/ui/VButton.vue";
import { API } from "@/assets/js/api/api";
import BookingSeatsSuccessModal from "@/components/modals/BookingSeatsSuccessModal.vue";
import FailModal from "@/components/modals/FailModal.vue";
export default {
  name: "BookingSeatsDurationModal",

  components: {
    VButton,
  },

  props: {
    catId: String,
    Id: String,
    tableId: String,
    tableNumber: String,
    busyTimeStart: String,
  },

  data() {
    return {
      durationTime: "",
      duration: 30,
      isLoaded: false,
      error: false,
    };
  },

  methods: {
    async onNextClickHandler() {
      if (this.Id) {
        try {
          await API.booking.waitingStart({
            Id: this.Id,
            duration: this.duration,
            tableId: this.tableId,
            tableNumber: this.tableNumber,
          });
          this.$store.dispatch("openModal", {
            value: {
              component: BookingSeatsSuccessModal,
              props: {
                tableNumber: this.tableNumber,
              },
            },
          });
        } catch (e) {
          this.$store.dispatch("openModal", {
            value: {
              component: FailModal,
              props: {
                error: e.response.data.message,
              },
            },
          });
        }
      } else {
        try {
          await API.booking.visitStart({
            catId: this.catId,
            tableIds: [this.tableId],
            duration: this.duration,
          });
          this.$store.dispatch("openModal", {
            value: {
              component: BookingSeatsSuccessModal,
              props: {
                tableNumber: this.tableNumber,
                duration: this.duration,
              },
            },
          });
        } catch (e) {
          this.error = true;
        }
      }
    },
    timeHandler(val) {
      this.duration = +val.hours * 60 + +val.minutes;
    },
  },

  mounted() {
    const date = new Date();
    date.setHours(Math.floor(this.duration / 60));
    date.setMinutes(this.duration % 60);
    date.setSeconds(0);

    this.durationTime = date;

    this.isLoaded = true;
  },
};
</script>

<style lang="scss" scoped>
.title {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  color: #222222;
}

.main-block {
  padding-bottom: 48px;

  @include mobile {
    padding: 64px;
  }
}

.message {
  width: 336px;
  margin-top: 24px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #222222;
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  width: 100%;

  @include mobile {
    flex-direction: column;
    margin-top: 32px;
  }
}

.button {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;

  @include mobile {
    &:first-child {
      margin-bottom: 16px;
    }
  }
}
</style>

<style lang="scss" scoped>
.inner {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 776px;
  width: 640px;
  max-width: 640px;
  margin: auto;
  padding: 32px 56px 40px 56px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;

  @media screen and (max-width: 841px) {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    box-shadow: 0 -2px 10px 0 #0000000d;
    border-radius: 20px 20px 0 0;
    padding: 32px 24px 24px 24px;
  }
}

h2 {
  margin-bottom: 32px;
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

.modal-cross {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.timers {
  max-width: 776px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  > div {
    width: calc(50% - 8px);
  }
}

.lines {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 56px;

  @include mobile {
    width: calc(100% - 32px);
    height: 8px;
    justify-content: flex-start;
    align-items: center;
    margin: 0 16px;
    padding: 0 4px;
    border-radius: 8px;
    background-color: white;
  }
}

.line {
  width: 204px;
  height: 8px;
  background-color: #adadad;

  @include mobile {
    width: calc(20% - 4px);
    border-radius: 8px;
  }

  &:not(:last-child) {
    margin-right: 10px;
  }

  &._active {
    background-color: #f18e35;
    cursor: pointer;
  }
}

.table {
  margin-bottom: 32px;
}

.error {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 24px 16px;
  background-color: #d20a11;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  color: #fff;
  border-radius: 16px;
}
</style>
