<template>
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    :fill="background"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 5.00001C0 4.55818 0.358172 4.20001 0.8 4.20001H9.2C9.64183 4.20001 10 4.55818 10 5.00001C10 5.44184 9.64183 5.80001 9.2 5.80001H0.8C0.358173 5.80001 0 5.44184 0 5.00001Z"
      :fill="color"
    />
    <path
      d="M5 10C4.55817 10 4.2 9.64183 4.2 9.2V0.8C4.2 0.358173 4.55817 0 5 0C5.44182 0 5.8 0.358172 5.8 0.8V9.2C5.8 9.64183 5.44182 10 5 10Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: "IconPlus",
  props: {
    color: {
      type: String,
      default: "#ffffff",
    },
    background: {
      type: String,
      default: "none",
    },
  },
};
</script>

<style scoped></style>
